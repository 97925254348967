import { SET_IS_SEARCHING } from './types';

const initialState = {
  isSearching: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_IS_SEARCHING:
    return { ...state, isSearching: payload };
  default:
    return state;
  }
};
