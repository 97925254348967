import styled from 'styled-components';

const BgCategories = styled.section`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${props =>
    props.type === 'BRANDED_COLLECTION'
      ? `url(${props.background})`
      : `linear-gradient(90deg, rgba(0, 0, 0, 0) 0 % ,
        rgba(18, 0, 36, 0.5) 10 %,
        rgba(18, 0, 36, 1) 40 %),
      url(${props.background})
    `};
    background-position: center center;
    background-size: cover;
    filter: ${props => (props.type === 'BRANDED_COLLECTION' ? '' : 'blur(10px)')};
    opacity: ${props => (props.type === 'BRANDED_COLLECTION' ? props.brandedOpacity : 0.2)};
  }
`;

export default BgCategories;
