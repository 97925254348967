const {
  SubtitleOverlay,
  SettingsPanelPage,
  SettingsPanel,
  SubtitleSettingsPanelPage,
  SubtitleSelectBox,
  SettingsPanelItem,
  CloseButton,
  ControlBar,
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
  SeekBar,
  SeekBarLabel,
  Container,
  PlaybackToggleButton,
  VolumeToggleButton,
  VolumeSlider,
  Spacer,
  SettingsToggleButton,
  FullscreenToggleButton,
  UIContainer,
  BufferingOverlay,
  PlaybackToggleOverlay,
  UIManager
} = window.bitmovin.playerui;

const customUI = bitPlayer => {
  const subtitleOverlay = new SubtitleOverlay();

  const mainSettingsPanelPage = new SettingsPanelPage({
    components: [new SettingsPanelItem('Subtitulos', new SubtitleSelectBox())]
  });

  const settingsPanel = new SettingsPanel({
    components: [mainSettingsPanelPage],
    hidden: true,
    pageTransitionAnimation: false,
    hideDelay: -1
  });

  const subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
    settingsPanel: settingsPanel,
    overlay: subtitleOverlay
  });

  settingsPanel.addComponent(subtitleSettingsPanelPage);

  settingsPanel.addComponent(new CloseButton({ target: settingsPanel }));
  subtitleSettingsPanelPage.addComponent(new CloseButton({ target: settingsPanel }));

  const controlBar = new ControlBar({
    components: [
      new Container({
        components: [
          new PlaybackTimeLabel({
            timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
            hideInLivePlayback: true
          }),
          new SeekBar({ label: new SeekBarLabel() }),
          new PlaybackTimeLabel({
            timeLabelMode: PlaybackTimeLabelMode.TotalTime,
            cssClasses: ['text-right']
          })
        ],
        cssClasses: ['controlbar-top']
      }),
      new Container({
        components: [
          new PlaybackToggleButton(),
          new VolumeToggleButton(),
          new VolumeSlider(),
          new Spacer(),
          new SettingsToggleButton({ settingsPanel: settingsPanel }),
          new FullscreenToggleButton()
        ],
        cssClasses: ['controlbar-bottom']
      })
    ]
  });

  const customUI = new UIContainer({
    components: [
      new BufferingOverlay(),
      new PlaybackToggleOverlay(),
      new SubtitleOverlay(),
      controlBar,
      settingsPanel
    ],
    cssClasses: ['ui-skin-smallscreen'],
    hideDelay: 1500
  });
  return new UIManager(bitPlayer, customUI);
};
export default customUI;
