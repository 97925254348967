import { takeLatest, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { loginUser, forgotPassword, resetPassword, tvCode } from '../../network';
import errorHandler from '../../utils/errors';
import { SET_ISLOGEDIN } from '../user/types';
import {
  POST_LOGIN_SERVICE,
  POST_FORGOT_PASSWORD,
  POST_RESET_PASSWORD,
  SET_LOGIN_SERVICE,
  RESET_LOGIN_SERVICE,
  POST_RESET_LOGIN_SERVICE,
  SET_ERROR_LOGIN_MODAL,
  POST_RESET_ERROR_LOGIN_MODAL,
  RESET_ERROR_LOGIN_MODAL,
  SET_ERROR_RESTORE_PASS,
  POST_RESET_ERROR_RESTORE_PASS,
  RESET_ERROR_RESTORE_PASS,
  POST_TV_CODE
} from './types';
import { loginErrors } from '../../utils/saga_errors';

function* postLogin(action) {
  try {
    const response = yield call(loginUser, action.payload);
    const { login } = action.payload;
    login(response.data);
    yield put({ type: SET_ISLOGEDIN, payload: true });
  } catch (e) {
    const { response } = e;

    if (response && response.status === 401) {
      if (response.data.error === 'WRONG_CREDENTIALS') {
        yield put({
          type: SET_LOGIN_SERVICE,
          payload: loginErrors.WRONG_CREDENTIALS
        });
      } else if (response.data.error === 'WRONG_CLIENT') {
        yield put({
          type: SET_LOGIN_SERVICE,
          payload: loginErrors.WRONG_CLIENT
        });
      } else {
        yield put({ type: SET_LOGIN_SERVICE, payload: errorHandler(response.data.error) });
        yield put({ type: SET_ERROR_LOGIN_MODAL, payload: response.data.error });
      }
    } else {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
    yield put({ type: SET_ISLOGEDIN, payload: false });
  }
}

function* postResetLogin() {
  yield put({ type: RESET_LOGIN_SERVICE, payload: '' });
}

function* postForgotPassword(action) {
  try {
    const response = yield call(forgotPassword, action.payload);
    action.payload.response(`Hemos enviado un correo a ${response.data.sent_to}`);
  } catch (e) {
    const { response } = e;
    if (response.status === 422) {
      action.payload.response(errorHandler(response.data.error));
      yield put({ type: SET_ERROR_RESTORE_PASS, payload: response.data.error });
    } else if (response) {
      action.payload.response(loginErrors.TRY_AGAIN);
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* postResetPassword(action) {
  try {
    yield call(resetPassword, action.payload);
    action.payload.response({ success: true });
  } catch (e) {
    const { response } = e;
    if (response.status === 422) {
      action.payload.response({
        success: false,
        message: errorHandler(response.data.error)
      });
    } else {
      action.payload.response({
        success: false,
        message: loginErrors.TRY_AGAIN
      });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* postTvCode(action) {
  try {
    yield call(tvCode, action.payload);
    action.payload.response({ success: true });
  } catch (e) {
    const { response } = e;
    if (response.status === 422) {
      action.payload.response({
        success: false,
        message: errorHandler(response.data.error)
      });
    } else {
      action.payload.response({
        success: false,
        message: loginErrors.TRY_AGAIN
      });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* resetErrorLogin() {
  yield put({ type: RESET_ERROR_LOGIN_MODAL, payload: '' });
}

function* resetErrorRestorePass() {
  yield put({ type: RESET_ERROR_RESTORE_PASS, payload: '' });
}

function* watchPostLogin() {
  yield takeLatest(POST_LOGIN_SERVICE, postLogin);
}

function* watchPostResetLogin() {
  yield takeLatest(POST_RESET_LOGIN_SERVICE, postResetLogin);
}

function* watchForgotPassword() {
  yield takeLatest(POST_FORGOT_PASSWORD, postForgotPassword);
}

function* watchResetPassword() {
  yield takeLatest(POST_RESET_PASSWORD, postResetPassword);
}

function* watchResetErrorRestorePass() {
  yield takeLatest(POST_RESET_ERROR_LOGIN_MODAL, resetErrorLogin);
}

function* watchResetLoginError() {
  yield takeLatest(POST_RESET_ERROR_RESTORE_PASS, resetErrorRestorePass);
}

function* watchPostTvCode() {
  yield takeLatest(POST_TV_CODE, postTvCode);
}

export default [
  watchPostLogin(),
  watchForgotPassword(),
  watchResetPassword(),
  watchPostResetLogin(),
  watchResetLoginError(),
  watchResetErrorRestorePass(),
  watchPostTvCode()
];
