import React, { useState, useEffect, useRef } from 'react';
import appConfig from '../../config';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalCustomPlayer } from '../../components';
import Player from './player';
import {
  FETCH_PREVIOUS_NEXT_EPISODE,
  SET_USER_ADULT,
  FETCH_VIDEO_DATA,
  FETCH_PLAYLIST,
  SET_PLAYLIST,
  SET_VIDEO_DATA
} from '../../store/player/types';
import useCSSClassChange from '../../utils/hooks/useCSSClassChange';
import useVisibilityChange from '../../utils/hooks/useVisibilityChange';
import TitleTypeFormat from '../../utils/helpers/titleTypeHelper';
import TagManager from '../../utils/helpers/gtmDatalayer';
import playerTypes from '../../utils/constants/playerTypes';
import statusTvod from '../../utils/constants/statusTvod';
import providerTypes from '../../utils/constants/providerTypes';
import {
  FETCH_ENTITLEMENT_MEDIA,
  POST_ENTITLEMENT_KEY,
  POST_MEDIA_SIGN
} from '../../store/user/types';
import { useCheckPlaylistLoading } from '../../utils/hooks/useCheckPlaylistLoading';
import { useClearPlayerStates } from '../../utils/hooks/useClearPlayerStates';
import { useFetchPlaylist } from '../../utils/hooks/useFetchPlaylist';

const PlayerWrapper = ({
  getPreviousOrNextEpidose,
  setUserAdult,
  userAdult,
  fetchPlaylist,
  fetchVideoData,
  playlist,
  videoData,
  postEntitlementKey,
  entitlementKey,
  fetchEntitlementMedia,
  entitlementMedia,
  isLogedIn,
  loadingEntitlementMedia,
  loadingVideoData,
  postMediaSign,
  mediaSignToken,
  loadingSignToken,
  loadingPlaylist,
  clearPlaylist,
  clearVideoData,
  entitlementMediaError
}) => {
  const { policyId } = appConfig();
  const { mediaId, mediaType } = useParams();
  const location = useLocation();
  const [showModalAdult, setShowModalAdult] = useState(true);
  const [showModalTvod, setShowModalTvod] = useState(true);
  const [buttonVisibility, setButtonVisibility] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [comesFromSuccessfullPayment, setComesFromSuccessfullPayment] = useState(false);
  const isSignedRef = useRef(false);
  const entitlementFetchedRef = useRef(false);
  const { PLAYER_TYPES } = playerTypes();
  const { TVOD_STATUS } = statusTvod();
  const { PROVIDER_TYPES } = providerTypes();
  const history = useHistory();

  useCSSClassChange(
    '.bmpui-ui-uicontainer',
    'bmpui-player-state-finished',
    'bmpui-player-state-prepared'
  );
  useVisibilityChange('.bmpui-ui-uicontainer', 'bmpui-controls-hidden', setButtonVisibility);
  useClearPlayerStates();
  useFetchPlaylist({
    loadingVideoData,
    videoData,
    mediaSignToken,
    fetchPlaylist,
    playlist,
    providerType: PROVIDER_TYPES,
    policyId,
    loadingSignToken,
    isLogedIn,
    playerTypes: PLAYER_TYPES,
    entitlementMediaError,
    loadingEntitlementMedia,
    entitlementMedia,
    tvodStatus: TVOD_STATUS
  });
  const shouldRender = useCheckPlaylistLoading(
    videoData,
    entitlementMedia,
    PLAYER_TYPES,
    loadingEntitlementMedia,
    loadingVideoData,
    isLogedIn,
    playlist,
    loadingPlaylist
  );

  const checkMetadata = media => {
    return Object.keys(media).length > 0;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let rqpwa = queryParams.get('rqpwa') === 'true' ? true : false;

    setComesFromSuccessfullPayment(rqpwa);
  }, [location]);

  useEffect(() => {
    if (!checkMetadata(videoData) && mediaId && mediaType) {
      fetchVideoData({ id: mediaId, type: TitleTypeFormat(mediaType) });
      TagManager().playerFunnel();
    }
  }, [fetchVideoData, mediaType, mediaId, videoData]);

  useEffect(() => {
    const isTvod =
      loadingVideoData &&
      checkMetadata(videoData) &&
      videoData?.monetizationType === PLAYER_TYPES.TVOD;

    if (
      isTvod &&
      !checkMetadata(entitlementMedia) &&
      isLogedIn &&
      mediaId &&
      !entitlementFetchedRef.current
    ) {
      fetchEntitlementMedia({ mediaId });
      entitlementFetchedRef.current = true;
    }
  }, [
    fetchEntitlementMedia,
    mediaId,
    isLogedIn,
    entitlementMedia,
    loadingVideoData,
    videoData,
    PLAYER_TYPES
  ]);

  useEffect(() => {
    const isVideoDataSigned =
      loadingVideoData && checkMetadata(videoData) && videoData?.sources?.is_signed;
    const isEntitlementWatching =
      checkMetadata(entitlementMedia) && entitlementMedia?.status === TVOD_STATUS.WATCHING;

    if (!isVideoDataSigned || isSignedRef.current) return;

    const { has_drm } = videoData.sources;

    const payload = { mediaId: mediaId };
    if (has_drm) {
      payload.policyId = policyId;
    }

    if (videoData?.monetizationType === PLAYER_TYPES.TVOD) {
      if (isEntitlementWatching) {
        postMediaSign(payload);
        isSignedRef.current = true;
        return;
      }
    }
    if (videoData?.monetizationType === PLAYER_TYPES.AVOD) {
      if (videoData?.sources?.provider === PROVIDER_TYPES.BRIGHTCOVE) {
        return;
      }
      postMediaSign(payload);
      isSignedRef.current = true;
      return;
    }
  }, [
    loadingVideoData,
    videoData,
    postMediaSign,
    mediaId,
    policyId,
    entitlementMedia,
    PLAYER_TYPES,
    TVOD_STATUS,
    PROVIDER_TYPES
  ]);

  useEffect(() => {
    if (checkMetadata(videoData) && mediaId && mediaType === 'series') {
      getPreviousOrNextEpidose({ type: mediaType, mediaId: mediaId });
    }
  }, [videoData, mediaId, getPreviousOrNextEpidose, mediaType]);

  useEffect(() => {
    const isAvodAndRated18 =
      videoData?.monetizationType === PLAYER_TYPES.AVOD && videoData?.rated === '+18';

    if (loadingVideoData && checkMetadata(videoData) && isAvodAndRated18) {
      setAutoplay(false);
      return;
    }
  }, [videoData, PLAYER_TYPES, TVOD_STATUS, loadingVideoData]);

  const handleModalTvod = () => {
    if (
      checkMetadata(videoData) &&
      videoData?.monetizationType === PLAYER_TYPES.TVOD &&
      checkMetadata(entitlementMedia) &&
      entitlementMedia?.status === TVOD_STATUS.NOT_WATCHED
    ) {
      return (
        <ModalCustomPlayer
          showModal={showModalTvod}
          textTitle={'¡Estás utilizando tu entrada!'}
          textContent={'Tendrás 72 horas para terminar de ver este contenido'}
          type={'tvod'}
          cancelText={'Ver más tarde'}
          acceptText={'Utilizar Entrada'}
          cancelCallback={() => {
            history.goBack();
            setShowModalTvod(false);
          }}
          acceptCallback={() => {
            postEntitlementKey({ entitlementId: entitlementMedia?.id });
            setShowModalTvod(false);
          }}
        />
      );
    }
  };

  const handleModalAdult = () => {
    if (
      checkMetadata(videoData) &&
      videoData?.monetizationType === PLAYER_TYPES.AVOD &&
      videoData?.rated === '+18' &&
      !userAdult
    ) {
      return (
        <ModalCustomPlayer
          showModal={showModalAdult}
          setUserAdult={setUserAdult}
          textTitle={'¡Contenido para adultos!'}
          textContent={'¿Eres mayor de edad?'}
          type={'adult'}
          cancelText={'No'}
          acceptText={'Si'}
          cancelCallback={() => {
            history.goBack();
            setShowModalAdult(false);
          }}
          acceptCallback={() => {
            setShowModalAdult(false);
            setUserAdult(true);
          }}
        />
      );
    }
  };

  return (
    <>
      <div style={{ display: !shouldRender ? 'block' : 'none' }}>
        <div className="spinner-container">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      </div>
      <div style={{ display: !shouldRender ? 'none' : 'block' }}>
        <div id="player-container" onMouseMove={() => setButtonVisibility(true)}>
          {handleModalTvod()}
          {handleModalAdult()}
          {shouldRender && (
            <Player
              id={mediaId}
              type={TitleTypeFormat(mediaType)}
              showModalAdult={showModalAdult}
              buttonVisibility={buttonVisibility}
              entitlementKey={entitlementKey}
              serieId={videoData.serieId}
              videoData={videoData}
              playlist={playlist}
              autoplay={autoplay}
              comesFromSuccessfullPayment={comesFromSuccessfullPayment}
              clearPlaylist={clearPlaylist}
              clearVideoData={clearVideoData}
            />
          )}
        </div>
      </div>
    </>
  );
};

PlayerWrapper.propTypes = {
  getPreviousOrNextEpidose: PropTypes.func.isRequired,
  setUserAdult: PropTypes.func.isRequired,
  userAdult: PropTypes.bool.isRequired,
  fetchPlaylist: PropTypes.func.isRequired,
  fetchVideoData: PropTypes.func.isRequired,
  playlist: PropTypes.object.isRequired,
  videoData: PropTypes.object.isRequired,
  postEntitlementKey: PropTypes.func.isRequired,
  fetchEntitlementMedia: PropTypes.func.isRequired,
  entitlementMedia: PropTypes.object.isRequired,
  entitlementKey: PropTypes.bool.isRequired,
  isLogedIn: PropTypes.bool.isRequired,
  loadingEntitlementMedia: PropTypes.bool.isRequired,
  loadingVideoData: PropTypes.bool.isRequired,
  postMediaSign: PropTypes.func.isRequired,
  mediaSignToken: PropTypes.string.isRequired,
  loadingSignToken: PropTypes.bool.isRequired,
  loadingPlaylist: PropTypes.bool.isRequired,
  clearPlaylist: PropTypes.func.isRequired,
  clearVideoData: PropTypes.func.isRequired,
  entitlementMediaError: PropTypes.object.isRequired
};

const stateToProps = ({ player, user }) => ({
  userAdult: player.userAdult,
  playlist: player.playlist,
  videoData: player.videoData,
  entitlementMedia: user.entitlementMedia,
  entitlementKey: user.entitlementKey,
  isLogedIn: user.isLogedIn,
  loadingEntitlementMedia: user.loadingEntitlementMedia,
  loadingVideoData: player.loadingVideoData,
  mediaSignToken: user.mediaSignToken,
  loadingSignToken: user.loadingSignToken,
  loadingPlaylist: player.loadingPlaylist,
  entitlementMediaError: user.entitlementMediaError
});

const actionToProps = dispatch => ({
  getPreviousOrNextEpidose: payload => dispatch({ type: FETCH_PREVIOUS_NEXT_EPISODE, payload }),
  setUserAdult: payload => dispatch({ type: SET_USER_ADULT, payload }),
  fetchPlaylist: payload => dispatch({ type: FETCH_PLAYLIST, payload }),
  fetchVideoData: payload => dispatch({ type: FETCH_VIDEO_DATA, payload }),
  postEntitlementKey: payload => dispatch({ type: POST_ENTITLEMENT_KEY, payload }),
  fetchEntitlementMedia: payload => dispatch({ type: FETCH_ENTITLEMENT_MEDIA, payload }),
  postMediaSign: payload => dispatch({ type: POST_MEDIA_SIGN, payload }),
  clearPlaylist: () => dispatch({ type: SET_PLAYLIST, payload: {} }),
  clearVideoData: () => dispatch({ type: SET_VIDEO_DATA, payload: {} })
});

export default connect(stateToProps, actionToProps)(PlayerWrapper);
