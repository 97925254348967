import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Slider } from '../index';
import { FETCH_CONTINUE_WATCHING, SET_CONTINUE_WATCHING } from '../../store/user/types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ContinueWrapper = ({
  fetchContinueWatching,
  continueWatching,
  isLogedIn,
  clearContinue,
  loadingContinueWatching
}) => {
  useEffect(() => {
    if (isLogedIn) {
      fetchContinueWatching();
    }
    clearContinue();
  }, [fetchContinueWatching, isLogedIn, clearContinue]);

  const render = () => {
    if (isLogedIn) {
      if (loadingContinueWatching) {
        return (
          <div className="container-fluid overflow-hidden py-2">
            <Row className="mb-4">
              <Col xs="6" sm="4" lg="2">
                <SkeletonTheme color="#eee" highlightColor="#fff">
                  <Skeleton height={36} />
                </SkeletonTheme>
              </Col>
            </Row>
            <Row>
              <SkeletonTheme color="#eee" highlightColor="#fff">
                <Col xs="12" className="d-flex">
                  <Skeleton height={130} className="skeleton-box-1 mx-1 mr-0" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                  <Skeleton height={130} className="skeleton-box-1 mx-1" />
                </Col>
              </SkeletonTheme>
            </Row>
          </div>
        );
      } else {
        if (continueWatching.length < 1) {
          return null;
        } else {
          return (
            <div className="container-fluid overflow-hidden px-0 pb-5">
              <div className="container-fluid py-2">
                <Row className="mb-3">
                  <Col sm="auto" className="d-flex align-items-center">
                    <h3 className="mr-3 mb-0">Continuar viendo</h3>
                  </Col>
                </Row>
                <Slider collectionName="Continuar Viendo">
                  {continueWatching.map((title, index) => (
                    <Slider.Item
                      key={title.id}
                      title={title}
                      collectionIndex={1}
                      titleIndex={index}
                      collectionName="Continuar Viendo"
                      origin="discovery"
                      continueWatching={true}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          );
        }
      }
    } else {
      return null;
    }
  };

  return render();
};

ContinueWrapper.propTypes = {
  fetchContinueWatching: PropTypes.func.isRequired,
  continueWatching: PropTypes.array.isRequired,
  isLogedIn: PropTypes.bool.isRequired,
  clearContinue: PropTypes.func.isRequired,
  loadingContinueWatching: PropTypes.bool.isRequired
};

const stateToProps = ({ user }) => ({
  continueWatching: user.continueWatching,
  isLogedIn: user.isLogedIn,
  loadingContinueWatching: user.loadingContinueWatching
});

const actionToProps = dispatch => ({
  fetchContinueWatching: payload => dispatch({ type: FETCH_CONTINUE_WATCHING, payload }),
  clearContinue: () => dispatch({ type: SET_CONTINUE_WATCHING, payload: [] })
});

export default connect(stateToProps, actionToProps)(ContinueWrapper);
