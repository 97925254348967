const socialErrors = {
  INTERNAL_ERROR: '¡Oops! Parece que algo salío mal. Por favor, vuelve a intentarlo más tarde.',
  FACEBOOK_VALIDATION_ERROR: '¡Vaya! No es posible conectarse con Facebook en este momento.',
  FACEBOOK_TOKEN_INVALID: '¡Oops! Parece que tu sesión expiro. Por favor, vuelve a intentarlo.',
  FACEBOOK_TOKEN_EMPTY: '¡Oops! Parece que algo salío mal. Por favor, vuelve a intentarlo.',
  FACEBOOK_USER_NOT_FOUND:
    '¡Oops! No encontramos tu usuario en Facebook. Por favor, vuelve a intentarlo.',
  GOOGLE_VALIDATION_ERROR: '¡Vaya! No es posible conectarse con Google en este momento.',
  GOOGLE_TOKEN_INVALID: '¡Oops! Parece que tu sesión expiro. Por favor, vuelve a intentarlo.',
  GOOGLE_TOKEN_EMPTY: '¡Oops! Parece que algo salío mal. Por favor, vuelve a intentarlo.'
};

export default socialErrors;
