import React from 'react';
import { EmailConfirmation } from '../../components';
import { Container } from 'react-bootstrap';

const ConfirmView = () => {
  return (
    <Container className="container-pt-custom">
      <div className="row">
        <div className="col-sm-6 mx-auto">
          <EmailConfirmation />
        </div>
      </div>
    </Container>
  );
};

export default ConfirmView;
