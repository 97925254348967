import React from 'react';
import { Slider } from '../';
import PropTypes from 'prop-types';
import adServer from '../../utils/constants/adServer';
import { Spinner } from 'react-bootstrap';

const Watching = ({ continueWatching, isLoading }) => {
  const { LOCATIONS } = adServer();

  return (
    <>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      ) : continueWatching.length > 0 ? (
        <Slider explore={true} collectionName={LOCATIONS.CONTINUEWATCHING}>
          {continueWatching.map((title, index) => (
            <Slider.Item
              key={title.id}
              title={title}
              origin="explore"
              collectionIndex={index}
              titleIndex={index}
              collectionName={LOCATIONS.CONTINUEWATCHING}
            />
          ))}
        </Slider>
      ) : (
        <p className="mb-3 text-center text-muted">No hay títulos para continuar viendo</p>
      )}
    </>
  );
};

Watching.propTypes = {
  continueWatching: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Watching;
