import { SET_REGISTER_SERVICE, SET_REGISTER_RRSS } from './types';

const initialState = {
  registered: false,
  registeredRRSS:false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_REGISTER_SERVICE:
    return { ...state, registered: payload };
  case SET_REGISTER_RRSS:
    return { ...state, registeredRRSS: payload };
  default:
    return state;
  }
};
