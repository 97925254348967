import { useState, useEffect } from 'react';

const useUpdateEpisodes = (episodes, resumeData) => {
  const [mergedArray, setMergedArray] = useState([]);

  useEffect(() => {
    const indexedEpisodes = indexEpisodesById(episodes);
    const mergedEpisodes = mergeData(indexedEpisodes, resumeData, ['resume', 'entitlement']);
    setMergedArray(Object.values(mergedEpisodes));
  }, [episodes, resumeData]);

  return mergedArray;
};

const indexEpisodesById = episodes =>
  episodes.reduce((acc, episode) => ({ ...acc, [episode.id]: episode }), {});

const mergeData = (indexedEpisodes, resumeData, keys) =>
  keys.reduce((acc, key) => {
    const episodesForKey = (resumeData[key] && resumeData[key].episodes) || [];
    return episodesForKey.reduce((innerAcc, episode) => {
      if (!innerAcc[episode.id]) return innerAcc;
      return { ...innerAcc, [episode.id]: { ...innerAcc[episode.id], [key]: episode } };
    }, acc);
  }, indexedEpisodes);

export default useUpdateEpisodes;
