import React from 'react';
import { TvCode } from '../../components';

const TvCodeView = () => {
  return (
    <TvCode />
  );
};

export default TvCodeView;
