import config from '../config';

const network = client => ({
  facebookLogin: async payload =>
    client.post(config().socialLogin.facebookLoginUrl, {
      accessToken: payload.accessToken
    }),
  googleLogin: async payload =>
    client.post(config().socialLogin.googleLoginUrl, {
      id_token: payload.tokenId
    }),
  toolboxMso: async () =>
    client.get(config().socialLogin.toolboxMso.replace(':cp', config().socialLogin.toolboxCP)),
  toolboxLogin: async payload =>
    client.post(config().socialLogin.toolboxAccessToken.replace(':idp', payload.idp), {
      user_token: payload.userToken
    })
});

export default network;
