import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Image, Button, Modal, Form, FormControl } from 'react-bootstrap';
import initials from '../../utils/helpers/extractFirstLetter';
import BgProfile from './styles';

const Profile = props => {
  const { picture, names } = props;
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <BgProfile background={picture}>
        <div className="container container-pt-custom">
          <div className="row mb-3">
            <div className="col-sm-12 d-flex justify-content-center">
              {picture ? (
                <Image src={picture} className="avatar-profile d-block mx-auto" />
              ) : (
                <div className="avatar-profile">{initials(names)}</div>
              )}
            </div>
          </div>
          <Row className="justify-content-md-center mt-5">
            <Col md="auto" className="text-center">
              <h3 className="text-center">{names}</h3>
            </Col>
          </Row>
        </div>
      </BgProfile>
      <Modal show={showModal} onHide={handleClose} className="modal-custom">
        <Modal.Header className="border-0 position-relative">
          <button className="btn btn-link btn-close" onClick={handleClose}>
            <i className="fal fa-times fa-lg text-white"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="text-white">
          <h5 className="text-white mb-4">Datos personales</h5>
          <form action="">
            <Form.Group className="form-label-group">
              <FormControl
                name="name"
                type="text"
                placeholder="Ingresa tu nombre"
              />
              <Form.Label>Nombre</Form.Label>
              <Form.Control.Feedback type="invalid">Debes ingresar un nombre válido</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-label-group">
              <FormControl
                name="name"
                type="text"
                placeholder="Ingresa tu nombre"
              />
              <Form.Label>Apellido</Form.Label>
              <Form.Control.Feedback type="invalid">Debes ingresar un apellido válido</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-label-group">
              <FormControl
                name="name"
                type="email"
                placeholder="Ingresa tu nombre"
              />
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control.Feedback type="invalid">Debes ingresar un correo válido</Form.Control.Feedback>
            </Form.Group>
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-end">
                <Button variant="primary">Guardar</Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

Profile.propTypes = {
  names: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  picture: PropTypes.string
};

const stateToProps = state => ({
  names: state.user.userName,
  email: state.user.userEmail,
  picture: state.user.profilePic
});

export default connect(stateToProps, null)(Profile);
