import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import RegisterImg from './../../images/register-img.png';
import LogoRiivi from './../../images/logo-riivi.svg';
import { FacebookLogin, GoogleLogin, RegisterForm, ToolboxButton } from '../../components';
import TagManager from '../../utils/helpers/gtmDatalayer';

const toSigninFromSignup = () => {
  TagManager().toSigninFromSignup();
};
const closeSignup = () => {
  TagManager().closeSignup();
};

const RegisterView = () => {
  const location = useLocation();
  const [userToken, setUserToken] = useState(null);
  const [idp, setIdp] = useState(null);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('toolbox_user_token');
    const idpParam = new URLSearchParams(location.search).get('idp');
    if (token && idpParam) {
      setUserToken(token);
      setIdp(idpParam);
    }
  }, [location]);

  return (
    <div className="bg-register bg-gradient-primary h-100 py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-8 col-xl-7 position-relative">
            <Button
              as={Link}
              variant="light"
              className="btn-close-custom"
              to="/inicio"
              onClick={closeSignup}
            >
              <i className="fal fa-times fa-lg text-white"></i>
            </Button>
            <h1 className="text-white display-4 font-weight-bold mt-5">
              Encuentrate con latinoamérica
              <br /> <span className="text-pink-light">en Riivi</span>
            </h1>
            <Image src={RegisterImg} fluid className="d-block" />
          </div>
          <div className="col-sm-12 col-lg-4 col-xl-4 ml-auto">
            <div className="row">
              <div className="col-sm-9">
                <Image src={LogoRiivi} className="d-block mb-4" />
                <h4 className="text-white mb-4">Crea tu cuenta con tus redes sociales</h4>
              </div>
            </div>
            <div>
              <FacebookLogin title="Ingresar con Facebook" source="register" />
              <GoogleLogin title="Ingresar con Google" source="register" />
            </div>
            <div className="mb-4">
              <ToolboxButton userToken={userToken} idp={idp} />
            </div>
            <p className="text-white text-center background-line mb-4">
              <span>O crea tu cuenta con tu correo</span>
            </p>
            <RegisterForm />
            <p className="text-white text-center small mb-5 ">
              Al registrarte, estarás aceptando los{' '}
              <a
                rel="noopener noreferrer"
                href="https://riivi-public-resources.s3.amazonaws.com/docs/riivi_terminos-y-condiciones-de-uso.pdf"
                target="_blank"
              >
                términos y condiciones de uso
              </a>{' '}
              de Riivi
            </p>
            <div className="text-center">
              <p className="text-white mb-0">¿Tienes una cuenta con correo?</p>
              <Button
                as={Link}
                className="text-primary-light"
                variant="link"
                to="/login"
                onClick={toSigninFromSignup}
              >
                Ingresar con mi correo
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterView;
