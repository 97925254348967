import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import rootSaga from './saga';
import rootReducer from './reducer';
import { POST_LOGIN_SERVICE, POST_RESET_PASSWORD } from './login/types';

const initialState = {};
const enhancers = [];

const saga = createSagaMiddleware({
  onError: error => Sentry.captureException(error, Sentry.Severity.Warning)
});
const middleware = [saga];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    switch (action.type) {
    case POST_LOGIN_SERVICE:
      return null;
    case POST_RESET_PASSWORD:
      return null;

    default:
      return action;
    }
  }
});

enhancers.push(sentryReduxEnhancer);


const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const store = createStore(rootReducer, initialState, composedEnhancers);
saga.run(rootSaga);

export default store;
