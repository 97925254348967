import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Configure } from 'react-instantsearch-dom';
import { Notification, NavbarCustom, SearchBox, Loader, InstantSearch } from './components';
import { ResultsView } from './views';
import { GET_USER_INFO, FETCH_FAVORITES_LIST, FETCH_RATING_LIST } from './store/user/types';
import { SET_IS_SEARCHING } from './store/search/types';
import { SET_LOGIN_MODAL } from './store/emailconfirmation/types';
import { FETCH_SERVICE_AVAILABLE, SET_SHOW_MODAL, SET_MODAL_ID } from './store/player/types';
import CookieHandler from './utils/helpers/cookie_handler';
import TagManager from 'react-gtm-module';
import TagManagerHelper from './utils/helpers/gtmDatalayer';
import appConfig from './config';
import playerScript from './utils/helpers/playerScript';
import ModalItem from './components/modal_details';
import hotjarScript from './utils/helpers/hotjarScript';
import handlePaymentUrl from './utils/helpers/handlePaymentUrl';
import authHelper from './utils/helpers/auth';
import { POST_TOOLBOX_LOGIN } from './store/social/types';

const Layout = props => {
  const {
    getUserInfo,
    getFavoritesList,
    showLoginModal,
    setIsSearching,
    Component,
    isLogin,
    socialLoginError,
    isSearching,
    showNav = true,
    showSearch = true,
    guestRoute = false,
    privateRoute = false,
    path,
    exact = false,
    getRatingList,
    checkServices,
    setShowModal,
    showModal,
    setModalId,
    modalId,
    modalError,
    tenancy,
    tvRoute = false,
    currentModalState,
    skipRoute,
    toolboxLogin
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [toolboxToken, setToolboxToken] = useState(null);
  const initRef = useRef(true);
  const config = appConfig();
  const scriptsLoader = playerScript();
  const regex = RegExp(
    '(peliculas|series|documentales)/(.*)/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
  );
  const strPathName = props.location.pathname;
  const location = useLocation();
  const history = useHistory();
  const token = CookieHandler().getAccessToken() || null;

  useEffect(() => {
    if (modalError !== '') {
      setShowModal(false);
      history.push('/error');
    }
  }, [modalError, history, setShowModal]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rqpid = queryParams.get('rqpid');
    if (rqpid && !showModal) {
      !modalId && setModalId(rqpid);
      if (location.pathname !== '/inicio' && !currentModalState) {
        history.push(`/inicio?${queryParams.toString()}`);
      }
      setShowModal(true);
    }
  }, [location, history, setShowModal, showModal, setModalId, currentModalState, modalId]);

  useEffect(() => {
    setToolboxToken(new URLSearchParams(location.search).get('toolbox_user_token'));
  }, [location]);

  useEffect(() => {
    if (isLogin && location?.state?.purchase) {
      const { titleId, mediaType, mediaId } = location.state.purchase;
      const monetization = {
        offer: {
          media_id: mediaId,
          type: mediaType
        }
      };
      handlePaymentUrl(titleId, monetization, isLogin, history);
    }
  }, [location, isLogin, history]);

  useEffect(() => {
    checkServices();
  }, [checkServices]);

  useEffect(() => {
    const valueCount = localStorage.getItem('countReload');
    if (valueCount === null) {
      localStorage.setItem('countReload', 0);
    }
  }, []);

  useEffect(() => {
    const ids = ['player-avod', 'player-carousel', 'player-modal', 'player-tvod'];

    ids.forEach(id => {
      if (!document.getElementById(id)) {
        const div = document.createElement('div');
        div.id = id;
        document.body.appendChild(div);
      }
    });

    setPlayerLoaded(true);
  }, [strPathName, isSearching]);

  useEffect(() => {
    scriptsLoader.loadCanonicals();
  }, [scriptsLoader, path]);

  useEffect(() => {
    if (token) {
      getUserInfo();
      getFavoritesList();
      getRatingList();
      checkServices();
    } else {
      setIsLoading(false);
    }
  }, [getUserInfo, getFavoritesList, isLogin, getRatingList, checkServices, token, setIsLoading]);

  useEffect(() => {
    if (!regex.test(strPathName)) {
      scriptsLoader.jsonPlayer({}, true, 'layout');
    }
  }, [strPathName, scriptsLoader, regex]);

  useEffect(() => {
    if (isLogin) {
      setIsLoading(false);
    }
  }, [isLogin]);

  useEffect(() => {
    const userToken = new URLSearchParams(location.search).get('toolbox_user_token');
    const idp = new URLSearchParams(location.search).get('idp');
    if (userToken && idp) {
      toolboxLogin({ userToken, idp, login: authHelper().login });
    }
  }, [location, toolboxLogin]);

  useEffect(() => {
    const { toolboxCP, toolboxSpUrl } = config.socialLogin;
    const idp = new URLSearchParams(location.search).get('idp');
    if (socialLoginError.toolboxErrorLogin === 'TOOLBOX_NOT_AUTHORIZED') {
      const logoutSearchParams =  new URLSearchParams({
        url: `${window.location.origin}/${tenancy}/${idp}/no-autorizado?back=${location.pathname}`
      });
      window.location.href = `${toolboxSpUrl}/v2/auth/${toolboxCP}/logout.html?${logoutSearchParams.toString()}`;
    } else if (socialLoginError.toolboxErrorLogin === 'TOOLBOX_CANCEL') {
      const logoutSearchParams =  new URLSearchParams({
        url: `${window.location.origin}/${tenancy}/${idp}/error?back=${location.pathname}`
      });
      window.location.href = `${toolboxSpUrl}/v2/auth/${toolboxCP}/logout.html?${logoutSearchParams.toString()}`;
    }
  }, [tenancy, location.pathname, socialLoginError.toolboxErrorLogin, config.socialLogin, location.search]);

  const tagManagerArgs = {
    gtmId: config.tagManager.key
  };

  const initScripts = useCallback(() => {
    if (config.appEnv === 'production') {
      hotjarScript().hotjar();
    }
    if (config.appEnv === 'production' || config.appEnv === 'development') {
      TagManager.initialize(tagManagerArgs);
    }
  }, [config, tagManagerArgs]);

  useEffect(() => {
    if (initRef.current) {
      initScripts();
    }
    initRef.current = false;
  }, [initScripts]);

  const showSearchResults = item => {
    if (isSearching) {
      return <ResultsView query={item.query} />;
    }
    return <Component {...item.componentProps} />;
  };

  const showNavbar = () => {
    if (showNav) {
      return <NavbarCustom>{showSearch ? <SearchBox delay={500} /> : null}</NavbarCustom>;
    }
  };

  const searchStateHandling = input => {
    if (input.query) {
      TagManagerHelper().searchTitle(input.query);
      setSearchQuery(input.query);
      if (input.query.length > 2) {
        setIsSearching(true);
      }
    } else {
      setIsSearching(false);
    }
  };

  const render = componentProps => {
    if (skipRoute) {
      return <Redirect to={{ pathname: '/inicio' }} />;
    }
    if (isLoading) {
      // TODO: Make page loader
      return <Loader />;
    }
    if (isLogin) {
      if (guestRoute) {
        if (location.state && location.state.from === 'tvRoute') {
          return <Redirect to={{ pathname: '/ingresar-tv' }} />;
        } else {
          return <Redirect to={{ pathname: '/inicio' }} />;
        }
      }
    } else if (privateRoute && !window.refreshing) {
      if (tvRoute && toolboxToken) {
        if (socialLoginError?.facebookErrorLogin || socialLoginError?.googleErrorLogin || socialLoginError?.toolboxErrorLogin) {
          console.log(socialLoginError);
          return <Redirect to="/error" />
        }
      } else if (tvRoute) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: 'tvRoute'
              }
            }}
          />
        );
      } else {
        showLoginModal();
        return <Redirect to={{ pathname: '/inicio' }} />;
      }
    }

    if (showSearch) {
      return (
        <InstantSearch onSearchStateChange={searchStateHandling}>
          <Configure hitsPerPage={20} facetFilters={[`country_visibility: ${tenancy}`]} />
          {showNavbar()}
          {showSearchResults({ componentProps: componentProps, query: searchQuery })}
        </InstantSearch>
      );
    }
    return (
      <>
        {showNavbar()}
        <Component {...componentProps} />
      </>
    );
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={componentProps =>
        playerLoaded && (
          <>
            <Notification />
            {render(componentProps)}
            {showModal && <ModalItem setShowModal={setShowModal} showModal={showModal} />}
          </>
        )
      }
    />
  );
};

Layout.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  getFavoritesList: PropTypes.func.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  setIsSearching: PropTypes.func.isRequired,
  Component: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]),
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  isLogin: PropTypes.bool,
  socialLoginError: PropTypes.object,
  isSearching: PropTypes.bool,
  showNav: PropTypes.bool,
  showSearch: PropTypes.bool,
  guestRoute: PropTypes.bool,
  privateRoute: PropTypes.bool,
  location: PropTypes.object.isRequired,
  getRatingList: PropTypes.func.isRequired,
  checkServices: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setModalId: PropTypes.func.isRequired,
  modalId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  modalError: PropTypes.string.isRequired,
  tenancy: PropTypes.string.isRequired,
  tvRoute: PropTypes.bool,
  currentModalState: PropTypes.bool.isRequired,
  skipRoute: PropTypes.bool,
  toolboxLogin: PropTypes.func.isRequired
};

const stateToProps = ({ search, player, user, socialLogin }) => ({
  isSearching: search.isSearching,
  isLogin: user.isLogedIn,
  tenancy: player.tenancy,
  socialLoginError: socialLogin,
  showModal: player.showModal,
  modalError: player.modalError,
  currentModalState: player.currentModalState,
  modalId: player.modalId
});

const actionToProps = dispatch => ({
  getUserInfo: () => dispatch({ type: GET_USER_INFO }),
  getFavoritesList: () => dispatch({ type: FETCH_FAVORITES_LIST }),
  showLoginModal: () => dispatch({ type: SET_LOGIN_MODAL, payload: true }),
  setIsSearching: payload => dispatch({ type: SET_IS_SEARCHING, payload }),
  getRatingList: () => dispatch({ type: FETCH_RATING_LIST }),
  setShowModal: payload => dispatch({ type: SET_SHOW_MODAL, payload }),
  setModalId: payload => dispatch({ type: SET_MODAL_ID, payload }),
  checkServices: () => dispatch({ type: FETCH_SERVICE_AVAILABLE }),
  toolboxLogin: payload => dispatch({ type: POST_TOOLBOX_LOGIN, payload })
});

export default connect(stateToProps, actionToProps)(Layout);
