import React from 'react';
import { Slider } from '../';
import PropTypes from 'prop-types';
import adServer from '../../utils/constants/adServer';
import { Spinner } from 'react-bootstrap';

const Favorites = ({ favoritesMedia, isLoading }) => {
  const { LOCATIONS } = adServer();

  return (
    <>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      ) : favoritesMedia.length > 0 ? (
        <Slider explore={true} collectionName={LOCATIONS.FAVORITES}>
          {favoritesMedia.map((movie, index) => (
            <Slider.Item
              key={movie.id}
              title={movie}
              origin="explore"
              collectionIndex={index}
              titleIndex={index}
              collectionName={LOCATIONS.FAVORITES}
            />
          ))}
        </Slider>
      ) : (
        <p className="mb-3 text-center text-muted">No hay títulos para mi lista</p>
      )}
    </>
  );
};

Favorites.propTypes = {
  favoritesMedia: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Favorites;
