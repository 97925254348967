import React from 'react';
import LogoPurple from '../../images/logo-riivi-purple.svg';
import LogoGooglePlay from '../../images/logo-play-store.svg';
import LogoAppStore from '../../images/logo-app-store.svg';

const Footer = () => {
  return (
    <div className="container-fluid py-2">
      <div className="row">
        <div className="col-sm-6 col-lg-9 mb-3">
          <img src={LogoPurple} alt="" className="mb-4" />
          <p className="small">Propiedad intelectual © 2023 Riivi.</p>
          <p className="small">Todos los derechos reservados.</p>
          <ul className="list-unstyled d-flex">
            <li className="small mr-4">
              <a
                rel="noopener noreferrer"
                href="https://riivi-public-resources.s3.amazonaws.com/docs/riivi_terminos-y-condiciones-de-uso.pdf"
                target="_blank"
              >
                Términos y condiciones de uso
              </a>
            </li>
            <li className="small">
              <a
                rel="noopener noreferrer"
                href="https://riivi-public-resources.s3.amazonaws.com/docs/riivi_politica-de-privacidad.pdf"
                target="_blank"
              >
                Política de privacidad
              </a>
            </li>
          </ul>
        </div>
        <div className="col-sm-6 col-lg-3 mb-3">
          <ul className="list-unstyled d-flex mb-3">
            <li className="mr-4">
              <a
                href="https://www.facebook.com/riivilatam"
                rel="noopener noreferrer"
                target="_blank"
                className="text-white"
              >
                <i className="fab fa-facebook fa-2x"></i>
              </a>
            </li>
            <li className="mr-4">
              <a
                href="https://www.instagram.com/riivilatam"
                rel="noopener noreferrer"
                target="_blank"
                className="text-white"
              >
                <i className="fab fa-instagram fa-2x"></i>
              </a>
            </li>
            <li className="mr-4">
              <a
                href="https://www.tiktok.com/@riivilatam"
                rel="noopener noreferrer"
                target="_blank"
                className="text-white"
              >
                <i className="fab fa-tiktok fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href=" https://www.youtube.com/@riivilatam"
                rel="noopener noreferrer"
                target="_blank"
                className="text-white"
              >
                <i className="fab fa-youtube fa-2x"></i>
              </a>
            </li>
          </ul>
          <p className="small">Descarga nuestra app</p>
          <div className="d-flex">
            <a href="https://play.google.com/store/apps/details?id=com.riivi.riivi">
              <img src={LogoGooglePlay} className="logo-app-footer mr-2" alt="" />
            </a>
            <a href="https://apps.apple.com/cl/app/riivi/id1549483558">
              <img src={LogoAppStore} className="logo-app-footer" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <p className="small">
            Hecho con <i className="fas fa-heart"></i> en Chile
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
