import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, FormControl, Form, Spinner } from 'react-bootstrap';
import authHelper from '../../utils/helpers/auth';
import { loginSchema } from '../../utils/validator/schemas';
import validator from '../../utils/validator';
import {
  POST_LOGIN_SERVICE,
  POST_RESET_ERROR_LOGIN_MODAL,
  POST_RESET_LOGIN_SERVICE
} from '../../store/login/types';
import TagManager from '../../utils/helpers/gtmDatalayer';
import { POST_RE_SEND_EMAIL_CONFIRMATION } from '../../store/emailconfirmation/types';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';

const signinEmail = () => {
  TagManager().signinEmail();
};

const LoginForm = ({
  isLoged,
  loginUser,
  errorCode,
  reSendEmailConfirmation,
  dispatchToast,
  resetLoginErrors,
  resetErrors
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: ''
  });
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: ''
  });

  const [loadLogin, setLoadLogin] = useState(false);

  const handleInputChange = event => {
    setLoginForm({ ...loginForm, [event.target.name]: event.target.value });
    setValidationErrors({ ...validationErrors, [event.target.name]: '' });
    resetLoginErrors();
  };

  const handleSubmit = event => {
    event.preventDefault();
    const errors = validator(loginSchema, loginForm);

    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationErrors(messages);
      return false;
    }
    loginUser({ ...loginForm, login: authHelper().login });
    setLoadLogin(true);
  };

  const resendEmail = () => {
    const email = loginForm.email;
    return (
      <>
        <p>Debes activar tu cuenta antes de realizar esta acción.</p>
        <button
          type="button"
          className="text-primary link-button"
          onClick={() => {
            reSendEmailConfirmation({ email });
            dispatchToast({
              title: '¡Correo reenviado!',
              body: <p>Hemos enviado un correo de activación a {email}.</p>,
              type: 'info',
              delay: 15000
            });
            resetLoginErrors();
          }}
        >
          Reenviar correo de activación
        </button>
      </>
    );
  };

  useEffect(() => {
    if (errorCode === 'INACTIVE_ACCOUNT') {
      dispatchToast({
        title: '¡Oops!',
        body: resendEmail(),
        type: 'danger',
        delay: 15000
      });
      setTimeout(() => {
        resetLoginErrors();
      }, 15000);
    } else {
      setTimeout(() => {
        resetErrors();
      }, 15000);
    }
  });

  useEffect(() => {
    if (isLoged) {
      setLoadLogin(false);
    }
  }, [isLoged]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputEvents = event => {
    if (event.target.name === 'email') {
      TagManager().inputMailSignin();
    }
    if (event.target.name === 'password') {
      TagManager().inputPasswordSignin();
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div>
        <Form.Group className="form-label-group">
          <FormControl
            name="email"
            type="text"
            placeholder="Ingresa tu correo electrónico"
            autoComplete="username"
            onChange={handleInputChange}
            isInvalid={validationErrors.email}
            onClick={inputEvents}
          />
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-label-group">
          <FormControl
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Ingresa una contraseña"
            autoComplete="current-password"
            onChange={handleInputChange}
            isInvalid={validationErrors.password}
            className="form-password"
            onClick={inputEvents}
          />
          <Button className="btn-pass border-0" variant="link" onClick={toggleShowPassword}>
            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
          </Button>

          <Form.Label>Contraseña</Form.Label>
          <Form.Control.Feedback type="invalid">{validationErrors.password}</Form.Control.Feedback>
        </Form.Group>
        <p></p>
        <div
          className="invalid-feedback m-3"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {isLoged}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="primary"
            type="submit"
            className="btn-block btn-lg"
            onClick={signinEmail}
          >
            {loadLogin ? (
              <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
            ) : (
              'Ingresar'
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  isLoged: PropTypes.string,
  loginUser: PropTypes.func.isRequired,
  errorCode: PropTypes.string,
  reSendEmailConfirmation: PropTypes.func.isRequired,
  dispatchToast: PropTypes.func.isRequired,
  resetLoginErrors: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired
};

const stateToProps = ({ login }) => ({
  isLoged: login.logedIn,
  errorCode: login.loginErrorCode
});

const actionsToProps = dispatch => ({
  loginUser: payload => dispatch({ type: POST_LOGIN_SERVICE, payload }),
  reSendEmailConfirmation: payload => dispatch({ type: POST_RE_SEND_EMAIL_CONFIRMATION, payload }),
  dispatchToast: payload => dispatch({ type: SET_TOAST_NOTIFICATION, payload }),
  resetLoginErrors: payload => dispatch({ type: POST_RESET_ERROR_LOGIN_MODAL, payload }),
  resetErrors: payload => dispatch({ type: POST_RESET_LOGIN_SERVICE, payload })
});

export default connect(stateToProps, actionsToProps)(LoginForm);
