import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useImageProperties from '../../../utils/hooks/useImageProperties';

const BrandedItem = ({
  assets,
  title,
  fallbackImage,
  handleShow,
  tileHoverStatus,
  playMedia,
  orientationTiles
}) => {
  const { source, altText, fallbackClass, handleClick, showFallbackText } = useImageProperties(
    assets,
    title,
    fallbackImage,
    isMobile,
    handleShow,
    orientationTiles
  );

  return (
    <>
      <div className="item-branded">
        <img className={fallbackClass} src={source} alt={altText} onClick={handleClick} />
        {showFallbackText && (
          <div className="fallbackContainer">
            <span className="fallbackText">{title.name}</span>
          </div>
        )}
      </div>
      {tileHoverStatus && (
        <>
          <Button className="btn-play btn-blur" size="sm" onClick={playMedia(title)}>
            <i className="fas fa-play fa-lg"></i>
          </Button>
          <Button className="btn-branded-info btn-blur" size="sm" onClick={handleShow}>
            <i className="far fa-info-circle fa-lg"></i>
          </Button>
        </>
      )}
    </>
  );
};

BrandedItem.propTypes = {
  assets: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  fallbackImage: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  tileHoverStatus: PropTypes.bool.isRequired,
  playMedia: PropTypes.func.isRequired,
  orientationTiles: PropTypes.string.isRequired
};

export default BrandedItem;
