import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import LogoRiivi from './../../images/logo-riivi.svg';
import BgHeaderImg from './../../images/alternative-landing-bg.png';
import { RegisterForm } from '../../components';
import PlatformSection from '../../components/landing/platforms';
import { FETCH_SERVICE_AVAILABLE, SET_SERVICE_AVAILABLE } from '../../store/player/types';

const NotAvailableCustom = ({ serviceAvailable, checkServices, setServiceStatus, tenancy }) => {
  const history = useHistory();
  const [formShown, setFormShown] = useState(false);
  const country = {
    ar: {
      name: 'Argentina',
    },
    mx: {
      name: 'México',
    }
  };

  useEffect(() => {
    checkServices();
  }, [checkServices]);

  useEffect(() => {
    if (serviceAvailable === true) {
      setServiceStatus(null);
      history.push('/inicio');
    }
  }, [serviceAvailable, setServiceStatus, history]);

  const btnAction = () => {
    setFormShown(true);
  };

  return (
    <>
      <div className="bg-register bg-gradient-primary h-100 py-5">
        <div className="container">
          <div className="row">
            <div className={`col-sm-12 ${tenancy === 'ar' ? 'col-lg-8 col-xl-7' : ''} position-relative py-5`}>
              <h1 className="text-white display-4 font-weight-bold my-5">
                Disponible en
                <br /> <span className="text-pink-light">{country[tenancy]?.name}</span> en 2023.
              </h1>
              <h5 className="text-white mb-4">Pronto podrás unirte a la comunidad de amantes del cine Latinoamericano en Riivi.</h5>
              <div className="my-5 embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/4wUTRxrc8vs?autoplay=1&loop=1&playlist=4wUTRxrc8vs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            { tenancy === 'ar' &&
              <div className="col-sm-12 col-lg-4 col-xl-4 ml-auto d-flex align-items-center">
                <div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Image src={LogoRiivi} className="d-block mb-4" />
                      <h4 className="text-white mb-4">Pre-regístrate y participa en el <span className="text-green-light">sorteo de una Smart TV</span></h4>
                    </div>
                  </div>
                  {formShown? (
                    <>
                      <RegisterForm />
                    </>
                  ) : (
                    <div>
                      <Button variant="primary" className="btn-block mb-4" size="lg" onClick={btnAction}>
                        ¡Quiero registrarme!
                      </Button>
                    </div>)}

                  <p className="text-white text-center small mb-5 ">
                    Al registrarte, estarás aceptando los <a rel="noopener noreferrer" href="https://riivi-public-resources.s3.amazonaws.com/docs/riivi_terminos-y-condiciones-de-uso.pdf" target="_blank">términos y condiciones de uso</a> de
                    Riivi
                  </p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <Parallax bgImage={BgHeaderImg} strength={650}>
        <div style={{ height: 250 }}></div>
      </Parallax>
      <div className="bg-register-reverse bg-gradient-primary h-100 py-5">
        <div className="container">
          <PlatformSection />
        </div>
      </div>
    </>
  );
};

NotAvailableCustom.propTypes = {
  tenancy: PropTypes.string,
  serviceAvailable: PropTypes.bool,
  checkServices: PropTypes.func.isRequired,
  setServiceStatus: PropTypes.func.isRequired
};

const stateToProps = state => ({
  tenancy: state.player.tenancy,
  serviceAvailable: state.player.serviceAvailable
});

const actionToProps = dispatch => ({
  checkServices: () => dispatch({ type: FETCH_SERVICE_AVAILABLE }),
  setServiceStatus: payload => dispatch({ type: SET_SERVICE_AVAILABLE, payload })
});

export default connect(stateToProps, actionToProps)(NotAvailableCustom);
