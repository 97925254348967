import { takeLatest, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { clientRegistration } from '../../network';
import { POST_REGISTER_SERVICE, SET_REGISTER_SERVICE, SET_REGISTER_RRSS, POST_REGISTER_RRSS } from './types';
import { SET_ERROR_MESSAGE } from '../error/types';
import { registerErrors } from '../../utils/saga_errors';
import constants from '../../utils/constants/profile';

function* postRegistration(action) {
  try {
    const profile = yield call(clientRegistration, action.payload);
    yield put({ type: SET_REGISTER_SERVICE, payload: true });
    const { history } = action.payload;
    const { ACCOUNT_STATUS } = constants();

    if (profile.data.status === ACCOUNT_STATUS.WAITING_ACTIVATION) {
      history.push(`/exito?email=${profile.data.email}`);
    }
    else if (profile.data.status === ACCOUNT_STATUS.ACTIVE) {
      yield put({ type: SET_REGISTER_RRSS, payload: true });
      history.push('/inicio');
    }
    else {
      history.push('/inicio');
    }
  } catch (e) {
    if (e.response.data.error === 'EMAIL_ALREADY_TAKEN') {
      yield put({
        type: SET_ERROR_MESSAGE,
        payload: [registerErrors.EMAIL_ALREADY_TAKEN]
      });
    } else {
      yield put({
        type: SET_ERROR_MESSAGE,
        payload: [registerErrors.REGISTER_ERROR]
      });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* resetRegiteredRRSS() {
  yield put({ type: SET_REGISTER_RRSS, payload: false });
}

function* watchPostRegistration() {
  yield takeLatest(POST_REGISTER_SERVICE, postRegistration);
}

function* watchResetRegisteredRRSS() {
  yield takeLatest(POST_REGISTER_RRSS, resetRegiteredRRSS);
}

export default [watchPostRegistration(), watchResetRegisteredRRSS()];
