import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Slider } from '../';
import BgCategories from './styles';
import BackgroundImage from '../../images/bg-featured-tile.jpg';
import formatUrl from '../../utils/helpers/formatUrl';
import TagManager from '../../utils/helpers/gtmDatalayer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useResumeTitles from '../../utils/hooks/useResumeTitles';

const Category = ({ id, name, collectionIndex }) => {
  const history = useHistory();
  const [titles, setTitles] = useState([]);
  const collectionTitles = useSelector(({ player }) => player.collectionsTitles);
  const continueWatching = useSelector(({ user }) => user.continueWatching);
  const resumeTitles = useResumeTitles(titles, continueWatching);

  useEffect(() => {
    const collection = collectionTitles.find(collection => collection.id === id);
    if (collection && collection.items.length > 0) {
      setTitles(collection.items);
    }
  }, [collectionTitles, id]);

  const exploreButton = collectionData => () => {
    TagManager().exploreCollectionCarrousel({
      collectionName: collectionData.name,
      position: collectionIndex
    });
    const formatCollection = formatUrl().getCleanedString(collectionData.name);
    history.push(`/coleccion/${formatCollection}/${collectionData.id}`);
  };

  const setBackground = titleList => {
    let collectionBackground = '';
    if (titleList.length > 0) {
      const assets = titleList[0].assets;
      if (assets.find(ass => ass.type === 'poster_h')) {
        collectionBackground = assets.find(ass => ass.type === 'poster_h').url;
        return collectionBackground;
      }
    }
    return BackgroundImage;
  };

  const SkeletonCategory = () => (
    <div className="container-fluid overflow-hidden py-2">
      <Row className="mb-4">
        <Col xs="6" sm="4" lg="2">
          <SkeletonTheme color="#eee" highlightColor="#fff">
            <Skeleton height={36} />
          </SkeletonTheme>
        </Col>
      </Row>
      <Row>
        <SkeletonTheme color="#eee" highlightColor="#fff">
          <Col xs="12" className="d-flex">
            {[...Array(7)].map((_, i) => (
              <Skeleton height={130} key={i} className="skeleton-box-1 mx-1" />
            ))}
          </Col>
        </SkeletonTheme>
      </Row>
    </div>
  );

  const render = () => {
    if (resumeTitles.length < 1) {
      return <SkeletonCategory />;
    }
    return (
      <BgCategories background={setBackground(resumeTitles)}>
        <div className="container-fluid container-category py-3">
          <Row className="mb-3">
            <Col sm="auto" className="category-container-title">
              <h3 className="mr-3 mb-0">{name}</h3>
              <Button
                className="text-purple-pink btn-explore"
                onClick={exploreButton({ name: name, id: id })}
              >
                Explorar
              </Button>
            </Col>
          </Row>
          <Slider collectionName={name}>
            {resumeTitles.map((title, index) => (
              <Slider.Item
                key={title.id}
                title={title}
                collectionIndex={collectionIndex}
                titleIndex={index}
                collectionName={name}
                origin="discovery"
              />
            ))}
          </Slider>
        </div>
      </BgCategories>
    );
  };

  return render();
};

Category.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  collectionIndex: PropTypes.number.isRequired
};

export default Category;
