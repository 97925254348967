export default () => ({
  getCleanedString: (element) => {
    var specialChars = '!@#$^&%*()+=-[]/{}|:<>?,.…';

    for (var i = 0; i < specialChars.length; i++) {
      element = element.replace(new RegExp('\\' + specialChars[i], 'gi'), '');
    }

    element = element.toLowerCase();
    element = element.replace(/ /g, '-');
    element = element.replace(/á/gi, 'a');
    element = element.replace(/é/gi, 'e');
    element = element.replace(/í/gi, 'i');
    element = element.replace(/ó/gi, 'o');
    element = element.replace(/ú/gi, 'u');
    element = element.replace(/ñ/gi, 'n');
    return element;
  },

  translateType: (type) => {
    switch (type) {
    case 'movie':
      return 'peliculas';
    case 'series':
      return 'series';
    case 'other':
      return 'documentales';
    default:
      return type;
    }
  }
});