import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useImageProperties from '../../../utils/hooks/useImageProperties';
import { useHistory } from 'react-router-dom';
import handlePaymentUrl from '../../../utils/helpers/handlePaymentUrl';
import getDurationOrSeason from '../../../utils/helpers/getDurationOrSeason';

const TvodItem = ({
  assets,
  title,
  fallbackImage,
  handleShow,
  tileHoverStatus,
  playMedia,
  orientationTiles,
  isLogin
}) => {
  const { source, altText, fallbackClass, handleClick, showFallbackText } = useImageProperties(
    assets,
    title,
    fallbackImage,
    isMobile,
    handleShow,
    orientationTiles
  );
  const history = useHistory();

  const setGenresArray = gen => {
    if (gen) {
      return gen.map(act => act.name).join(', ');
    }
    return '';
  };

  const renderImage = () => (
    <div className="item-tvod">
      <img className={fallbackClass} src={source} alt={altText} onClick={handleClick} />
      {showFallbackText && (
        <div className="fallbackContainer">
          <span className="fallbackText">{title.name}</span>
        </div>
      )}
    </div>
  );

  const renderInfo = () => {
    if (orientationTiles === 'landscape') {
      return (
        <div className="d-flex justify-content-between">
          <div className="tvod-info-content d-none d-xl-block">
            <div className="d-flex align-items-center mb-2">
              <h2 className="mr-3">{title.name}</h2>
              <Badge variant="gold-500" className="font-weight-bold">
                <i className="fas fa-ticket-alt fa-lg" />
                {title?.entitlement && (
                  <span className="ml-1">{title?.entitlement?.remaining}</span>
                )}
              </Badge>
            </div>
            <div className="d-flex">
              <h6 className="mr-3">{getDurationOrSeason(title)}</h6>
              <h6 className="mr-3">{setGenresArray(title.genres)}</h6>
              <h6 className="mr-3">{title.release_year}</h6>
            </div>
          </div>
        </div>
      );
    } else if (orientationTiles === 'portrait') {
      return (
        title.entitlement && (
          <Badge variant="gold-500" className="tvod-badge font-weight-bold">
            <i className="fas fa-ticket-alt fa-lg" />
            {title?.entitlement && <span className="ml-1">{title?.entitlement?.remaining}</span>}
          </Badge>
        )
      );
    }
    return null;
  };

  const renderHoverContent = () =>
    tileHoverStatus && (
      <>
        {title.entitlement && (
          <Button className="btn-play" size="sm" variant="gold-500" onClick={playMedia(title)}>
            <i className="fas fa-play fa-lg"></i>
          </Button>
        )}
        <div className="bg-gradient-darks" />
        {renderInfo()}
        <div className="tvod-item-content">
          <Button className="btn-more-info btn-blur" onClick={handleShow}>
            <div className="d-flex align-items-center">
              <i className="far fa-info-circle fa-lg" />
              <span className="ml-1">Más info</span>
            </div>
          </Button>
          {!title.entitlement && (
            <Button
              disabled={!title.monetization?.offer}
              variant="gold-500"
              className="btn-rounded ml-2"
              onClick={() => handlePaymentUrl(title.id, title.monetization, isLogin, history)}
            >
              <i className="fas fa-ticket-alt fa-lg mr-1" />
              <span className="ml-1">
                {title?.monetization?.offer?.price_label || 'No disponible'}
              </span>
            </Button>
          )}
        </div>
      </>
    );

  return (
    <>
      {renderImage()}
      {renderHoverContent()}
    </>
  );
};

TvodItem.propTypes = {
  assets: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  fallbackImage: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  tileHoverStatus: PropTypes.bool.isRequired,
  playMedia: PropTypes.func.isRequired,
  orientationTiles: PropTypes.string.isRequired,
  isLogin: PropTypes.bool.isRequired
};

export default TvodItem;
