import styled from 'styled-components';

const BgProfile = styled.section`
  position: relative;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => `radial-gradient(circle, rgba(18,0,36,0.2) 0%, rgba(18,0,36,1) 40%), url(${props.background})`};
    background-position: center center;
    background-size: cover;
    filter: blur(20px);
    opacity: 0.2;
  }
`;

export default BgProfile;
