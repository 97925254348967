import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import LogoPink from '../../images/logo-riivi-pink.svg';
import BgHeaderImg from '../../images/bg-header-landing.png';
import TagManager from '../../utils/helpers/gtmDatalayer';
import BgHeader from '../landing/styles';
import appConfig from '../../config';
import LogoRiivi from './../../images/logo-riivi.svg';
import { FETCH_SERVICE_AVAILABLE, SET_SERVICE_AVAILABLE } from '../../store/player/types';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.css';

const NotAvailableDefault = ({ serviceAvailable, checkServices, setServiceStatus }) => {
  const history = useHistory();

  useEffect(() => {
    TagManager().landingFunnel();
  }, []);

  useEffect(() => {
    checkServices();
  }, [checkServices]);

  useEffect(() => {
    if (serviceAvailable === true) {
      setServiceStatus(null);
      history.push('/inicio');
    }
  }, [serviceAvailable, setServiceStatus, history]);

  const { imagesCDN } = appConfig();

  const imageProvider = src => {
    return imagesCDN ? `${imagesCDN}/${src}` : src;
  };

  return (
    <div className="container-landing">
      <BgHeader background={imageProvider(BgHeaderImg)} className="d-flex justify-content-center align-items-center position-relative">
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col-lg-4 mx-auto">
              <img src={LogoRiivi} alt="" className="d-block mx-auto" />
            </div>
          </div>
          <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div className="row">
              <div className="col-lg-10 col-xl-8 mx-auto text-center">
                <h1 className="display-3 font-weight-bold title-landing mb-5">
                  ¡Perdón! Riivi no está disponible en tu país aún.
                </h1>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-xl-5 mx-auto text-center">
                <h4 className="mb-3">Pronto llegaremos a más lugares, revisa nuestras redes para mayor información</h4>
              </div>
            </div>
            <div className="row justify-content-center mb-4">
              <ul className="list-unstyled d-flex">
                <li className="mr-4">
                  <a
                    href="https://www.facebook.com/riivitv"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-white"
                  >
                    <i className="fab fa-facebook fa-3x"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/riivitv/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-white"
                  >
                    <i className="fab fa-instagram fa-3x"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="row justify-content-center">
              <img src={imageProvider(LogoPink)} alt="" className="d-block mx-auto" />
            </div>
          </ScrollAnimation>
        </div>
      </BgHeader>
    </div>
  );
};

NotAvailableDefault.propTypes = {
  serviceAvailable: PropTypes.bool,
  checkServices: PropTypes.func.isRequired,
  setServiceStatus: PropTypes.func.isRequired
};

const stateToProps = state => ({
  serviceAvailable: state.player.serviceAvailable
});

const actionToProps = dispatch => ({
  checkServices: () => dispatch({ type: FETCH_SERVICE_AVAILABLE }),
  setServiceStatus: payload => dispatch({ type: SET_SERVICE_AVAILABLE, payload })
});

export default connect(stateToProps, actionToProps)(NotAvailableDefault);
