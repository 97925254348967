export const SET_LOGIN_SERVICE = 'login/SET_LOGIN_SERVICE';
export const POST_LOGIN_SERVICE = 'saga/login/POST_LOGIN_SERVICE';
export const POST_FORGOT_PASSWORD = 'saga/login/POST_FORGOT_PASSWORD';
export const POST_RESET_PASSWORD = 'saga/login/POST_RESET_PASSWORD';
export const RESET_LOGIN_SERVICE = 'login/RESET_LOGIN_SERVICE';
export const POST_RESET_LOGIN_SERVICE = 'saga/login/POST_RESET_LOGIN_SERVICE';
export const SET_ERROR_LOGIN_MODAL = 'login/SET_ERROR_LOGIN_MODAL';
export const RESET_ERROR_LOGIN_MODAL = 'login/RESET_ERROR_LOGIN_MODAL';
export const POST_RESET_ERROR_LOGIN_MODAL = 'login/POST_RESET_ERROR_LOGIN_MODAL';
export const SET_ERROR_RESTORE_PASS = 'login/SET_ERROR_RESTORE_PASS';
export const RESET_ERROR_RESTORE_PASS = 'login/RESET_ERROR_RESTORE_PASS';
export const POST_RESET_ERROR_RESTORE_PASS = 'login/POST_RESET_ERROR_RESTORE_PASS';
export const POST_TV_CODE = 'login/POST_TV_CODE';
