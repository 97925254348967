import React from 'react';
import PropTypes from 'prop-types';
import { Category, CategoryBrand, CategoryTvod } from '../';

const CollectionWrapper = ({ collection, index }) => {
  switch (collection.type) {
  case 'NORMAL_COLLECTION':
    return <Category key={collection.id} collectionIndex={index} {...collection} />;
  case 'BRANDED_COLLECTION':
    return <CategoryBrand key={collection.id} collectionIndex={index} {...collection} />;
  case 'TVOD_COLLECTION':
    return <CategoryTvod key={collection.id} collectionIndex={index} {...collection} />;
  default:
    return null;
  }
};

CollectionWrapper.propTypes = {
  collection: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired
};

export default CollectionWrapper;
