import {
  SET_EMAIL_CONFIRMATION,
  SET_TITLE_CONFIRMATION,
  SET_BUTTON_CONFIRMATION,
  SET_RE_SEND_EMAIL_CONFIRMATION,
  SET_LOGIN_MODAL
} from './types';

const initialState = {
  emailConfirmed: {},
  title: [],
  button: '',
  reSendEmailConfirmed: '',
  modalLogin: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_EMAIL_CONFIRMATION:
    return { ...state, emailConfirmed: payload };
  case SET_TITLE_CONFIRMATION:
    return { ...state, title: payload };
  case SET_BUTTON_CONFIRMATION:
    return { ...state, button: payload };
  case SET_RE_SEND_EMAIL_CONFIRMATION:
    return { ...state, reSendEmailConfirmed: payload };
  case SET_LOGIN_MODAL:
    return { ...state, modalLogin: payload };
  default:
    return state;
  }
};
