import { useMemo } from 'react';

const useImagePropertie = (
  assets,
  title,
  fallbackImage,
  isMobile,
  handleShow,
  orientationTiles
) => {
  return useMemo(() => {
    let source,
      altText,
      fallbackClass,
      showFallbackText = false;

    if (orientationTiles) {
      source = orientationTiles === 'landscape' ? assets.poster_h : assets.poster_v;
      altText = title.name;
    } else if (assets && assets.poster_h) {
      source = assets.poster_h;
      altText = title.name;
    } else if (title && title.resume) {
      source = title.resume.thumbnail;
      altText = title.name;
    } else {
      source = fallbackImage;
      altText = title.name;
      fallbackClass = 'fallbackImage';
      showFallbackText = true;
    }

    const handleClick = isMobile ? handleShow : null;

    return { source, altText, fallbackClass, handleClick, showFallbackText };
  }, [assets, title, fallbackImage, isMobile, handleShow, orientationTiles]);
};

export default useImagePropertie;
