import React, { useEffect, useState, useCallback } from 'react';
import { Button, Container, Spinner, Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import config from '../../config';
import { Profile, Favorites, Footer, ModalConfirm, Watching, Tickets } from '../../components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CookieHandler from '../../utils/helpers/cookie_handler';
import {
  FETCH_CONTINUE_WATCHING,
  FETCH_ENTITLEMENTS,
  FETCH_FAVORITES,
  DELETE_ACCOUNT,
  SET_ISLOGEDIN,
  SET_DELETE_ACCOUNT,
  SET_USER_EMAIL,
  SET_USER_NAME
} from '../../store/user/types';
import { useLocation } from 'react-router-dom';

const { toolboxCP, toolboxSpUrl } = config().socialLogin;

const ProfileView = ({
  idp,
  userCreationDate,
  fetchContinueWatching,
  resetAccountDeleted,
  fetchFavorites,
  fetchEntitlements,
  continueWatching,
  entitlements,
  accountDeleted,
  favoritesMedia,
  watchingLoading,
  favoritesLoading,
  entitlementsLoading,
  deleteAccount,
  emptyUserName,
  emptyUserEmail,
  logedOut,
}) => {
  const [activeKey, setActiveKey] = useState('my-list');
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const logout = useCallback(() => {
    CookieHandler().removeToken();
    emptyUserName();
    emptyUserEmail();
    logedOut();
    resetAccountDeleted();
    history.push('/inicio');
    if (idp && /^toolbox-/.test(idp)) {
      const logoutSearchParams = new URLSearchParams({ url: window.location.href });
      window.location.href = `${toolboxSpUrl}/v2/auth/${toolboxCP}/logout.html?${logoutSearchParams.toString()}`;
    }
  }, [emptyUserEmail, emptyUserName, history, idp, logedOut, resetAccountDeleted]);

  const handleAccountDeleted = useCallback(() => {
    setShowModal(false);
    setModalLoading(false);
    logout();
  }, [logout]);

  useEffect(() => {
    fetchFavorites();
    fetchContinueWatching();
    fetchEntitlements();
  }, [fetchFavorites, fetchContinueWatching, fetchEntitlements]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let rqpak = queryParams.get('rqpak');
    if (rqpak) {
      setActiveKey(rqpak);
    }
  }, [location, setActiveKey]);

  useEffect(() => {
    if (accountDeleted === true) {
      handleAccountDeleted();
    }
  }, [accountDeleted, handleAccountDeleted]);

  return (
    <>
      <Container fluid>
        <Profile />
        <Tabs
          activeKey={activeKey}
          onSelect={key => setActiveKey(key)}
          id="profile-tabs"
          className="tabs-custom overflow-x-auto"
        >
          <Tab eventKey="my-list" title="Mi lista">
            <Favorites favoritesMedia={favoritesMedia} isLoading={watchingLoading} />
          </Tab>
          <Tab eventKey="watching" title="Continuar viendo">
            <Watching continueWatching={continueWatching} isLoading={favoritesLoading} />
          </Tab>
          <Tab eventKey="my-tickets" title="Mis Entradas">
            <Tickets entitlements={entitlements} isLoading={entitlementsLoading} />
          </Tab>
          <Tab eventKey="config" title="Configuración">
            <div className="d-flex justify-content-center" style={{ height: 250 }}>
              <div className="mx-3" style={{width: '500px'}}>
                <h6>Mi cuenta</h6>
                <div className="d-flex justify-content-between">
                  <span style={{ color: '#a18fb3' }}>Usuario desde {userCreationDate ? moment(userCreationDate).year() : '-'}</span>
                  <Button onClick={() => setShowModal(true)}>Eliminar cuenta</Button>
                </div>
              </div>
              <ModalConfirm
                title="¿De verdad deseas eliminar tu cuenta de Riivi?"
                description="Tu perfil será eliminado permanentemente, incluyendo toda tu información."
                show={showModal}
                loading={modalLoading}
                actionButtons={(
                  <div className="row">
                    <div className="col-6">
                      <Button
                        className="btn btn-block text-purple mr-3 btn-actions"
                        variant="danger"
                        disabled={modalLoading}
                        onClick={() => {
                          setModalLoading(true);
                          deleteAccount();
                        }}
                      >
                        {modalLoading && (<Spinner
                          className="mr-2"
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />)}
                        Eliminar cuenta
                      </Button>
                    </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-block text-purple mr-3 btn-actions"
                      variant="light"
                      disabled={modalLoading}
                      onClick={() => setShowModal(false)}
                    >
                      Cancelar
                    </Button>
                  </div>
                </div>
              )}
              />
            </div>
          </Tab>
        </Tabs>
      </Container>
      <Footer />
    </>
  );
};

ProfileView.propTypes = {
  fetchContinueWatching: PropTypes.func.isRequired,
  fetchFavorites: PropTypes.func.isRequired,
  fetchEntitlements: PropTypes.func.isRequired,
  resetAccountDeleted: PropTypes.func.isRequired,
  continueWatching: PropTypes.array.isRequired,
  entitlements: PropTypes.array.isRequired,
  favoritesMedia: PropTypes.array.isRequired,
  watchingLoading: PropTypes.bool.isRequired,
  entitlementsLoading: PropTypes.bool.isRequired,
  favoritesLoading: PropTypes.bool.isRequired,
  accountDeleted: PropTypes.bool.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  emptyUserName: PropTypes.func.isRequired,
  emptyUserEmail: PropTypes.func.isRequired,
  logedOut: PropTypes.func.isRequired,
  idp: PropTypes.string,
  userCreationDate: PropTypes.string,
};

const stateToProps = ({ user }) => ({
  idp: user.idp,
  userCreationDate: user.createdAt,
  continueWatching: user.continueWatching,
  entitlements: user.entitlements,
  favoritesMedia: user.favoritesMedia,
  watchingLoading: user.loadingContinueWatching,
  entitlementsLoading: user.loadingEntitlements,
  favoritesLoading: user.loadingFavorites,
  accountDeleted: user.accountDeleted
});

const actionToProps = dispatch => ({
  fetchContinueWatching: payload => dispatch({ type: FETCH_CONTINUE_WATCHING, payload }),
  fetchFavorites: payload => dispatch({ type: FETCH_FAVORITES, payload }),
  fetchEntitlements: payload => dispatch({ type: FETCH_ENTITLEMENTS, payload }),
  deleteAccount: () => dispatch({ type: DELETE_ACCOUNT }),
  emptyUserName: () => dispatch({ type: SET_USER_NAME, payload: '' }),
  emptyUserEmail: () => dispatch({ type: SET_USER_EMAIL, payload: '' }),
  resetAccountDeleted: () => dispatch({ type: SET_DELETE_ACCOUNT, payload: false }),
  logedOut: () => dispatch({ type: SET_ISLOGEDIN, payload: false })
});

export default connect(stateToProps, actionToProps)(ProfileView);
