import React, { useCallback } from 'react';
import { Badge, Button, ProgressBar, Spinner } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useIntersectionObserver } from '../../utils/hooks/useInterceptorObserver';
import PropTypes from 'prop-types';

function Episodes({
  modalData,
  myEpisodes,
  playMedia,
  handlePaymentUrl,
  isLogin,
  history,
  setShowModal,
  PLAYER_TYPES,
  TVOD_STATUS,
  setNextPage,
  episodesHasMore
}) {
  const { nodeRef, showLoader } = useIntersectionObserver({
    onIntersect: useCallback(() => setNextPage(prevPage => prevPage + 1), [setNextPage]),
    hasMore: episodesHasMore
  });

  const renderLoader = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="light" className="spinner-custom" />
      </div>
    );
  };

  const statusColorBadge = episode => {
    if (!episode?.entitlement || !episode?.offer) {
      return 'blur-gray';
    }
    if (episode?.entitlement?.status === TVOD_STATUS.NOT_WATCHED) {
      return 'blur-gray';
    } else if (episode?.entitlement?.status === TVOD_STATUS.WATCHING) {
      return 'pink-fluorescent';
    } else {
      return 'gold-500';
    }
  };

  const purchaseEpisodeButton = episode => {
    return (
      modalData.monetization?.type === PLAYER_TYPES.TVOD &&
      !episode.entitlement &&
      episode.offer?.price_label && (
        <div className="purchase-episode-btn">
          <Button
            variant="gold-500"
            size="md"
            onClick={() => {
              handlePaymentUrl(modalData.id, episode, isLogin, history);
              !isLogin && setShowModal(false);
            }}
            className="button-play"
          >
            <i className="fas fa-ticket-alt fa-md mr-1"></i>
            <span className="ml-1">{episode.offer?.price_label}</span>
          </Button>
        </div>
      )
    );
  };

  const showStatusBadge = episode => {
    if (modalData.monetization?.type === PLAYER_TYPES.TVOD) {
      return (
        <Badge variant={statusColorBadge(episode)} className="ticket-badge mb-2">
          {episode?.entitlement?.remaining && <i className="fas fa-ticket-alt fa-md text-white" />}
          <span className="font-weight-bold ml-1 text-white">
            {episode?.entitlement?.remaining || (!episode?.offer && 'No disponible')}
          </span>
        </Badge>
      );
    }
  };

  const handleClickEpisodes = episode => {
    if (episode.entitlement || !modalData.monetization) {
      playMedia(episode.id);
    }
  };

  const playButtonClass = () => {
    if (modalData.monetization?.type === PLAYER_TYPES.TVOD) {
      return 'is-paid';
    }

    return '';
  };

  const episodeCardClass = () => {
    let classes = '';

    if (modalData.monetization?.type === PLAYER_TYPES.TVOD) {
      classes += 'bg-blur-gray';
    } else {
      classes += 'bg-darker';
    }

    return classes;
  };

  const renderSkeleton = skeletonNumber => {
    return (
      <SkeletonTheme color="#eee" highlightColor="#fff">
        {Array.from({ length: skeletonNumber }).map((_, index) => (
          <div className="media mb-3" key={index}>
            <Skeleton height={100} width={260} className="mb-3 mr-3" />
            <div className="media-body d-block">
              <Skeleton height={10} width={100} className="mb-2 d-block" />
              <Skeleton height={20} width={300} className="mb-3 d-block" />
              <Skeleton height={8} width={160} className="mb-2 d-block" />
              <Skeleton height={8} width={160} className="mb-2 d-block" />
            </div>
          </div>
        ))}
      </SkeletonTheme>
    );
  };

  const renderEpisodes = myEpisodes.map((episode, index) => (
    <div
      className={`card card-episode mb-3 ${episodeCardClass()}`}
      onClick={() => {
        handleClickEpisodes(episode);
      }}
      key={episode.id}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4">
            <div className="position-relative">
              <img
                src={episode.thumbnail}
                alt={episode.name}
                className="img-fluid episode-cover my-3"
              />
              {(!modalData.monetization ||
                (modalData.monetization?.type === PLAYER_TYPES.TVOD && episode.entitlement)) && (
                <div className={`play-episode-icon ${playButtonClass()}`}>
                  <i className="fas fa-play"></i>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column">
            <p className="small mb-1">{episode.duration}</p>
            <div className="d-flex align-items-center m-0">
              <h5 className="mr-2">{episode.name}</h5>
              {showStatusBadge(episode)}
            </div>
            <p className="p-episode mb-2">{episode.description}</p>
            {episode.resume && (
              <div className="d-flex align-items-center">
                <ProgressBar
                  className={`progress-bar-custom progress-bar-purple mb-0 ${
                    modalData.monetization?.type === PLAYER_TYPES.TVOD ? 'progress-bar-paid' : ''
                  }`}
                  variant="danger"
                  now={episode.resume.completed}
                />
                <p className="small mb-0 ml-2">Quedan {episode.resume.remaining}</p>
              </div>
            )}
            <div className="d-flex flex-row-reverse">{purchaseEpisodeButton(episode, index)}</div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="episode-container">
      {myEpisodes.length < 1 ? renderSkeleton(5) : renderEpisodes}
      {showLoader && renderLoader()}
      <div ref={nodeRef} style={{ height: '1rem' }} />
    </div>
  );
}

Episodes.propTypes = {
  modalData: PropTypes.object.isRequired,
  myEpisodes: PropTypes.array.isRequired,
  playMedia: PropTypes.func.isRequired,
  handlePaymentUrl: PropTypes.func.isRequired,
  isLogin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  PLAYER_TYPES: PropTypes.object.isRequired,
  TVOD_STATUS: PropTypes.object.isRequired,
  setNextPage: PropTypes.func.isRequired,
  episodesHasMore: PropTypes.bool.isRequired
};

export default Episodes;
