import axios from 'axios';
import recaptchaNetwork from './recaptcha';
import registerClient from './register';
import emailConfirmNetwork from './emailconfirmation';
import loginNetwork from './login';
import userNetwork from './user';
import config from '../config';
import socialNetwork from './sociallogin';
import playerNetwork from './player';
import authInterceptor from '../utils/helpers/axiosApiInterceptor';
import geoInterceptor from '../utils/helpers/geoBlockInterceptor';

const appConfig = config();
const httpConfig = {
  baseURL: appConfig.appEnv !== 'local' ? appConfig.proxyUrl : '/api',
  timeout: appConfig.timeout
};
const http = axios.create(httpConfig);
const httpAuth = axios.create(httpConfig);

const interceptor = authInterceptor(httpAuth);
const geoBlock = geoInterceptor();
httpAuth.interceptors.request.use(interceptor.authRequest, error => Promise.reject(error));
http.interceptors.response.use(response => response, geoBlock.response);
httpAuth.interceptors.response.use(response => response, interceptor.authResponse);
httpAuth.interceptors.response.use(response => response, geoBlock.response);

const recaptcha = recaptchaNetwork(http);
const register = registerClient(http);
const confirm = emailConfirmNetwork(http);
const login = loginNetwork(http);
const social = socialNetwork(http);
const player = playerNetwork(httpAuth);
const user = userNetwork(httpAuth);

export const verifyRecaptcha = recaptcha.siteVerify;
export const clientRegistration = register.registerClient;
export const emailConfirmation = confirm.emailConfirm;
export const reSendConfirmation = confirm.reSendEmailConfirm;
export const loginUser = login.loginClient;
export const userInfo = user.userInfo;
export const deleteProfile = user.deleteProfile;
export const postFavourite = user.favourites;
export const getFavourite = user.getFavourites;
export const storeResumeTitle = user.storeResume;
export const storeResumeSeries = user.storeResumeSeries;
export const getResumeTime = user.getResumeTime;
export const removeWatching = user.removeWatching;
export const forgotPassword = login.forgotPassword;
export const resetPassword = login.resetPassword;
export const facebookLogin = social.facebookLogin;
export const googleLogin = social.googleLogin;
export const toolboxMso = social.toolboxMso;
export const toolboxLogin = social.toolboxLogin;
export const fetchCollections = player.fetchCollections;
export const fetchCollectionTitles = player.fetchCollectionTitles;
export const movieDetails = player.movieDetails;
export const featuredMedia = player.featuredMedia;
export const exploreMovies = player.exploreMovies;
export const fetchPlaylist = player.fetchPlaylist;
export const fetchVideoData = player.fetchVideoData;
export const exploreGenre = player.exploreGenre;
export const browseCollections = player.browseCollections;
export const fetchEpisodes = player.fetchEpisodes;
export const suggestTitle = player.suggestTitle;
export const healthCheck = player.healthCheck;
export const postRating = user.rating;
export const getRating = user.fetchRatingMedia;
export const tvCode = user.tvCode;
export const fetchUserTitles = user.fetchUserTitles;
export const fetchResumeTitles = user.fetchResumeTitles;
export const fetchTrailerSource = player.fetchTrailerSource;
export const fetchEntitlements = user.fetchEntitlements;
export const postEntitlementKey = user.postEntitlementKey;
export const fetchEntitlementsMedia = user.fetchEntitlementsMedia;
export const fetchMediaSign = user.fetchMediaSign;
