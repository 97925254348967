const player = () => ({
  playerMovieDetails: '/catalogue/titles',
  videoData: '/catalogue/media',
  collectionsUrl: '/catalogue/collections',
  playerFeaturedMedia: '/catalogue/featured',
  sliderToShowCarousel: 5,
  browse: '/catalogue/browse',
  exploreGenre: '/catalogue/genres',
  playTimeCounter: process.env.REACT_APP_SAVE_PLAYTIME_COUNTER || 15000,
  valueAd: process.env.REACT_APP_VALUE_AD,
  playerSerieDetails: '/catalogue/season',
  suggestTitle: '/catalogue/suggest',
  healthCheck: '/catalogue',
  muxKey: process.env.REACT_APP_MUX_KEY,
  bitmovinKey: process.env.REACT_APP_BITMOVIN_KEY,
  subPropertyId: process.env.REACT_APP_SUB_PROPERTY_ID,
  trailerSource: '/catalogue/videos',
});

export default player;
