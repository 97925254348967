import styled from 'styled-components';

const BgCategories = styled.section`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${props =>
    `linear-gradient(
        to right,
        rgb(13, 0, 24) 0.5%,
        rgba(0, 0, 0, 0),
        rgb(13, 0, 24)
      ),
      url(${props.background})`};
    background-position: center center;
    background-size: cover;
    opacity: ${props => props.brandedOpacity};
    z-index: -1;
  }
`;

export default BgCategories;
