import appConfig from '../../config';
import adServer from '../../utils/constants/adServer';
import adsPosition from '../../utils/constants/adsPosition';

function generateCorrelator() {
  const currentDate = new Date().getTime();
  const randomNumber = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
  const uniqueId = `${randomNumber}${currentDate}`;
  return uniqueId;
}

const AdsMacros = ({ data = {}, location, position, identityProvider }) => {
  const { ADS_POSITION } = adsPosition();
  const { LOCATIONS } = adServer();
  const config = appConfig();
  const { baseURL, ...rest } = data;
  const { otteraSiteName, otteraSiteId, otteraSitePage, pmnd, pmxd, pmad } = config;
  const hostnameSplited = window.location.hostname.split('.')[0];
  const urlBase = `${baseURL}?`;
  const userData = {
    ua: encodeURIComponent(navigator.userAgent),
    player_width: window.screen.width,
    player_height: window.screen.height,
    content_url: encodeURIComponent(`${window.location.href}`),
    device_language: navigator.language,
    site_name: encodeURIComponent(otteraSiteName),
    site_id: otteraSiteId,
    site_page: otteraSitePage,
    custom_6: location?.from || LOCATIONS.EXTERNAL,
    custom_7: position || ADS_POSITION.PREROLL,
    custom_8: identityProvider ? `loggedin-${identityProvider}` : 'unregistered',
    custom_9:
      hostnameSplited === 'localhost' || hostnameSplited === 'dev' ? 'riivi' : hostnameSplited,
    custom_10: '[GOOGLE_INSTREAM_VIDEO_NONCE]',
    custom_11: generateCorrelator(),
    custom_12: pmnd,
    custom_13: pmxd,
    custom_14: pmad
  };

  const handlerConfigAds = () => {
    const buildMacros = (userData, macros) => {
      const combinedMacros = { ...userData, ...macros };
      const macroList = Object.entries(combinedMacros).map(
        ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
      return macroList;
    };
    const macros = buildMacros(userData, rest.macros);

    return {
      tag: {
        type: 'vast',
        url: urlBase.concat(macros.join('&'))
      },
      id: 'riiviAd'
    };
  };

  return handlerConfigAds();
};

export default AdsMacros;
