import config from '../config';

const network = client => ({
  userInfo: async () => client.get(config().user.profile),
  deleteProfile: async () => client.delete(config().user.profile),
  favourites: async payload =>
    client.post(config().user.favourite, {
      title_id: payload
    }),
  getFavourites: async () => client.get(config().user.favourite),
  storeResume: async ({ type, titleId, time }) =>
    client.post(`${config().user.resumeMedia}/${type}/${titleId}`, {
      resume_time: time
    }),
  storeResumeSeries: async ({ type, serieId, time, episodeId }) =>
    client.post(`${config().user.resumeMedia}/${type}/${serieId}`, {
      resume_time: time,
      episode_id: episodeId
    }),
  getResumeTime: async ({ type, titleID }) =>
    client.get(`${config().user.resumeMedia}/${type}/${titleID}`),
  removeWatching: async ({ type, mediaID }) =>
    client.delete(`${config().user.resumeMedia}/${type}/${mediaID}`),
  rating: async ({ action, titleID }) =>
    client.post(`${config().user.rating}/${action}`, {
      title_id: titleID
    }),
  fetchRatingMedia: async () => client.get(config().user.ratingMedia),
  tvCode: async ({ code }) => client.post(config().auth.tvCode, { user_code: code }),
  fetchUserTitles: async ({ type }) => client.get(`${config().user.userTitles}/${type}`),
  fetchResumeTitles: async payload => client.get(`${config().user.userTitles}/${payload}`),
  fetchEntitlements: async () => client.get(config().user.userEntitlements),
  postEntitlementKey: async ({ entitlementId }) =>
    client.post(`${config().user.userEntitlements}/${entitlementId}/authorize`),
  fetchEntitlementsMedia: async ({ mediaId }) => {
    const url = config().user.entitlementMedia.replace(':mediaId', mediaId);
    return client.get(url);
  },
  fetchMediaSign: async ({ mediaId, policyId }) => {
    const url = config().user.mediaSign.replace(':media_id', mediaId);
    return client.post(url, {
      policy_id: policyId
    });
  }
});

export default network;
