import React from 'react';
import { Slider } from '../';
import PropTypes from 'prop-types';
import adServer from '../../utils/constants/adServer';
import { Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const Tickets = ({ entitlements, isLoading }) => {
  const { LOCATIONS } = adServer();
  const history = useHistory();

  return (
    <>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      ) : entitlements.length > 0 ? (
        <Slider explore={true} collectionName={LOCATIONS.TICKETS}>
          {entitlements.map((title, index) => (
            <Slider.Item
              key={title.id}
              title={title}
              origin="explore"
              collectionIndex={index}
              titleIndex={index}
              ticket={true}
              collectionName={LOCATIONS.TICKETS}
            />
          ))}
        </Slider>
      ) : (
        <div className="tickets-empty">
          <h2>Adquiere tus entradas para ver los últimos estrenos, ahora en Riivi.</h2>
          <p>Todos los estrenos que adquieras se guardarán aquí.</p>
          <Button variant="gold-500" size="lg" onClick={() => history.push('/inicio')}>
            <i className={'fas fa-ticket-alt fa-md mr-2'} />
            <span className="ml-1">Ver el catálogo</span>
          </Button>
        </div>
      )}
    </>
  );
};

Tickets.propTypes = {
  entitlements: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Tickets;
