const facebookLogin = () => ({
  facebookKey: process.env.REACT_APP_FACEBOOK_LOGIN_KEY,
  facebookFields: 'name,email',
  facebookLoginUrl: '/auth/social/facebook',
  googleKey: process.env.REACT_APP_GOGLE_LOGIN_KEY,
  googleCookiePolicy: 'single_host_origin',
  googleResponseType: 'code,token',
  googleLoginUrl: '/auth/social/google',
  toolboxCP: process.env.REACT_APP_TOOLBOX_CP || 'riivi',
  toolboxSpUrl: process.env.REACT_APP_TOOLBOX_SP_URL || 'https://sp-cert.tbxnet.com',
  toolboxMso: '/toolbox/v2/auth/:cp/mso.json',
  toolboxAccessToken: '/auth/toolbox/:idp',
});

export default facebookLogin;
