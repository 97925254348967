import React, { useEffect, useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { Slider } from '../';
import TagManager from '../../utils/helpers/gtmDatalayer';
import { EmptyResults, InternalError } from '../../views';
import playerScript from '../../utils/helpers/playerScript';
import adServer from '../../utils/constants/adServer';
import { useIntersectionObserver } from '../../utils/hooks/useInterceptorObserver';

const Results = ({ hits, query, hasMore, refineNext }) => {
  const scriptsLoader = playerScript();
  const { LOCATIONS } = adServer();
  const { nodeRef } = useIntersectionObserver({
    onIntersect: useCallback(() => refineNext(), [refineNext]),
    hasMore
  });

  useEffect(() => {
    TagManager().searchFunnel();
  }, []);

  useEffect(() => {
    scriptsLoader.titleDocuments('Search');
    scriptsLoader.metaTag(
      {
        description: `Encuentra la película, serie o documental que estás buscando. 
      Tenemos las mejores películas, series y documentales chilenos y latinos. ¡Completamente gratis!`
      },
      true
    );
  }, [scriptsLoader]);

  const LoadingIndicator = connectStateResults(({ searching, error, searchResults }) => {
    if (error) {
      return <InternalError />;
    }
    if (searching || searchResults.index === undefined) {
      return (
        <div className="spinner-modal">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      );
    }
    if (hits.length < 1) {
      return <EmptyResults />;
    }
    return <></>;
  });

  return (
    <>
      <div className="row mb-4">
        <div className="col-10 col-sm-6 col-md-4">
          <h6 className="text-primary">Resultados para</h6>
          <h2>{query}</h2>
        </div>
      </div>
      {hits.length >= 1 ? (
        <Slider explore={true} collectionName={LOCATIONS.SEARCH}>
          {hits
            .map(title => ({ ...title, id: title.objectID }))
            .map((hit, index) => (
              <Slider.Item
                key={hit.objectID}
                title={hit}
                origin="search"
                searchQuery={query}
                collectionIndex={index}
                titleIndex={index}
                collectionName={LOCATIONS.SEARCH}
              />
            ))}
          <div ref={nodeRef} />
        </Slider>
      ) : (
        <></>
      )}
      <LoadingIndicator />
    </>
  );
};

Results.propTypes = {
  hits: PropTypes.array.isRequired,
  query: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired
};

export default connectInfiniteHits(Results);
