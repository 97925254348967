const useResumeTitles = (titles, resumeData) => {
  const mappedResumes = resumeData.reduce((map, item) => ({ ...map, [item.id]: item.resume }), {});

  const resumeTitles = titles.map(title => {
    const resume = mappedResumes[title.id];
    return resume ? { ...title, resume } : title;
  });
  return resumeTitles;
};

export default useResumeTitles;
