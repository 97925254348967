import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useImageProperties from '../../../utils/hooks/useImageProperties';
import getDurationOrSeason from '../../../utils/helpers/getDurationOrSeason';

const NormalItem = ({
  assets,
  title,
  fallbackImage,
  handleShow,
  tileHoverStatus,
  genre,
  playMedia,
  myListButton
}) => {
  const { source, altText, fallbackClass, handleClick, showFallbackText } = useImageProperties(
    assets,
    title,
    fallbackImage,
    isMobile,
    handleShow
  );

  return (
    <>
      <div className="normal-image-container">
        <img className={fallbackClass} src={source} alt={altText} onClick={handleClick} />
      </div>
      {showFallbackText && (
        <div className="fallbackContainer">
          <span className="fallbackText">{title.name}</span>
        </div>
      )}
      {tileHoverStatus && (
        <div className="content-item p-3">
          <Button variant="primary" size="sm" className="btn-play" onClick={playMedia(title)}>
            <i className="fas fa-play"></i>
          </Button>
          <div className="content">
            <div className="row mb-3">
              <div className="col-sm-10">
                <h5 className="text-dark font-weight-bold content-title mb-1">{title.name}</h5>
              </div>
              <div className="col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex align-items-center flex-wrap">
                  <p className="text-dark text-nowrap small mr-2 mb-0 font-weight-bold">
                    {getDurationOrSeason(title)}
                  </p>
                  <p className="text-dark small mr-2 mb-0">{genre ? genre.name : ''}</p>
                  <span className="badge badge-purple-pink badge-pill small mr-2">
                    {title.rated}
                  </span>
                  <p className="text-dark small mb-0">{title.release_year}</p>
                </div>
                {myListButton()}
              </div>
            </div>
            {title.resume ? (
              <div className="d-flex align-items-center mb-3">
                <ProgressBar
                  className="progress-bar-custom"
                  variant="danger"
                  now={title.resume.completed}
                />
                <p className="small text-dark mb-0 ml-2">Quedan {title.resume.remaining}</p>
              </div>
            ) : null}
            <Button
              className="btn-purple-400 btn-more-info text-primary btn-block"
              size="sm"
              onClick={handleShow}
            >
              Ver más
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

NormalItem.propTypes = {
  assets: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  fallbackImage: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  tileHoverStatus: PropTypes.bool.isRequired,
  genre: PropTypes.object.isRequired,
  playMedia: PropTypes.func.isRequired,
  myListButton: PropTypes.func.isRequired
};

export default NormalItem;
