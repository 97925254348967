import { takeLatest, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { facebookLogin, googleLogin, healthCheck, toolboxLogin } from '../../network';
import { SET_ISLOGEDIN } from '../user/types';
import { SET_TENANCY } from '../player/types';
import { SET_TOAST_NOTIFICATION } from '../notification/types';
import {
  SET_ERROR_FACEBOOK_LOGIN,
  POST_FACEBOOK_LOGIN,
  POST_GOOGLE_LOGIN,
  POST_TOOLBOX_LOGIN,
  SET_ERROR_GOOGLE_LOGIN,
  SET_ERROR_TOOLBOX_LOGIN
} from './types';
import { socialErrors } from '../../utils/saga_errors';
import successMsg from '../../utils/constants/success';

function* postFacebookLogin(action) {
  try {
    const response = yield call(facebookLogin, action.payload);
    const { login } = action.payload;
    login(response.data);
    yield put({ type: SET_ISLOGEDIN, payload: true });
    if (response.data.new_account) {
      const { newAccount } = successMsg();
      yield put({
        type: SET_TOAST_NOTIFICATION,
        payload: { title: newAccount.title, body: newAccount.body }
      });
    }
  } catch (e) {
    const { response } = e;

    if (response && response.status === 401) {
      if (response && response.data.error === 'VALIDATION_ERROR') {
        yield put({
          type: SET_ERROR_FACEBOOK_LOGIN,
          payload: socialErrors.FACEBOOK_VALIDATION_ERROR
        });
      }
      if (response && response.data.error === 'FACEBOOK_TOKEN_INVALID') {
        yield put({
          type: SET_ERROR_FACEBOOK_LOGIN,
          payload: socialErrors.FACEBOOK_TOKEN_INVALID
        });
      }
      if (response && response.data.error === 'FACEBOOK_TOKEN_EMPTY') {
        yield put({
          type: SET_ERROR_FACEBOOK_LOGIN,
          payload: socialErrors.FACEBOOK_TOKEN_EMPTY
        });
      }
      if (response && response.data.error === 'FACEBOOK_USER_NOT_FOUND') {
        yield put({
          type: SET_ERROR_FACEBOOK_LOGIN,
          payload: socialErrors.FACEBOOK_USER_NOT_FOUND
        });
      }
    } else {
      yield put({ type: SET_ERROR_FACEBOOK_LOGIN, payload: socialErrors.INTERNAL_ERROR });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* postGoogleLogin(action) {
  try {
    const response = yield call(googleLogin, action.payload);
    const { login } = action.payload;
    login(response.data);
    yield put({ type: SET_ISLOGEDIN, payload: true });
    if (response.data.new_account) {
      const { newAccount } = successMsg();
      yield put({
        type: SET_TOAST_NOTIFICATION,
        payload: { title: newAccount.title, body: newAccount.body }
      });
    }
  } catch (e) {
    const { response } = e;
    console.error(e);

    if (response && response.status === 401) {
      if (response && response.data.error === 'VALIDATION_ERROR') {
        yield put({
          type: SET_ERROR_GOOGLE_LOGIN,
          payload: socialErrors.GOOGLE_VALIDATION_ERROR
        });
      }
      if (response && response.data.error === 'GOOGLE_TOKEN_INVALID') {
        yield put({
          type: SET_ERROR_GOOGLE_LOGIN,
          payload: socialErrors.GOOGLE_TOKEN_INVALID
        });
      }
      if (response && response.data.error === 'GOOGLE_TOKEN_EMPTY') {
        yield put({
          type: SET_ERROR_GOOGLE_LOGIN,
          payload: socialErrors.GOOGLE_TOKEN_EMPTY
        });
      }
    } else {
      yield put({ type: SET_ERROR_GOOGLE_LOGIN, payload: socialErrors.INTERNAL_ERROR });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* postToolboxLogin(action) {
  try {
    const response = yield call(toolboxLogin, action.payload);
    const { login } = action.payload;
    login(response.data);
    const tenant = yield call(healthCheck);
    const { tenant_id } = tenant.data;
    if (tenant_id) {
      yield put({ type: SET_TENANCY, payload: tenant_id });
    }
    yield put({ type: SET_ISLOGEDIN, payload: true });
    if (response.data.new_account) {
      const { newAccount } = successMsg();
      yield put({
        type: SET_TOAST_NOTIFICATION,
        payload: { title: newAccount.title, body: newAccount.body }
      });
    }
  } catch(e) {
    const { response } = e;
    if (response && response.status === 401) {
      // WIP: catch errors
      if (typeof response.data.error === 'string') {
        yield put({
          type: SET_ERROR_TOOLBOX_LOGIN,
          payload: response.data.error
        });
      }
    } else {
      yield put({ type: SET_ERROR_TOOLBOX_LOGIN, payload: 'TOOLBOX_CANCEL' });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* watchFacebookLogin() {
  yield takeLatest(POST_FACEBOOK_LOGIN, postFacebookLogin);
}

function* watchGoogleLogin() {
  yield takeLatest(POST_GOOGLE_LOGIN, postGoogleLogin);
}

function* watchToolboxLogin() {
  yield takeLatest(POST_TOOLBOX_LOGIN, postToolboxLogin);
}

export default [watchFacebookLogin(), watchGoogleLogin(), watchToolboxLogin()];
