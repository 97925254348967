import {
  SET_LOGIN_SERVICE,
  RESET_LOGIN_SERVICE,
  SET_ERROR_LOGIN_MODAL,
  RESET_ERROR_LOGIN_MODAL,
  SET_ERROR_RESTORE_PASS,
  RESET_ERROR_RESTORE_PASS
} from './types';

const initialState = {
  logedIn: '',
  loginErrorCode: '',
  passwordErrorCode: ''
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_LOGIN_SERVICE:
    return { ...state, logedIn: payload };
  case RESET_LOGIN_SERVICE:
    return { ...state, logedIn: payload };
  case SET_ERROR_LOGIN_MODAL:
    return { ...state, loginErrorCode: payload };
  case SET_ERROR_RESTORE_PASS:
    return { ...state, passwordErrorCode: payload };
  case RESET_ERROR_LOGIN_MODAL:
    return { ...state, loginErrorCode: payload };
  case RESET_ERROR_RESTORE_PASS:
    return { ...state, passwordErrorCode: payload };
  default:
    return state;
  }
};
