import recaptchaConfig from './recaptcha';
import authConfig from './auth';
import userConfig from './user';
import socialConfig from './social';
import adSense from './ad_sense';
import playerConfig from './player';
import algoliaConfig from './algolia';
import tagManager from './tagManager';
import hotjar from './hotjar';
import amplitude from './amplitude';

export default () => ({
  appEnv: process.env.REACT_APP_ENV || 'local',
  proxyUrl: process.env.REACT_APP_PROXY_SERVER_URL,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  imagesCDN: process.env.REACT_APP_IMAGES_CDN,
  policyId: process.env.REACT_APP_POLICY_ID,
  chatServiceUrl: process.env.REACT_APP_TAWKTO_SERVICE_URL,
  timeout: 10000,
  recaptcha: recaptchaConfig(),
  auth: authConfig(),
  user: userConfig(),
  socialLogin: socialConfig(),
  adSense: adSense(),
  player: playerConfig(),
  algolia: algoliaConfig(),
  tagManager: tagManager(),
  hotjar: hotjar(),
  amplitude: amplitude(),
  otteraSiteId: process.env.REACT_APP_OTTERA_SITE_ID,
  otteraSitePage: process.env.REACT_APP_OTTERA_SITE_PAGE,
  otteraSiteName: process.env.REACT_APP_OTTERA_SITE_NAME,
  otteraUrlBase: process.env.REACT_APP_OTTERA_URL_BASE,
  brightcoveAccountId: process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID,
  pmnd: process.env.REACT_APP_PMND,
  pmxd: process.env.REACT_APP_PMXD,
  pmad: process.env.REACT_APP_PMAD,
  midrollInterval: process.env.REACT_APP_MIDROLL_INTERVAL
});
