const setupPlayer = () => {
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.polyfill.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['engine-bitmovin'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['engine-native'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.mserenderer.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.abr.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.drm.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['container-mp4'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['container-ts'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.xml.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.dash.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.hls.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.crypto.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.style.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['advertising-core'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['advertising-bitmovin'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['advertising-ima'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.thumbnail.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.ui.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player.subtitles.default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['subtitles-cea608'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['subtitles-native'].default);
  window.bitmovin.player.core.Player.addModule(window.bitmovin.player['subtitles-vtt'].default);
};

export default setupPlayer;
