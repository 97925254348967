import config from '../config';

const network = client => ({
  registerClient: async payload =>
    client.post(config().auth.registerUrl, {
      first_name: payload.name,
      last_name: '',
      email: payload.email,
      password: payload.password,
      host: config().auth.host
    })
});

export default network;
