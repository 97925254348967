import React from 'react';
import PropTypes from 'prop-types';

const SliderWrapper = ({ children, explore }) => (
  <div className={`slider-wrapper ${explore ? 'slider-explore' : ''}`}>{children}</div>
);

SliderWrapper.propTypes = {
  children: PropTypes.array.isRequired,
  explore: PropTypes.bool
};

export default SliderWrapper;
