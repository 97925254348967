import config from '../config';

const network = client => ({
  emailConfirm: async ({ token }) =>
    client.post(config().auth.emailConfirmUrl, {
      token,
      host: config().auth.host
    }),
  reSendEmailConfirm: async ({ email }) =>
    client.post(config().auth.reSendEmailConfirm, {
      email,
      host: config().auth.host
    })
});

export default network;
