const handlePaymentUrl = (titleId, monetization, isLogin, history) => {
  const mediaId = monetization.offer.media_id;
  const mediaType = monetization.offer.type.toLowerCase();

  const typeToPlural = type => {
    const pluralMapping = {
      movie: 'movies',
      episode: 'episodes',
      season: 'seasons'
    };

    return pluralMapping[type] || type;
  };

  const url = `/payments/titles/${titleId}/${typeToPlural(mediaType)}/${mediaId}/checkout`;

  if (url) {
    if (isLogin) {
      window.location.href = url;
    } else {
      history.push('/login', {
        purchase: {
          titleId: titleId,
          mediaId: mediaId,
          mediaType: mediaType
        }
      });
    }
  }
};

export default handlePaymentUrl;
