import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as Sentry from '@sentry/react';
import config from '../../config';
import authHelper from '../../utils/helpers/auth';
import ErrorMessage from '../../utils/google_errors';
import { POST_GOOGLE_LOGIN, SET_ERROR_GOOGLE_LOGIN } from '../../store/social/types';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';
import TagManager from '../../utils/helpers/gtmDatalayer';

const { socialLogin } = config();

const GoogleLogin = props => {
  const {
    googleLoginError,
    googleLogin,
    setGoogleLoginError,
    title,
    source,
    isLoged,
    dispatchToast
  } = props;
  const [loadLogin, setLoadLogin] = useState(false);

  const createFakeGoogleWrapper = useRef(null);
  const googleButtonWrapper = useRef(null);

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: socialLogin.googleKey,
      ux_mode: 'popup',
      callback: response => {
        try {
          login(response);
          googleEvent();
        } catch {
          handleLoginFailure(response);
        }
      }
    });

    createFakeGoogleWrapper.current = () => {
      const googleLoginWrapper = document.createElement('div');
      googleLoginWrapper.style.display = 'none';
      document.body.appendChild(googleLoginWrapper);
      window.google.accounts.id.renderButton(googleLoginWrapper, {});
      const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');
      return {
        click: () => {
          googleLoginWrapperButton.click();
        }
      };
    };

    googleButtonWrapper.current = createFakeGoogleWrapper.current();
  });

  useEffect(() => {
    if (isLoged) {
      setLoadLogin(false);
    }
  }, [isLoged]);

  const login = response => {
    googleLogin({ tokenId: response.credential, login: authHelper().login });
    setLoadLogin(true);
    if (response.new_account) {
      dispatchToast({
        title: '¡Listo!',
        body: '¡Te has registrado exitosamente!',
        type: 'success',
        delay: 15000
      });
    }
  };

  const handleLoginFailure = response => {
    Sentry.captureException(response, Sentry.Severity.Fatal);
    setGoogleLoginError(ErrorMessage(response.error));
  };

  const googleEvent = () => {
    if (source === 'login') {
      TagManager().loginGoogle();
    }
    if (source === 'register') {
      TagManager().signupGoogle();
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    googleButtonWrapper.current.click();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <button type="submit" className="my-google-button">
          {loadLogin ? (
            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
          ) : (
            <>
              <i className="fab fa-google text-primary mr-2"></i> {title}
            </>
          )}
        </button>
        <p className="text-danger small mt-1">{googleLoginError}</p>
      </div>
    </form>
  );
};

GoogleLogin.propTypes = {
  googleLoginError: PropTypes.string.isRequired,
  googleLogin: PropTypes.func.isRequired,
  setGoogleLoginError: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  isLoged: PropTypes.bool.isRequired,
  dispatchToast: PropTypes.func.isRequired
};

const stateToProps = state => ({
  googleLoginError: state.socialLogin.googleErrorLogin,
  isLoged: state.user.isLogedIn
});

const actionsToProps = dispatch => ({
  googleLogin: payload => dispatch({ type: POST_GOOGLE_LOGIN, payload }),
  setGoogleLoginError: payload => dispatch({ type: SET_ERROR_GOOGLE_LOGIN, payload }),
  dispatchToast: payload => dispatch({ type: SET_TOAST_NOTIFICATION, payload })
});

export default connect(stateToProps, actionsToProps)(GoogleLogin);
