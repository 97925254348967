import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Results } from '../../components';

const ResultsView = props => (
  <Container className="container-pt-custom" fluid>
    <Results query={props.query} />
  </Container>
);

ResultsView.propTypes = {
  query: PropTypes.string.isRequired
};

export default ResultsView;
