import { Cookies } from 'react-cookie';
import moment from 'moment';

const cookies = new Cookies();

export default () => ({
  setToken: payload => {
    const expires = moment().add(1, 'y').toDate();
    cookies.set('accessToken', payload.access_token, { path: '/', sameSite: true, expires });
    cookies.set('refreshToken', payload.refresh_token, { path: '/', sameSite: true, expires });
  },
  getAccessToken: () => cookies.get('accessToken'),
  getRefreshToken: () => cookies.get('refreshToken'),
  removeToken: () => {
    cookies.remove('accessToken', { path: '/' });
    cookies.remove('refreshToken', { path: '/' });
  }
});
