import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Button, Badge } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  FETCH_FEATURED_TITLE,
  SET_SHOW_MODAL,
  SET_MODAL_ID,
  SET_CURRENT_MODAL_STATE
} from '../../store/player/types';
import { POST_FAVOURITES, POST_RATING } from '../../store/user/types';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';
import formatUrl from '../../utils/helpers/formatUrl';
import TagManager from '../../utils/helpers/gtmDatalayer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import adServer from '../../utils/constants/adServer';
import addQueryParams from '../../utils/helpers/addQueryParams';
import useResumeTitles from '../../utils/hooks/useResumeTitles';
import { isMobile } from 'react-device-detect';

const FeaturedTile = ({
  getFeaturedMedia,
  featuredMedia,
  featuredMediaDetails,
  isLogin,
  postFavourites,
  loadingFav,
  myList,
  setShowModal,
  setModalId,
  setCurrentModalState,
  continueWatching,
  tenancy
}) => {
  const history = useHistory();
  const [seasonsCount, setSeasonsCount] = useState('');
  const { LOCATIONS } = adServer();
  const featuredResumeTitles = useResumeTitles([featuredMediaDetails], continueWatching);

  useEffect(() => {
    if (tenancy) {
      getFeaturedMedia();
    }
  }, [tenancy, getFeaturedMedia]);

  useEffect(() => {
    const season = featuredMedia.series;
    if (season) {
      setSeasonsCount(season.seasons_count);
    }
  }, [featuredMedia]);

  const playMedia = () => {
    TagManager().playFeaturedHero({
      genres: setGenresArray(featuredMedia.genres),
      titleName: featuredMedia.name
    });

    const formatter = formatUrl();
    const formatName = formatter.getCleanedString(featuredMedia.name);
    const formatType = formatter.getCleanedString(featuredMedia.type);
    const type = formatter.translateType(formatType);
    const [resumeTitle] = featuredResumeTitles;
    if (resumeTitle.type === 'SERIES') {
      let episodeId;
      episodeId = resumeTitle.resume
        ? resumeTitle.resume.media_id
        : resumeTitle.series.first_episode;

      history.push(`/${type}/${formatName}/${episodeId}`, { from: LOCATIONS.FEATUREDTITLE });
    } else {
      history.push(`/${type}/${formatName}/${featuredMedia.movie.id}`, {
        from: LOCATIONS.FEATUREDTITLE
      });
    }
  };

  const handleShow = () => {
    TagManager().moreInfoFeaturedHero({
      genres: setGenresArray(featuredMedia.genres),
      titleName: featuredMedia.name
    });
    setShowModal(true);
    setModalId(featuredMedia.id);
    addQueryParams({
      id: featuredMedia.id,
      type:
        featuredMedia.type.toLowerCase() === 'other' ? 'movie' : featuredMedia.type.toLowerCase(),
      history
    });
    setCurrentModalState(true);
  };

  const setGenresArray = gen => {
    if (gen) {
      return gen.map(act => act.name).join(', ');
    }
    return '';
  };

  const returnDuration = movie => {
    if (movie) {
      return movie.duration;
    }
  };

  const myFavButton = () => {
    if (isLogin) {
      return (
        <button
          onClick={favouriteCall}
          className={`btn btn-lg fav-button fav-button-featured ${
            myList.some(movie => movie.title_id === featuredMedia.id) ? 'active' : ''
          }`}
          type="button"
          disabled={loadingFav}
        >
          <i className="far fa-heart"></i>
        </button>
      );
    }
  };

  const favouriteCall = () => {
    if (featuredMedia.id) {
      postFavourites(featuredMedia.id);
    }
  };

  const durationOrSeason = () => {
    if (featuredMedia.type === 'MOVIE' || featuredMedia.type === 'OTHER') {
      return returnDuration(featuredMedia.movie);
    } else {
      return `${seasonsCount} Temporadas`;
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(${featuredMedia.backdrop_url})` }}
      className="featured-tile"
    >
      <div className="container-fluid container-featured-tile">
        <div className="row">
          <div className="col-sm-10 col-xl-6 col-md-8 col-lg-6">
            {featuredMedia.name ? (
              <h1 className="font-weight-bold display-4 mb-4 featured-tile-title">
                {featuredMedia.name}
              </h1>
            ) : (
              <SkeletonTheme color="#eee" highlightColor="#fff">
                <Skeleton height={40} className="mb-4" />
              </SkeletonTheme>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-md-8 col-sm-8 col-lg-6">
            <div className="mb-3">
              {featuredMedia.movie || featuredMedia.series ? (
                <Row className="mb-3">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <h6 className="mb-0 mr-3">{durationOrSeason()}</h6>{' '}
                    <h6 className="mb-0 mr-3">{setGenresArray(featuredMedia.genres)}</h6>
                    <Badge pill variant="primary" className="mr-3">
                      {featuredMedia.rated}
                    </Badge>
                    <h6 className="mb-0">{featuredMedia.release_year}</h6>
                  </div>
                </Row>
              ) : (
                <SkeletonTheme color="#eee" highlightColor="#fff">
                  <div className="row">
                    <div className="col-lg-8">
                      <Skeleton />
                    </div>
                  </div>
                </SkeletonTheme>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-5 col-md-8 col-sm-8 col-lg-6">
            {featuredMedia.description ? (
              <p className="text-shadow featured-tile-description">{featuredMedia.description}</p>
            ) : (
              <SkeletonTheme color="#eee" highlightColor="#fff">
                <p>
                  <Skeleton count={3} />
                </p>
              </SkeletonTheme>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-5">
            {featuredMedia.id ? (
              <>
                <Button
                  variant="primary"
                  size="lg"
                  className="btn-featured-tile mr-2"
                  onClick={() => playMedia()}
                >
                  <i className="fas fa-play mr-2"></i> Reproducir
                </Button>
                <Button
                  variant="light"
                  className="btn-blur btn-featured-tile mr-2"
                  size="lg"
                  onClick={handleShow}
                >
                  {isMobile ? 'Más info' : 'Más información'}
                </Button>
                {myFavButton()}
              </>
            ) : (
              <SkeletonTheme color="#eee" highlightColor="#fff">
                <>
                  <Skeleton width={160} height={50} className="mx-1 mr-2" />
                  <Skeleton width={160} height={50} className="mx-1" />
                </>
              </SkeletonTheme>
            )}
          </div>
        </div>
      </div>
      <div className="gradient-tile"></div>
    </div>
  );
};

FeaturedTile.propTypes = {
  getFeaturedMedia: PropTypes.func.isRequired,
  featuredMedia: PropTypes.object.isRequired,
  featuredMediaDetails: PropTypes.object.isRequired,
  isLogin: PropTypes.bool.isRequired,
  postFavourites: PropTypes.func.isRequired,
  loadingFav: PropTypes.bool.isRequired,
  myList: PropTypes.array.isRequired,
  myEpisodes: PropTypes.array.isRequired,
  dispatchToast: PropTypes.func.isRequired,
  myRatingList: PropTypes.array.isRequired,
  postRating: PropTypes.func.isRequired,
  loadingRating: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setModalId: PropTypes.func.isRequired,
  setCurrentModalState: PropTypes.func.isRequired,
  continueWatching: PropTypes.array.isRequired,
  tenancy: PropTypes.string
};

const stateToProps = ({ player, user }) => ({
  featuredMedia: player.featuredMedia,
  featuredMediaDetails: player.featuredMediaDetailedInfo,
  isLogin: user.isLogedIn,
  loadingFav: user.loading,
  myList: user.favouriteList,
  myEpisodes: player.episodes,
  myRatingList: user.ratingList,
  loadingRating: user.loadingRating,
  showModal: player.showModal,
  continueWatching: user.continueWatching,
  tenancy: player.tenancy
});

const actionToProps = dispatch => ({
  getFeaturedMedia: payload => dispatch({ type: FETCH_FEATURED_TITLE, payload }),
  postFavourites: payload => dispatch({ type: POST_FAVOURITES, payload }),
  dispatchToast: payload => dispatch({ type: SET_TOAST_NOTIFICATION, payload }),
  postRating: payload => dispatch({ type: POST_RATING, payload }),
  setShowModal: payload => dispatch({ type: SET_SHOW_MODAL, payload }),
  setModalId: payload => dispatch({ type: SET_MODAL_ID, payload }),
  setCurrentModalState: payload => dispatch({ type: SET_CURRENT_MODAL_STATE, payload })
});

export default connect(stateToProps, actionToProps)(FeaturedTile);
