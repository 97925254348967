import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import DetailedSheet from './detailedSheet';
import PropTypes from 'prop-types';
import TagManager from '../../utils/helpers/gtmDatalayer';
import {
  FETCH_EPISODES,
  SET_MODAL_ID,
  GET_MODAL_DATA,
  SET_MODAL_DATA,
  SET_CURRENT_MODAL_STATE,
  SET_COLLECTION_NAME_MODAL,
  RESET_EPISODES
} from '../../store/player/types';
import {
  POST_FAVOURITES,
  REMOVE_WATCHING,
  POST_RATING,
  FETCH_CONTINUE_WATCHING,
  FETCH_RESUME_DATA,
  SET_RESUME_DATA
} from '../../store/user/types';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';
import { SET_IS_SEARCHING } from '../../store/search/types';
import formatUrl from '../../utils/helpers/formatUrl';
import addQueryParams from '../../utils/helpers/addQueryParams';
import PlayerModal from './playerModal';
import useUpdateEpisodes from '../../utils/hooks/useUpdateEpisodes';
import playerTypes from '../../utils/constants/playerTypes';
import playerScript from '../../utils/helpers/playerScript';

const ModalItem = ({
  getModalData,
  postFavourites,
  removeWatching,
  fetchContinueWatching,
  fetchEpisodes,
  dispatchToast,
  resetSearching,
  postRating,
  cleanModalData,
  modalData,
  episodes,
  isLogedIn,
  loading,
  favouriteList,
  isRemoved,
  ratingList,
  loadingRating,
  setShowModal,
  showModal,
  setModalId,
  modalId,
  setCurrentModalState,
  getResumeData,
  resumeData,
  setResumeData,
  resumeTimeSended,
  collectionNameModal,
  setCollectionNameModal,
  resetEpisodes,
  episodesHasMore
}) => {
  const history = useHistory();
  const [showTrailer, setShowTrailer] = useState(true);
  const [currentModalData, setCurrentModalData] = useState({});
  const [seasonPurchased, setSeasonPurchased] = useState(false);
  const updatedEpisodes = useUpdateEpisodes(episodes, resumeData);
  const { PLAYER_TYPES } = playerTypes();
  const [nextPage, setNextPage] = useState(1);

  useEffect(() => {
    TagManager().titleDetailFunnel();
  }, []);

  useEffect(() => {
    if (modalId && Object.keys(modalData).length < 1) {
      getModalData(modalId);
    }
  }, [modalId, getModalData, modalData]);

  useEffect(() => {
    if ((modalId && isLogedIn) || resumeTimeSended) {
      getResumeData(modalId);
    }
  }, [modalId, isLogedIn, getResumeData, resumeTimeSended]);

  useEffect(() => {
    if (modalData.type === 'SERIES') {
      fetchEpisodes({ id: modalData.series?.seasons[0].id, page: nextPage });
    }
  }, [modalData, fetchEpisodes, nextPage]);

  useEffect(() => {
    if (Object.keys(modalData).length > 0) {
      if (isLogedIn && Object.keys(resumeData).length > 0) {
        setCurrentModalData({
          ...modalData,
          resume: resumeData.resume,
          entitlement: resumeData.entitlement
        });
      } else if (!isLogedIn) {
        setCurrentModalData(modalData);
      }
    }
  }, [resumeData, modalData, setCurrentModalData, isLogedIn]);

  useEffect(() => {
    if (isRemoved) {
      fetchContinueWatching();
      getResumeData(modalId);
    }
  }, [isRemoved, fetchContinueWatching, getResumeData, modalId]);

  useEffect(() => {
    if (isRemoved) {
      dispatchToast({
        title: '¡Listo!',
        body: 'Se ha quitado el título correctamente',
        type: 'success',
        delay: 5000
      });
    }
  }, [dispatchToast, isRemoved]);

  useEffect(() => {
    const seasonPurchased =
      updatedEpisodes.length > 0 &&
      updatedEpisodes.every(
        episode =>
          'entitlement' in episode &&
          episode.entitlement !== null &&
          typeof episode.entitlement === 'object' &&
          Object.keys(episode.entitlement).length > 0
      );
    setSeasonPurchased(seasonPurchased);
  }, [updatedEpisodes]);

  useEffect(() => {
    if (Object.keys(modalData).length > 0) {
      const formatter = formatUrl();
      const formatName = formatter.getCleanedString(modalData.name);
      const formatType = formatter.getCleanedString(modalData.type);
      const type = formatter.translateType(formatType);

      playerScript().scriptTag(
        {
          name: modalData.name,
          url: `${type}/${formatName}`,
          description: modalData.description,
          genre: setGenresArray(modalData.genres),
          dateCreated: modalData.release_year,
          startDate: modalData.release_year,
          location: modalData.origin_country.name,
          cast: modalData.credits,
          director: modalData.director
        },
        true
      );
    }
  }, [modalData]);

  const assets = useMemo(
    () =>
      currentModalData.assets?.reduce((acc, cur) => ({ ...acc, [cur.type]: cur.url }), {}) ?? {},
    [currentModalData]
  );

  const setGenresArray = gen => {
    if (gen) {
      return gen.map(act => act.name).join(', ');
    }
  };

  const infoTagManager = {
    genre: setGenresArray(modalData.genres),
    collectionName: collectionNameModal,
    titleName: modalData.name
  };

  const playTrailer = () => {
    history.push(`/videos/${modalData.trailer.id}`, { from: collectionNameModal });
    setShowModal(false);
  };

  const playMedia = serieId => {
    resetSearching();
    TagManager().playTitleDetail(infoTagManager);

    const formatter = formatUrl();
    const formatName = formatter.getCleanedString(currentModalData.name);
    const formatType = formatter.getCleanedString(currentModalData.type);
    const type = formatter.translateType(formatType);

    if (currentModalData.type === 'SERIES') {
      let episodeId;
      if (serieId !== undefined) {
        episodeId = serieId;
      } else if (currentModalData.resume) {
        episodeId = currentModalData.resume.episode_id;
      } else {
        if (
          currentModalData.monetization?.type === PLAYER_TYPES.TVOD &&
          currentModalData.entitlement
        ) {
          episodeId = currentModalData.entitlement.media.id;
        } else {
          episodeId = currentModalData.series.first_episode;
        }
      }
      if (episodeId) {
        history.push(`/${type}/${formatName}/${episodeId}`, { from: collectionNameModal });
      }
    } else {
      history.push(`/${type}/${formatName}/${currentModalData.movie.id}`, {
        from: collectionNameModal
      });
    }
    setShowModal(false);
  };

  const removeTitle = () => {
    const mediaType = currentModalData.type.toLowerCase();
    if (mediaType === 'other' || mediaType === 'movie') {
      const movieID = currentModalData.movie.id;
      removeWatching({ type: 'movie', mediaID: movieID });
    } else if (mediaType === 'series') {
      const serieID = currentModalData.series.id;
      removeWatching({ type: mediaType, mediaID: serieID });
    }
  };

  const ratingCall = action => {
    if (modalId) {
      postRating({ titleID: modalId, action: action });
    }
  };

  const favouriteCall = () => {
    if (modalId) {
      TagManager().addToMyListDetail(infoTagManager);
      postFavourites(modalId);
    }
  };

  const handleModalPlayerDiv = () => {
    const divPlayer = document.getElementById('player-modal');
    if (!divPlayer) {
      const playerDiv = document.createElement('div');
      playerDiv.id = 'player-modal';
      document.body.appendChild(playerDiv);
    }
  };

  const handleClose = () => {
    TagManager().closeTitleDetail();
    setShowModal(false);
    cleanModalData();
    addQueryParams({ history });
    setModalId(null);
    setCurrentModalState(false);
    setShowTrailer(true);
    setResumeData({});
    setCurrentModalData({});
    setCollectionNameModal('');
    resetEpisodes();
    setTimeout(() => {
      handleModalPlayerDiv();
    }, 1000);
  };

  const handlePlayerModal = () => {
    return (
      Object.keys(currentModalData).length > 0 &&
      !currentModalData.resume &&
      showTrailer &&
      currentModalData.trailer && (
        <div id="player-modal-container" className="gradient-trailer">
          <PlayerModal setShowTrailer={setShowTrailer} modalData={currentModalData} />
        </div>
      )
    );
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-details"
    >
      <Modal.Header
        style={{
          backgroundImage: `url(${`${assets.backdrop}`})`
        }}
        className={`${
          currentModalData.monetization?.type === PLAYER_TYPES.TVOD ? 'bg-black' : 'bg-purple'
        } modal-header-movie`}
        closeButton
      >
        {handlePlayerModal()}
        <div
          className={`${
            currentModalData.monetization?.type === PLAYER_TYPES.TVOD
              ? 'gradient-tile gradient-black'
              : 'gradient-tile'
          }`}
        ></div>
      </Modal.Header>
      <Modal.Body
        className={`${
          currentModalData.monetization?.type === PLAYER_TYPES.TVOD ? 'bg-black' : 'bg-purple-900'
        }`}
      >
        <DetailedSheet
          modalId={modalId}
          modalData={currentModalData}
          isLogin={isLogedIn}
          loadingFav={loading}
          myList={favouriteList}
          fetchEpisodes={fetchEpisodes}
          myEpisodes={updatedEpisodes}
          myRatingList={ratingList}
          loadingRating={loadingRating}
          favouriteCall={favouriteCall}
          ratingCall={ratingCall}
          playMedia={playMedia}
          playTrailer={playTrailer}
          removeTitle={removeTitle}
          setGenresArray={setGenresArray}
          setShowModal={setShowModal}
          seasonPurchased={seasonPurchased}
          setNextPage={setNextPage}
          episodesHasMore={episodesHasMore}
          resetEpisodes={resetEpisodes}
        />
      </Modal.Body>
    </Modal>
  );
};

ModalItem.propTypes = {
  getModalData: PropTypes.func.isRequired,
  postFavourites: PropTypes.func.isRequired,
  removeWatching: PropTypes.func.isRequired,
  fetchContinueWatching: PropTypes.func.isRequired,
  fetchEpisodes: PropTypes.func.isRequired,
  dispatchToast: PropTypes.func.isRequired,
  resetSearching: PropTypes.func.isRequired,
  postRating: PropTypes.func.isRequired,
  cleanModalData: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
  episodes: PropTypes.array.isRequired,
  isLogedIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  favouriteList: PropTypes.array.isRequired,
  isRemoved: PropTypes.bool.isRequired,
  ratingList: PropTypes.array.isRequired,
  loadingRating: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setModalId: PropTypes.func.isRequired,
  modalId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  setCurrentModalState: PropTypes.func.isRequired,
  getResumeData: PropTypes.func.isRequired,
  resumeData: PropTypes.object.isRequired,
  setResumeData: PropTypes.func.isRequired,
  resumeTimeSended: PropTypes.bool.isRequired,
  collectionNameModal: PropTypes.string.isRequired,
  setCollectionNameModal: PropTypes.func.isRequired,
  resetEpisodes: PropTypes.func.isRequired,
  episodesHasMore: PropTypes.bool.isRequired
};

const stateToProps = ({ user, player }) => ({
  modalData: player.modalData,
  episodes: player.episodes,
  isLogedIn: user.isLogedIn,
  loading: user.loading,
  favouriteList: user.favouriteList,
  isRemoved: user.isRemoved,
  ratingList: user.ratingList,
  loadingRating: user.loadingRating,
  modalId: player.modalId,
  resumeData: user.resumeData,
  resumeTimeSended: player.resumeTimeSended,
  collectionNameModal: player.collectionNameModal,
  episodesHasMore: player.episodesHasMore
});

const actionToProps = dispatch => ({
  getModalData: payload => dispatch({ type: GET_MODAL_DATA, payload }),
  postFavourites: payload => dispatch({ type: POST_FAVOURITES, payload }),
  removeWatching: payload => dispatch({ type: REMOVE_WATCHING, payload }),
  fetchContinueWatching: payload => dispatch({ type: FETCH_CONTINUE_WATCHING, payload }),
  fetchEpisodes: payload => dispatch({ type: FETCH_EPISODES, payload }),
  resetEpisodes: payload => dispatch({ type: RESET_EPISODES, payload }),
  dispatchToast: payload => dispatch({ type: SET_TOAST_NOTIFICATION, payload }),
  resetSearching: () => dispatch({ type: SET_IS_SEARCHING, payload: false }),
  postRating: payload => dispatch({ type: POST_RATING, payload }),
  cleanModalData: () => dispatch({ type: SET_MODAL_DATA, payload: {} }),
  setModalId: payload => dispatch({ type: SET_MODAL_ID, payload }),
  setCurrentModalState: payload => dispatch({ type: SET_CURRENT_MODAL_STATE, payload }),
  getResumeData: payload => dispatch({ type: FETCH_RESUME_DATA, payload }),
  setResumeData: payload => dispatch({ type: SET_RESUME_DATA, payload }),
  setCollectionNameModal: payload => dispatch({ type: SET_COLLECTION_NAME_MODAL, payload })
});

export default connect(stateToProps, actionToProps)(ModalItem);
