import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const ScrollContext = createContext();

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('Error en ScrollContext');
  }
  return context;
};

export const ScrollProvider = ({ children }) => {
  const scrollRef = useRef(null);
  const [changeLocation, setChangeLocation] = useState(false);

  const scrollToElement = history => {
    if (history.location.pathname !== '/inicio') {
      history.push('/inicio');
      setChangeLocation(true);
    } else if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (changeLocation && scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        setChangeLocation(false);
      }
    }, 500);
  }, [changeLocation]);

  return (
    <ScrollContext.Provider value={{ scrollRef, scrollToElement }}>
      {children}
    </ScrollContext.Provider>
  );
};

ScrollProvider.propTypes = {
  children: PropTypes.element.isRequired
};
