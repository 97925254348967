import React from 'react';
import { emptyResults } from '../../images';


const EmptyResult = () => {
  return (
    <div className="container-fluid container-pt-custom">
      <div className="row mb-4">
        <div className="col-10 col-sm-6 col-md-4 mx-auto text-center">
          <img src={emptyResults} className="d-block img-fluid mx-auto" alt="Empty Results" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8 mx-auto text-center">
          <h6 className="font-weight-bold mb-1">No hemos encontrado resultados para tu búsqueda</h6>
        </div>
      </div>
    </div>
  );
};

export default EmptyResult;
