import React from 'react';
import SmartTvIcon from '../../images/smart-tv-icon.svg';
import LaptopIcon from '../../images/laptop-icon.svg';
import PhoneTabletIcon from '../../images/phone-tablet-icon.svg';
import SeparatorIcon from '../../images/riivi-separator.svg';
import appConfig from '../../config';

const LandingPlatforms = () => {
  const { imagesCDN } = appConfig();

  const imageProvider = src => {
    return imagesCDN ? `${imagesCDN}/${src}` : src;
  };

  return (
    <div className="py-5 mb-5">
      <div className="row">
        <div className="col-sm-12 text-center mb-5">
          <h2 className="display-5">Disponible en tus dispositivos favoritos</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 text-center">
          <div className="col-10 col-sm-8 mx-auto mb-5">
            <img src={imageProvider(SmartTvIcon)} alt="" className="mb-3" />
            <h5>Smart TV</h5>
            <img src={imageProvider(SeparatorIcon)} alt="" className="mb-3" />
            <p className="text-center">
              Samsung<br />
              LG<br />
              Android TV<br />
              Chromecast<br />
            </p>
          </div>
        </div>

        <div className="col-sm-4 text-center">
          <div className="col-10 col-sm-8 mx-auto mb-5">
            <img src={imageProvider(LaptopIcon)} alt="" className="mb-3" />
            <h5>Computadoras</h5>
            <img src={imageProvider(SeparatorIcon)} alt="" className="mb-3" />
            <p className="text-center">
              ChromeOS<br />
              MacOS<br />
              Windows PC<br />
            </p>
          </div>
        </div>

        <div className="col-sm-4 text-center">
          <div className="col-10 col-sm-8 mx-auto mb-5">
            <img src={imageProvider(PhoneTabletIcon)} alt="" className="mb-3" />
            <h5>Smartphone y Tablet</h5>
            <img src={imageProvider(SeparatorIcon)} alt="" className="mb-3" />
            <p className="text-center">
              Android Phone & Tablet<br />
              iPhone & iPad<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPlatforms;
