import config from '../config';

const withHeaders = (client, headers) => {
  return {
    get: async (url, config = {}) =>
      client.get(url, {
        ...config,
        headers: {
          ...config.headers,
          ...headers
        }
      })
  };
};

const network = client => {
  const clientWithHeaders = withHeaders(client, { 'accept-version': 'v2' });
  return {
    fetchCollections: async () => clientWithHeaders.get(config().player.collectionsUrl),
    fetchCollectionTitles: async payload =>
      clientWithHeaders.get(`${config().player.collectionsUrl}/${payload}`, {
        params: { page: 1, per_page: 25 }
      }),
    movieDetails: async payload =>
      clientWithHeaders.get(`${config().player.playerMovieDetails}/${payload}`),
    fetchPlaylist: async payload => client.get(payload),
    fetchVideoData: async ({ type, id }) =>
      clientWithHeaders.get(`${config().player.videoData}/${type}/${id}`),
    featuredMedia: async () => clientWithHeaders.get(`${config().player.playerFeaturedMedia}`),
    exploreMovies: async payload =>
      clientWithHeaders.get(`${config().player.collectionsUrl}/${payload.id}`, {
        params: { page: payload.page, per_page: 25 }
      }),
    exploreGenre: async payload =>
      clientWithHeaders.get(`${config().player.exploreGenre}/${payload.id}`, {
        params: { page: payload.page, per_page: 25 }
      }),
    browseCollections: async () => client.get(config().player.browse),
    fetchEpisodes: async payload =>
      clientWithHeaders.get(`${config().player.playerSerieDetails}/${payload.id}`, {
        params: { page: payload.page, per_page: 25 }
      }),
    suggestTitle: async payload =>
      client.get(`${config().player.suggestTitle}/${payload.type}/${payload.mediaId}`),
    healthCheck: async () => client.get(config().player.healthCheck),
    fetchTrailerSource: async ({ id }) =>
      clientWithHeaders.get(`${config().player.trailerSource}/${id}`)
  };
};

export default network;
