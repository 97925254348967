import TagManager from 'react-gtm-module';

export default () => ({
  registerNavbar: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'to_signup_navbar'
      }
    });
  },
  loginNavbar: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'to_login_navbar'
      }
    });
  },
  playFeaturedHero: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'discovery',
        'label': 'play_featured',
        'step': '3.a',
        'genre': item.genres,
        'collection': 'Destacados',
        'collection_position': 0,
        'title': item.titleName
      }
    });
  },
  moreInfoFeaturedHero: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'discovery',
        'label': 'more_info_featured',
        'step': '3.b',
        'genre': item.genres,
        'collection': 'Destacados',
        'collection_position': 0,
        'title': item.titleName
      }
    });
  },
  exploreCollectionCarrousel: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'discovery',
        'label': 'explore_collection_carrousel',
        'step': '3.e',
        'collection': item.collectionName,
        'collection_position': item.position
      }
    });
  },
  slideCollectionCarrousel: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'discovery',
        'label': 'slide_collection_carrousel',
        'step': '3.d',
        'collection': item.collection,
        'collection_position': item.position
      }
    });
  },
  playTitleBriefDiscovery: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-discovery',
        'label': 'play_title_brief',
        'step': '4.a',
        'genre': item.genre,
        'collection': item.collectionName,
        'collection_position': item.colIndex + 1,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  moreInfoTitleDiscovery: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-discovery',
        'label': 'more_info_title',
        'step': '4.b',
        'genre': item.genre,
        'collection': item.collectionName,
        'collection_position': item.colIndex,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  addToMyListBriefDiscovery: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-discovery',
        'label': 'add_to_mylist',
        'step': '4.c',
        'genre': item.genre,
        'collection': item.collectionName,
        'collection_position': item.colIndex,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  playTitleBriefExplore: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-explore',
        'label': 'play_title_brief',
        'step': '4.a',
        'genre': item.genre,
        'collection': item.collectionName,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  moreInfoTitleExplore: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-explore',
        'label': 'more_info_title',
        'step': '4.b',
        'genre': item.genre,
        'collection': item.collectionName,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  addToMyListBriefExplore: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-explore',
        'label': 'add_to_mylist',
        'step': '4.c',
        'genre': item.genre,
        'collection': item.collectionName,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  playTitleBriefSearch: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-search',
        'label': 'play_title_brief',
        'step': '4.a',
        'genre': item.genre,
        'title': item.titleName,
        'title_position': item.titIndex,
        'search_query': item.searchQuery
      }
    });
  },
  moreInfoTitleSearch: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-search',
        'label': 'more_info_title',
        'step': '4.b',
        'genre': item.genre,
        'title': item.titleName,
        'title_position': item.titIndex,
        'search_query': item.searchQuery
      }
    });
  },
  addToMyListBriefSearch: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-brief-search',
        'label': 'add_to_mylist',
        'step': '4.c',
        'genre': item.genre,
        'title': item.titleName,
        'title_position': item.titIndex,
        'search_query': item.searchQuery
      }
    });
  },
  playTitleDetail: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-detail',
        'label': 'play_title_detail',
        'step': '5.a',
        'genre': item.genre,
        'collection': item.collectionName,
        'title': item.titleName
      }
    });
  },
  addToMyListDetail: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'title-detail',
        'label': 'add_to_mylist',
        'step': '4.c',
        'genre': item.genre,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  closeTitleDetail: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'close_title_detail'
      }
    });
  },
  toHomeFromLanding: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'landing',
        'label': 'to_home_from_landing',
        'step': '2.a'
      }
    });
  },
  toSignupFromLanding: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'to_signup_from_landing'
      }
    });
  },
  signupFacebook: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'signup_fb'
      }
    });
  },
  signupGoogle: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'signup_google'
      }
    });
  },
  inputNameSignup: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'enter_text',
        'label': 'input_name_signup'
      }
    });
  },
  inputMailSignup: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'enter_text',
        'label': 'input_mail_signup'
      }
    });
  },
  inputPasswordSignup: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'enter_text',
        'label': 'input_password_signup'
      }
    });
  },
  checkboxCaptcha: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'checkbox_captcha'
      }
    });
  },
  signupEmail: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'signup_mail'
      }
    });
  },
  toSigninFromSignup: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'to_signin_from_signup'
      }
    });
  },
  closeSignup: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'close_signup'
      }
    });
  },
  loginFacebook: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'login_fb'
      }
    });
  },
  loginGoogle: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'login_google'
      }
    });
  },
  inputMailSignin: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'enter_text',
        'label': 'input_mail_signin'
      }
    });
  },
  inputPasswordSignin: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'enter_text',
        'label': 'input_password_signin'
      }
    });
  },
  signinEmail: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'signin_email'
      }
    });
  },
  toResetpassFromSignin: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'to_resetpass_from_signin'
      }
    });
  },
  toSignupFromSignin: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'to_signup_from_signin'
      }
    });
  },
  closeSignin: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'label': 'close_signin'
      }
    });
  },
  searchTitle: query => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'navbar',
        'label': 'search_title',
        'step': '2.b',
        'search_query': query
      }
    });
  },
  exploreClick: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'navbar',
        'label': 'explore_click',
        'step': '2.c'
      }
    });
  },
  exploreCollection: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'explore-menu',
        'label': 'explore_collection',
        'step': '3.f',
        'genre': item.name,
        'collection_position': item.position
      }
    });
  },
  exploreGenre: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'explore-menu',
        'label': 'explore_genre',
        'step': '3.g',
        'genre': item.name,
        'collection_position': item.position
      }
    });
  },
  hoverTitleDiscovery: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'hover',
        'category': 'interaction',
        'action': 'title-brief-discovery',
        'label': 'hover_title',
        'step': '3.c',
        'genre': item.genre,
        'collection': item.collectionName,
        'collection_position': item.colIndex,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  hoverTitleExplore: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'hover',
        'category': 'interaction',
        'action': 'title-brief-explore',
        'label': 'hover_title',
        'step': '3.c',
        'genre': item.genre,
        'collection': item.collectionName,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  hoverTitleSearch: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'hover',
        'category': 'interaction',
        'action': 'title-brief-search',
        'label': 'hover_title',
        'step': '3.c',
        'genre': item.genre,
        'title': item.titleName,
        'title_position': item.titIndex
      }
    });
  },
  landingFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'landing'
      }
    });
  },
  discoveryFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'discovery'
      }
    });
  },
  exploreFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'explore'
      }
    });
  },
  searchFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'search'
      }
    });
  },
  titleBriefFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'title_brief'
      }
    });
  },
  titleLandingFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'title_landing'
      }
    });
  },
  titleDetailFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'title_detail'
      }
    });
  },
  playerFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'player'
      }
    });
  },
  adStartedFunnel: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'ad_started',
        'ad_position': item.adposition,
        'ad_title': item.adtitle,
        'title': item.title,
        'media_id': item.title_id
      }
    });
  },
  adCompleteFunnel: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'ad_complete',
        'ad_position': item.adposition,
        'ad_title': item.adtitle,
        'title': item.title,
        'media_id': item.title_id
      }
    });
  },
  firstFrameFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'first_frame'
      }
    });
  },
  play25Funnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'play_25'
      }
    });
  },
  play50Funnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'play_50'
      }
    });
  },
  play75Funnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'play_75'
      }
    });
  },
  playCompleteFunnel: () => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'funnel',
        'category': 'funnel',
        'action': 'play_complete'
      }
    });
  },
  conversion: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'conversion',
        'value': item.value,
        'ad_position': item.adposition,
        'ad_title': item.adtitle,
        'title': item.title,
        'media_id': item.title_id
      }
    });
  },
  joinTime: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'join_time',
        'kpi_value': item.value
      }
    });
  },
  contentLoadTime: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'content_load_time',
        'kpi_value': item.value
      }
    });
  },
  playTime: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'play_time',
        'kpi_value': item.value
      }
    });
  },
  buffer: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'buffer',
        'kpi_value': item.value
      }
    });
  },
  interruptions: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'interruptions',
        'kpi_value': item.value
      }
    });
  },
  bufferRatio: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'buffer_ratio',
        'kpi_value': item.value
      }
    });
  },
  errorPlayer: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'error',
        'kpi_value': item
      }
    });
  },
  playerReady: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'jw_player',
        'category': 'quality_of_experience',
        'label': 'player_ready',
        'kpi_value': item.value
      }
    });
  },
  playRecommendation: item => {
    TagManager.dataLayer({
      dataLayer: {
        'event': 'button_click',
        'category': 'interaction',
        'action': 'recommendation',
        'label': 'view_recommendation',
        'title_viewed': item.titlePast,
        'title_recommendation': item.titleName
      }
    });
  }
});
