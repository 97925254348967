import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export function useCheckPlaylistLoading(
  videoData,
  entitlementMedia,
  PLAYER_TYPES,
  loadingEntitlementMedia,
  loadingVideoData,
  isLogedIn,
  playlist,
  loadingPlaylist
) {
  const [shouldRender, setShouldRender] = useState(false);
  const history = useHistory();

  const checkMetadata = media => {
    return Object.keys(media).length > 0;
  };

  useEffect(() => {
    if (
      loadingVideoData &&
      checkMetadata(videoData) &&
      loadingPlaylist &&
      checkMetadata(playlist)
    ) {
      if (videoData?.monetizationType === PLAYER_TYPES.AVOD) {
        setShouldRender(true);
        return;
      }
      if (videoData?.monetizationType === PLAYER_TYPES.TVOD) {
        if (!isLogedIn) {
          history.push('/inicio');
          return;
        }
        if (isLogedIn && loadingEntitlementMedia) {
          if (checkMetadata(entitlementMedia)) {
            setShouldRender(true);
            return;
          } else {
            history.push('/inicio');
            return;
          }
        }
      }
    }
  }, [
    videoData,
    entitlementMedia,
    PLAYER_TYPES,
    history,
    loadingEntitlementMedia,
    loadingVideoData,
    isLogedIn,
    playlist,
    loadingPlaylist
  ]);

  return shouldRender;
}
