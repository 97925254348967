import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ModalCustomPlayer = ({
  showModal,
  textTitle,
  textContent,
  type,
  cancelText,
  acceptText,
  cancelCallback,
  acceptCallback
}) => {
  const handleClose = () => {
    return false;
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      className={`modal-custom-player modal-type-${type}`}
    >
      <Modal.Body>
        <div className="mb-4">
          <div className="d-block">
            <h5
              className={`text-center font-weight-bold ${
                type === 'tvod' ? 'text-gold-500' : 'text-white'
              }`}
            >
              {textTitle}
            </h5>
            <h6 className="text-white text-center mb-4">{textContent}</h6>
          </div>
          <div className="row">
            <div className="col-6">
              <Button
                className="btn btn-block text-purple mr-3"
                variant="light"
                onClick={() => cancelCallback()}
              >
                {cancelText}
              </Button>
            </div>
            <div className="col-6">
              <Button
                className={`btn btn-block mr-3 ${type === 'tvod' ? 'text-black' : 'text-white'}}`}
                variant={type === 'tvod' ? 'gold-500' : 'primary'}
                onClick={() => acceptCallback()}
              >
                {acceptText}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalCustomPlayer.propTypes = {
  showModal: PropTypes.bool.isRequired,
  textTitle: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  acceptText: PropTypes.string.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  acceptCallback: PropTypes.func.isRequired
};

export default ModalCustomPlayer;
