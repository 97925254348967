import {
  SET_ERROR_CATEGORY_LIST,
  SET_ERROR_CATEGORY_MOVIES,
  SET_COLLECTIONS,
  SET_COLLECTION_TITLES,
  SET_FEATURED_MEDIA,
  SET_MEDIA_DETAILED_INFO,
  SET_FEATURED_MEDIA_DETAILED_INFO,
  SET_MEDIA_INFO,
  SET_EXPLORE_MEDIA,
  SET_EXPLORE_HAS_MORE,
  SET_COLLECTION_NAME,
  SET_TITLE_NAME,
  SET_TITLE_ID,
  SET_RESUME_TIME,
  SET_BROWSE,
  SET_EPISODES,
  SET_SUGGEST,
  SET_CREDITS_START,
  SET_ERROR_SUGGEST,
  SET_SHOW_SUGGEST,
  SET_SERVICE_AVAILABLE,
  SET_PREVIOUS_NEXT_EPISODE,
  SET_TENANCY,
  SET_PLAYLIST,
  SET_VIDEO_DATA,
  SET_USER_ADULT,
  SET_MUX_DATA,
  SET_SHOW_MODAL,
  SET_MODAL_ID,
  SET_MODAL_DATA,
  SET_MODAL_ERROR,
  SET_CURRENT_MODAL_STATE,
  RESUME_TIME_SENDED,
  SET_COLLECTION_NAME_MODAL,
  SET_TRAILER_SOURCE,
  LOADING_PLAYLIST,
  LOADING_VIDEO_DATA,
  SET_ERROR_PLAYLIST,
  SET_EPISODES_HAS_MORE,
  RESET_EPISODES,
  RESET_EXPLORE_MEDIA
} from './types';

const initialState = {
  collectionsTitles: [],
  collections: [],
  categoryListError: '',
  categoryMoviesError: '',
  featuredMedia: {},
  featuredMediaDetailedInfo: {},
  mediaMetadata: {},
  mediaInfo: {},
  exploreMedia: [],
  exploreHasMore: true,
  collectionName: '',
  titleName: '',
  titleId: '',
  resumeTime: 0,
  browseResult: {},
  episodes: [],
  suggest: {},
  creditsStart: 0,
  suggestionsError: '',
  showSuggest: false,
  serviceAvailable: false,
  previousOrNextEpisode: {},
  tenancy: '',
  playlist: {},
  videoData: {},
  userAdult: false,
  muxData: {},
  showModal: false,
  modalId: null,
  modalData: {},
  modalError: '',
  currentModalState: false,
  resumeTimeSended: false,
  collectionNameModal: '',
  trailerSource: {},
  loadingPlaylist: false,
  loadingVideoData: false,
  errorPlaylist: '',
  episodesHasMore: true
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_ERROR_CATEGORY_LIST:
    return { ...state, categoryListError: payload };
  case SET_COLLECTIONS:
    return { ...state, collections: payload };
  case SET_ERROR_CATEGORY_MOVIES:
    return { ...state, categoryMoviesError: payload };
  case SET_COLLECTION_TITLES:
    return { ...state, collectionsTitles: [...state.collectionsTitles, payload] };
  case SET_FEATURED_MEDIA:
    return { ...state, featuredMedia: payload };
  case SET_MEDIA_DETAILED_INFO:
    return { ...state, mediaMetadata: payload };
  case SET_FEATURED_MEDIA_DETAILED_INFO:
    return { ...state, featuredMediaDetailedInfo: payload };
  case SET_MEDIA_INFO:
    return { ...state, mediaInfo: payload };
  case SET_EXPLORE_MEDIA:
    return { ...state, exploreMedia: [...state.exploreMedia, ...payload] };
  case RESET_EXPLORE_MEDIA:
    return { ...state, exploreMedia: [] };
  case SET_EXPLORE_HAS_MORE:
    return { ...state, exploreHasMore: payload };
  case SET_COLLECTION_NAME:
    return { ...state, collectionName: payload };
  case SET_TITLE_NAME:
    return { ...state, titleName: payload };
  case SET_TITLE_ID:
    return { ...state, titleId: payload };
  case SET_RESUME_TIME:
    return { ...state, resumeTime: payload };
  case SET_BROWSE:
    return { ...state, browseResult: payload };
  case SET_EPISODES:
    return { ...state, episodes: [...state.episodes, ...payload] };
  case RESET_EPISODES:
    return { ...state, episodes: [] };
  case SET_SUGGEST:
    return { ...state, suggest: payload };
  case SET_SHOW_SUGGEST:
    return { ...state, showSuggest: payload };
  case SET_CREDITS_START:
    return { ...state, creditsStart: payload };
  case SET_ERROR_SUGGEST:
    return { ...state, suggestionsError: payload };
  case SET_SERVICE_AVAILABLE:
    return { ...state, serviceAvailable: payload };
  case SET_PREVIOUS_NEXT_EPISODE:
    return { ...state, previousOrNextEpisode: payload };
  case SET_TENANCY:
    return { ...state, tenancy: payload };
  case SET_PLAYLIST:
    return { ...state, playlist: payload };
  case SET_VIDEO_DATA:
    return { ...state, videoData: payload };
  case SET_USER_ADULT:
    return { ...state, userAdult: payload };
  case SET_MUX_DATA:
    return { ...state, muxData: payload };
  case SET_SHOW_MODAL:
    return { ...state, showModal: payload };
  case SET_MODAL_ID:
    return { ...state, modalId: payload };
  case SET_MODAL_DATA:
    return { ...state, modalData: payload };
  case SET_MODAL_ERROR:
    return { ...state, modalError: payload };
  case SET_CURRENT_MODAL_STATE:
    return { ...state, currentModalState: payload };
  case RESUME_TIME_SENDED:
    return { ...state, resumeTimeSended: payload };
  case SET_COLLECTION_NAME_MODAL:
    return { ...state, collectionNameModal: payload };
  case SET_TRAILER_SOURCE:
    return { ...state, trailerSource: payload };
  case LOADING_PLAYLIST:
    return { ...state, loadingPlaylist: payload };
  case LOADING_VIDEO_DATA:
    return { ...state, loadingVideoData: payload };
  case SET_ERROR_PLAYLIST:
    return { ...state, errorPlaylist: payload };
  case SET_EPISODES_HAS_MORE:
    return { ...state, episodesHasMore: payload };
  default:
    return state;
  }
};
