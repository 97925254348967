const transformAssets = item => {
  const assetKeys = ['poster_h', 'poster_v', 'poster_tv'];

  return assetKeys.reduce((acc, key) => {
    if (item[key]) {
      acc.push({
        url: item[key],
        type: key
      });
    }
    return acc;
  }, []);
};
export default transformAssets;
