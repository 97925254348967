import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FormControl, Form } from 'react-bootstrap';
import validator from '../../utils/validator';
import { forgotSchema } from '../../utils/validator/schemas';
import { POST_FORGOT_PASSWORD, POST_RESET_ERROR_RESTORE_PASS } from '../../store/login/types';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';
import { POST_RE_SEND_EMAIL_CONFIRMATION } from '../../store/emailconfirmation/types';

const ForgotForm = props => {
  const [forgotForm, setForgotForm] = useState({ email: '' });
  const [validationErrors, setValidationErrors] = useState({ email: '' });
  const [message, setMessage] = useState('');
  const {
    forgotPassword,
    dispatchToast,
    reSendEmailConfirmation,
    errorCode,
    resetErrors
  } = props;

  const handleResponse = msg => {
    setMessage(msg);
  };

  const handleInputChange = event => {
    setForgotForm({ ...forgotForm, [event.target.name]: event.target.value });
    setValidationErrors({ ...validationErrors, [event.target.name]: '' });
    resetErrors();
    setMessage('');
  };

  const handleSubmit = event => {
    event.preventDefault();
    const errors = validator(forgotSchema, forgotForm);

    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationErrors(messages);
      return false;
    }
    forgotPassword({ ...forgotForm, response: handleResponse });
    // TODO: loader
  };

  const resendEmail = () => {
    const email = forgotForm.email;
    return (
      <>
        <p>
          {message}
        </p>
        <button
          type="button"
          className="text-primary link-button"
          onClick={() => reSendEmailConfirmation({ email })} >
          Reenviar correo de activación
        </button>
      </>
    );
  };

  const toast = (type) => {
    if (type === 'danger' && errorCode === 'ACCOUNT_NOT_VALIDATED') {
      dispatchToast({
        title: '¡Oops!',
        body: resendEmail(),
        type: type,
        delay: 15000
      });
    }
    else if (type === 'danger') {
      dispatchToast({
        title: '¡Oops!',
        body: message,
        type: type,
        delay: 15000
      });
    } else {
      dispatchToast({
        title: '¡Listo!',
        body: message,
        type: type,
        delay: 15000
      });
    }
  };

  useEffect(() => {
    if (errorCode === 'ACCOUNT_NOT_VALIDATED') {
      toast('danger');
      setTimeout(() => {
        resetErrors();
        setMessage('');
      }, 15000);
    }
    else if (errorCode) {
      toast('danger');
      setTimeout(() => {
        resetErrors();
        setMessage('');
      }, 15000);
    }
    else {
      toast('info');
    }
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Form.Group className="form-label-group mb-4">
        <FormControl
          name="email"
          type="email"
          placeholder="Ingresa tu correo electrónico"
          onChange={handleInputChange}
          isInvalid={validationErrors.email}
        />
        <Form.Label>Correo electrónico</Form.Label>
        <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-end mb-4">
        <Button variant="primary" type="submit" className="btn-lg">
          Reestablecer contraseña
        </Button>
      </div>
    </form>
  );
};

ForgotForm.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  dispatchToast: PropTypes.func.isRequired,
  errorCode: PropTypes.string.isRequired,
  reSendEmailConfirmation: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired
};

const stateToProps = state => ({
  errorCode: state.login.passwordErrorCode
});

const actionsToProps = dispatch => ({
  forgotPassword: payload => dispatch({ type: POST_FORGOT_PASSWORD, payload }),
  dispatchToast: payload => dispatch({ type: SET_TOAST_NOTIFICATION, payload }),
  reSendEmailConfirmation: payload => dispatch({ type: POST_RE_SEND_EMAIL_CONFIRMATION, payload }),
  resetErrors: payload => dispatch({ type: POST_RESET_ERROR_RESTORE_PASS, payload })
});

export default connect(stateToProps, actionsToProps)(ForgotForm);
