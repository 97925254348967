import { SET_RECAPTCHA } from './types';

const initialState = {
  isValid: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_RECAPTCHA:
    return { ...state, isValid: payload };
  default:
    return state;
  }
};
