import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { connectSearchBox } from 'react-instantsearch-dom';

let timerId = null;
const SearchBox = ({ currentRefinement, refine, delay }) => {
  const [value, setValue] = useState(currentRefinement);

  const onChangeDebounced = event => {
    const currentValue = event.currentTarget.value;
    clearTimeout(timerId);
    timerId = setTimeout(() => refine(currentValue), delay);

    setValue(currentValue);
  };

  return (
    <Form.Group className="search-box mb-0">
      <Form.Control
        type="search"
        placeholder="Encuentra películas y series"
        value={value}
        onChange={(e) => onChangeDebounced(e)}
      />
      <i className="fa fa-search"></i>
    </Form.Group>
  );
};

SearchBox.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  delay: PropTypes.number.isRequired
};

export default connectSearchBox(SearchBox);
