import { SET_ERROR_FACEBOOK_LOGIN, SET_ERROR_GOOGLE_LOGIN, SET_ERROR_TOOLBOX_LOGIN } from './types';

const initialState = {
  facebookErrorLogin: '',
  googleErrorLogin: '',
  toolboxErrorLogin: ''
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_ERROR_FACEBOOK_LOGIN:
    return { ...state, facebookErrorLogin: payload };
  case SET_ERROR_GOOGLE_LOGIN:
    return { ...state, googleErrorLogin: payload };
  case SET_ERROR_TOOLBOX_LOGIN:
    return { ...state, toolboxErrorLogin: payload };
  default:
    return state;
  }
};
