const messages = {
  ACCOUNT_NOT_FOUND: '¡Vaya! No tenemos registro del correo ingresado. Por favor, vuelve a intentarlo.',
  ACCOUNT_NOT_VALIDATED: 'Debes activar tu cuenta antes de realizar esta acción.',
  INVALID_PASSWORD_TOKEN: 'El link para reiniciar tu contraseña es inválido o está caducado.',
  ACCOUNT_WITHOUT_PASSWORD: 'Este correo está asociado a una cuenta de redes sociales. Para iniciar sesión usa la opción de inicio con redes sociales. También puedes volver a registrarte para asociar una contraseña a tu cuenta.',
  INACTIVE_ACCOUNT: '¡Ya casi! Debes activar tu cuenta para ingresar. Revisa tu correo.',
  VALIDATION_ERROR: '¡Vaya! El código ingresado es incorrecto o ha expirado.'
};

export default () => messages;
