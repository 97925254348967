import { useState, useRef, useEffect, useCallback } from 'react';

export function useIntersectionObserver({ onIntersect, hasMore }) {
  const [showLoader, setShowLoader] = useState(false);
  const observer = useRef(null);

  const nodeRef = useCallback(
    mov => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          onIntersect();
          setShowLoader(true);
        }
      });
      if (mov) observer.current.observe(mov);
      setShowLoader(false);
    },
    [onIntersect, hasMore]
  );

  useEffect(() => {
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  return { nodeRef, showLoader };
}
