import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useFetchPlaylist({
  loadingVideoData,
  videoData,
  mediaSignToken,
  fetchPlaylist,
  playlist,
  providerType,
  policyId,
  loadingSignToken,
  isLogedIn,
  playerTypes,
  entitlementMediaError,
  loadingEntitlementMedia,
  entitlementMedia,
  tvodStatus
}) {
  const history = useHistory();

  const checkMetadata = media => {
    return Object.keys(media).length > 0;
  };

  const buildPayload = (provider, path, token, has_drm) => ({
    url: `${provider.toLowerCase()}${path}${token ? `?token=${token}` : ''}`,
    provider,
    hasDrm: has_drm
  });

  useEffect(() => {
    if (loadingVideoData && checkMetadata(videoData)) {
      const { provider, provider_url, has_drm, is_signed } = videoData.sources;
      const { monetizationType } = videoData;
      const { status } = entitlementMediaError;
      let payload;
      const baseUrl = new URL(provider_url);
      const path = baseUrl.pathname;

      if (!checkMetadata(playlist)) {
        if (
          (monetizationType === playerTypes.TVOD && !isLogedIn) ||
          status === 404 ||
          status === 500
        ) {
          history.push('/inicio');
          return;
        }
        if (provider === providerType.JWPLAYER) {
          if (is_signed && !loadingSignToken) return;
          if (is_signed && mediaSignToken) {
            payload = buildPayload(
              provider,
              has_drm ? `${path}/${policyId}` : path,
              mediaSignToken,
              has_drm
            );
          } else {
            if (monetizationType === playerTypes.TVOD) {
              if (loadingEntitlementMedia && entitlementMedia.status === tvodStatus.WATCHING) {
                payload = buildPayload(provider, path, null, has_drm);
              }
            } else {
              payload = buildPayload(provider, path, null, has_drm);
            }
          }
        } else {
          payload = buildPayload(provider, path, null, has_drm);
        }
        payload && fetchPlaylist(payload);
      }
    }
  }, [
    loadingVideoData,
    videoData,
    mediaSignToken,
    fetchPlaylist,
    playlist,
    providerType,
    policyId,
    loadingSignToken,
    isLogedIn,
    playerTypes,
    entitlementMediaError,
    history,
    loadingEntitlementMedia,
    entitlementMedia,
    tvodStatus
  ]);
}
