import { SET_ERROR_MESSAGE, RESET_ERROR_MESSAGE } from './types';

const initialState = {
  message: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_ERROR_MESSAGE:
    return { ...state, message: payload };
  case RESET_ERROR_MESSAGE:
    return { ...state, message: payload };
  default:
    return state;
  }
};
