import Axios from 'axios';
import brightcoveEvents from '../constants/brightcoveEvents';
import appConfig from '../../config';

export default function eventsBrightcoveCollector({
  eventType,
  videoData,
  playlist,
  PROVIDER_TYPES,
  rangeTime
}) {
  const { brightcoveAccountId } = appConfig();
  const { BRIGHTCOVE_EVENTS } = brightcoveEvents();
  let eventBrightcove = {};

  const checkMetadata = media => {
    return Object.keys(media).length > 0;
  };

  if (
    !checkMetadata(videoData) ||
    !checkMetadata(playlist) ||
    videoData?.sources?.provider !== PROVIDER_TYPES.BRIGHTCOVE
  ) {
    return;
  }

  function generateUniqueId() {
    const currentDate = new Date().toISOString();
    const randomNumber = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    const uniqueId = `${randomNumber}_${currentDate}`;
    return uniqueId;
  }
  eventBrightcove = {
    event: eventType,
    account: brightcoveAccountId,
    session: generateUniqueId(),
    destination: encodeURIComponent(`${window.location.origin}${window.location.pathname}`),
    source: encodeURIComponent(playlist?.hls),
    time: +new Date(),
    video_duration: playlist?.duration,
    domain: 'riivi'
  };
  if (
    eventType === BRIGHTCOVE_EVENTS.VIDEO_IMPRESSION ||
    eventType === BRIGHTCOVE_EVENTS.VIDEO_VIEW ||
    eventType === BRIGHTCOVE_EVENTS.VIDEO_ENGAGEMENT
  ) {
    eventBrightcove = {
      ...eventBrightcove,
      video: videoData?.sources?.media_id,
      video_name: videoData?.title
    };
  }
  if (eventType === BRIGHTCOVE_EVENTS.VIDEO_ENGAGEMENT) {
    eventBrightcove = {
      ...eventBrightcove,
      range: rangeTime
    };
  }

  const queryParams = new URLSearchParams(eventBrightcove).toString();
  const baseUrl = 'https://metrics.brightcove.com/tracker';
  const fullUrl = `${baseUrl}?${queryParams}`;

  Axios({
    method: 'get',
    url: fullUrl
  });
}
