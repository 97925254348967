import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';
import setupPlayer from '../../utils/helpers/setupPlayer';
import ModalStateCookie from '../../utils/helpers/modalStateCookie';
import config from '../../config';

const PlayerModal = ({ setShowTrailer, modalData }) => {
  const { getModalMuted, setModalMuted } = ModalStateCookie();
  const [isMuted, setIsMuted] = useState(getModalMuted() || false);
  const [muteIcon, setMuteIcon] = useState('far fa-volume-slash');
  const isMutedRef = useRef(true);
  const playerRef = useRef(null);
  const { bitmovin } = window;

  useEffect(() => {
    isMutedRef.current = isMuted;
    setMuteIcon(isMuted ? 'far fa-volume-slash' : 'far fa-volume');
  }, [isMuted]);

  useEffect(() => {
    const player = document.getElementById('player-modal');
    document.getElementById('player-modal-container').appendChild(player);
    return () => {
      if (playerRef.current !== null) {
        playerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (bitmovin) {
      setupPlayer();
      const conf = {
        key: config().player.bitmovinKey,
        playback: {
          autoplay: true,
          muted: isMutedRef.current
        },
        ui: false
      };
      const player = new bitmovin.player.core.Player(document.getElementById('player-modal'), conf);
      playerRef.current = player;
      playerRef.current.unload();
      const playerSource = modalData.trailer.sources;
      playerRef.current.load(playerSource).then(() => {
        const bitmovinPlayer = bitmovin.player;
        bitmovin.player = bitmovin.player.core;
        bitmovin.player = bitmovinPlayer;
      });
    }
  }, [bitmovin, modalData]);

  useEffect(() => {
    playerRef.current.on(bitmovin.player.core.PlayerEvent.PlaybackFinished, () => {
      setShowTrailer(false);
    });
  }, [bitmovin, setShowTrailer]);

  const toggleMute = () => {
    const newValue = !isMuted;
    const muteIcon = newValue ? 'far fa-volume-slash' : 'far fa-volume';
    setMuteIcon(muteIcon);
    if (newValue) {
      playerRef.current.mute();
    } else {
      playerRef.current.unmute();
    }
    setModalMuted(newValue);
    setIsMuted(newValue);
  };

  const trailerUI = () => {
    return (
      <>
        <Button onClick={toggleMute} variant="light" className="btn-blur btn-mute" size="lg">
          <i className={muteIcon}></i>
        </Button>
      </>
    );
  };

  return ReactDOM.createPortal(trailerUI(), document.getElementById('player-modal'));
};

export default PlayerModal;
