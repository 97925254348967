const user = () => ({
  profile: '/user/profile',
  favourite: '/user/favorites',
  resumeMedia: '/user/resume',
  rating: '/user/rating',
  ratingMedia: '/user/profile/rating',
  userTitles: '/user/titles',
  userEntitlements: '/user/entitlements',
  entitlementMedia: '/user/media/:mediaId/entitlements',
  mediaSign: '/user/media/:media_id/sign'
});

export default user;
