import React from 'react';
import SliderContext from './context';
import SlideButton from './SlideButton';
import SliderWrapper from './SliderWrapper';
import Navigation from './Navigation';
import useSliding from './useSliding';
import useSizeElement from './useSizeElement';
import PropTypes from 'prop-types';

const Slider = ({ explore = false, children, collectionName, branded, orientationTiles, tvod }) => {
  const { width, elementRef } = useSizeElement();
  const { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext, amount, current } =
    useSliding(
      width,
      React.Children.count(children),
      collectionName,
      branded,
      orientationTiles,
      tvod
    );

  const contextValue = {
    elementRef
  };

  return (
    <SliderContext.Provider value={contextValue}>
      <SliderWrapper explore={explore}>
        <div className="slider">
          <div
            ref={containerRef}
            className={explore ? 'grid-container' : 'slider__container'}
            {...slideProps}
          >
            {children}
          </div>
        </div>
        {hasPrev && <SlideButton onClick={handlePrev} type="prev" />}
        {(hasNext || current > 0) && <SlideButton onClick={handleNext} type="next" />}
      </SliderWrapper>
      <Navigation explore={explore} amount={amount} current={current} branded={branded} />
    </SliderContext.Provider>
  );
};

Slider.propTypes = {
  children: PropTypes.array.isRequired,
  explore: PropTypes.bool,
  collectionName: PropTypes.string.isRequired,
  branded: PropTypes.bool,
  orientationTiles: PropTypes.string,
  tvod: PropTypes.bool
};

export default Slider;
