import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FacebookLogin, GoogleLogin, LoginForm } from '../';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader, ToolboxButton } from '../';
import { SET_LOGIN_MODAL } from '../../store/emailconfirmation/types';
import TagManager from '../../utils/helpers/gtmDatalayer';
import { POST_RESET_LOGIN_SERVICE } from '../../store/login/types';

const LoginModal = props => {
  const { show, modalOn, modalOff, resetLoginService } = props;
  const location = useLocation();
  const [userToken, setUserToken] = useState(null);
  const [idp, setIdp] = useState(null);

  const handleClose = () => {
    modalOff();
    TagManager().closeSignin();
  };
  const handleShow = () => {
    modalOn();
    TagManager().loginNavbar();
    resetLoginService();
  };
  const toResetpassFromSignin = () => {
    modalOff();
    TagManager().toResetpassFromSignin();
  };
  const toSignupFromSignin = () => {
    TagManager().toSignupFromSignin();
  };

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('toolbox_user_token');
    const idpParam = new URLSearchParams(location.search).get('idp');
    if (token && idpParam) {
      setUserToken(token);
      setIdp(idpParam);
    }
  }, [location]);

  return (
    <>
      {userToken && (
        <div className="position-fixed spinner-fixed">
          <Loader />
        </div>)
      }
      <Button variant="light" onClick={handleShow} className="text-purple">
        Ingresar
      </Button>
      <Modal show={show} onHide={handleClose} className="modal-custom">
        <Modal.Header className="border-0 position-relative">
          <button className="btn btn-link btn-close" onClick={handleClose}>
            <i className="fal fa-times fa-lg text-white"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="text-white">
          <div className="mb-4">
            <h5 className="text-white mb-4">Ingresa con tus redes sociales</h5>
            <FacebookLogin title="Ingresar con Facebook" source="login" />
            <GoogleLogin title="Ingresar con Google" source="login" />
          </div>
          <div className="mb-4">
            <ToolboxButton userToken={userToken} idp={idp} />
          </div>

          <h5 className="text-white mb-4">Ingresa con tu correo</h5>
          <div className="mb-5">
            <LoginForm />
            <div className="text-right mb-4">
              <Button
                as={Link}
                onClick={toResetpassFromSignin}
                variant="link"
                to="/recuperar/clave"
                className="text-white"
              >
                Olvidé mi contraseña
              </Button>
            </div>
          </div>
          <div className="text-center">
            <p className="text-white">¿Aún no tienes cuenta?</p>
            <Button
              as={Link}
              variant="link"
              to="/registro"
              className="text-white"
              onClick={toSignupFromSignin}
            >
              Crear cuenta
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

LoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  modalOn: PropTypes.func.isRequired,
  modalOff: PropTypes.func.isRequired,
  resetLoginService: PropTypes.func.isRequired
};

const stateToProps = state => ({
  show: state.emailconfirmation.modalLogin
});

const actionToProps = dispatch => ({
  modalOn: () => dispatch({ type: SET_LOGIN_MODAL, payload: true }),
  modalOff: () => dispatch({ type: SET_LOGIN_MODAL, payload: false }),
  resetLoginService: payload => dispatch({ type: POST_RESET_LOGIN_SERVICE, payload })
});

export default connect(stateToProps, actionToProps)(LoginModal);
