const schema = {
  code: {
    type: 'string',
    min: 4,
    max: 4,
    optional: false,
    pattern: '(^([a-zA-z]{4})$)',
    messages: {
      required: 'El código es obligatorio.',
      string: 'Por favor ingresar código valido.',
      stringMin: 'Su código es muy corto (minimo 4 caracteres).',
      stringMax: 'Su código es muy largo (maximo 4 caracteres).',
      stringPattern: 'El código debe contener solo letras'
    }
  }
};

export default schema;
