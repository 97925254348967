import Validator from 'fastest-validator';

export default (schema, params) => {
  const validator = new Validator();
  const check = validator.compile(schema);
  const validationResult = check(params);

  if (validationResult !== true) {
    return validationResult;
  }

  return [];
};
