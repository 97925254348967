import styled from 'styled-components';

const BgHeader = styled.div`
  background-image: ${props => `url(${props.background})`};
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
`;

export default BgHeader;
