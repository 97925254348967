import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import NotAvailableDefault from './default';

const NotAvailable = ({ tenancy }) => {
  const delay = 5;
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => setShowSpinner(false), delay * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (tenancy === 'ar' || tenancy === 'mx') {
      history.push('/pronto-disponible');
    }
  }, [tenancy, history]);

  if (!tenancy && showSpinner) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="light" className="spinner-custom" />
      </div>
    );
  }

  return <NotAvailableDefault />;
};

NotAvailable.propTypes = {
  tenancy: PropTypes.string.isRequired,
};

const stateToProps = state => ({
  tenancy: state.player.tenancy
});

export default connect(stateToProps)(NotAvailable);
