const TitleTypeFormat = url => {
  var arr = url.split('/');
  var titleType = '';
  if (arr.find(element => element === 'peliculas')) {
    titleType = 'movie';
  } else if (arr.find(element => element === 'documentales')) {
    titleType = 'movie';
  } else {
    titleType = 'series';
  }

  return titleType;
};

export default TitleTypeFormat;
