import React from 'react';
import { Container } from 'react-bootstrap';
import { Explore } from '../components';

const ExploreView = () => (
  <Container className="container-pt-custom" fluid>
    <Explore />
  </Container>
);

export default ExploreView;
