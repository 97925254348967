import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import store from './store';
import config from './config';
import './scss/app.scss';
import { healthCheck } from './network';
import { Spinner } from 'react-bootstrap';
import { ScrollProvider } from './utils/helpers/scrollToPremier';

const { sentryDsn, appEnv, chatServiceUrl } = config();

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: appEnv,
    autoSessionTracking: true,
    sampleRate: 0.2,
    tracesSampleRate: 0.2,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (
        event.exception.values[0].value ===
        'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22'
      ) {
        return null;
      }
      if (error && error.message && error.message.match(/https:\/\/goo\.gl\/LdLk22/i)) {
        return null;
      }
      if (error && error.message && error.message.match(/_internalId/i)) {
        return Math.round(Math.random() * 10) < 1 ? event : null;
      }
      return event;
    }
  });
  Sentry.configureScope(scope => {
    scope.setLevel(Sentry.Severity.Warning);
  });
}

if (chatServiceUrl) {
  /* WARNING: eslint throws an error for this line. It comes from Tawk.to */
  // var Tawk_API: any = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = chatServiceUrl;
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
}

const Application = () => {
  const [localTenancy, setLocalTenancy] = useState(null);

  useEffect(() => {
    healthCheck()
      .then(response => {
        const { tenant_id } = response.data;
        if (tenant_id) {
          setLocalTenancy(tenant_id);
        } else {
          setLocalTenancy('');
        }
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.tenant_id) {
          setLocalTenancy(response.data.tenant_id);
        } else {
          setLocalTenancy('');
        }
      });
  }, []);

  const render = () => {
    if (localTenancy === null) {
      return (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      );
    }
    return (
      <ScrollProvider>
        <Provider store={store}>
          <Router tenancy={localTenancy} setTenancy={setLocalTenancy} />
        </Provider>
      </ScrollProvider>
    );
  };
  return render();
};

ReactDOM.render(<Application />, document.getElementById('root'));
serviceWorker.unregister();
