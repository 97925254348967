import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';
import setupPlayer from '../../utils/helpers/setupPlayer';
import config from '../../config';

const PlayerTvod = ({ name, sponsor }) => {
  const [muteIcon, setMuteIcon] = useState('far fa-volume-slash');
  const [isMuted, setIsMuted] = useState(true);
  const playerRef = useRef(null);
  const { bitmovin } = window;

  useEffect(() => {
    const player = document.getElementById('player-carousel');
    document.getElementById('player-tvod-container').appendChild(player);
    return () => {
      if (playerRef.current !== null) {
        playerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const [selected] = sponsor.playlist?.sources.filter(
      source => source.type === 'application/vnd.apple.mpegurl'
    ) || [];

    if (bitmovin) {
      setupPlayer();
      const conf = {
        key: config().player.bitmovinKey,
        playback: {
          autoplay: true,
          muted: true
        },
        ui: false
      };
      const player = new bitmovin.player.core.Player(
        document.getElementById('player-carousel'),
        conf
      );
      playerRef.current = player;
      playerRef.current.unload();
      const source = {
        title: name,
        hls: selected?.file
      };
      playerRef.current.load(source).then(() => {
        const bitmovinPlayer = bitmovin.player;
        bitmovin.player = bitmovin.player.core;
        bitmovin.player = bitmovinPlayer;
      });
    }
  }, [bitmovin, name, sponsor]);

  const toogleMute = () => {
    if (isMuted) {
      setMuteIcon('far fa-volume');
      playerRef.current.unmute();
      setIsMuted(false);
    } else {
      setMuteIcon('far fa-volume-slash');
      playerRef.current.mute();
      setIsMuted(true);
    }
  };

  return ReactDOM.createPortal(
    <>
      <Button onClick={toogleMute} variant="light" className="btn-blur btn-mute" size="lg">
        <i className={muteIcon}></i>
      </Button>
    </>,
    document.getElementById('player-carousel')
  );
};

export default PlayerTvod;
