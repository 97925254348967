import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import appConfig from '../../config';

const SEARCH_TIMEOUT = 1000;// WIP
const delay = ms => {
  const promise = new Promise(resolve => setTimeout(resolve, ms));
  return { promise };
};

const Search = ({ onSearchStateChange, children, isSearching }) => {
  const cache = useRef(new Map());
  const promiseCache = useRef(new Map());
  const search = useRef(() => { });
  const [hasParams, setHasParams] = useState(false);

  const config = appConfig();
  const { appId, apiKey, indexName } = config.algolia;
  const emptyResults = {
    hits: [],
    nbHits: 0,
    nbPages: 0,
    page: 0,
    processingTimeMS: 0
  };

  let seachPromise = useRef(delay(SEARCH_TIMEOUT).promise);

  useEffect(() => {
    search.current = async requests => {
      if (requests.every(({ params }) => !params.query || params.query.length < 3) && !hasParams) {
        return Promise.resolve({ results: requests.map(() => emptyResults) });
      }
      if (requests.every(({ params }) => params.query.length > 2)) {
        setHasParams(true);
      }
      if (requests.every(({ params }) => params.query.length < 1)) {
        setHasParams(false);
        return Promise.reject(seachPromise.current).catch(e => e);
      }

      seachPromise.current = delay(SEARCH_TIMEOUT).promise;

      const body = JSON.stringify({ requests });
      const cached = cache.current.get(body);

      if (cached) {
        return cached;
      }
      const promiseCached = promiseCache.current.get(body);
      if (promiseCached) {
        return promiseCached;
      }
      const promise = algoliasearch(appId, apiKey).search(requests);
      promiseCache.current.set(body, promise);
      const response = await seachPromise.current.then(() => {
        return promise;
      });

      cache.current.set(body, response);
      promiseCache.current.delete(body);
      return response;
    };
  }, [isSearching, appId, apiKey, emptyResults, hasParams]);

  return (
    <InstantSearch
      onSearchStateChange={input => onSearchStateChange(input)}
      searchClient={{ search: search.current }}
      indexName={indexName}
    >
      {children}
    </InstantSearch>
  );
};

Search.propTypes = {
  children: PropTypes.array,
  searchStateHandling: PropTypes.func,
  isSearching: PropTypes.bool,
  onSearchStateChange: PropTypes.func.isRequired
};

const stateToProps = state => ({
  isSearching: state.search.isSearching
});

export default connect(stateToProps)(Search);
