export default () => ({
  LOCATIONS: {
    INTERNALNAVIGATION: 'Navegacion Interna',
    FEATUREDTITLE: 'Titulo Destacado',
    FAVORITES: 'Favoritos',
    SEARCH: 'Buscador',
    EXTERNAL: 'Externo',
    CONTINUEWATCHING: 'Continuar Viendo',
    TICKETS: 'Entradas'
  }
});
