const handleQueryParmas = ({ id, type, history }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const params = ['rqpid', 'rqptype'];

  params.forEach(param => queryParams.delete(param));

  if (id && type) {
    queryParams.set('rqpid', id);
    queryParams.set('rqptype', type);
  }
  history && history.push({ search: queryParams.toString() });
};

export default handleQueryParmas;
