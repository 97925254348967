export default () => ({
  scriptTag: (data, status) => {
    let scriptTag = document.createElement('script');
    scriptTag.type = 'application/ld+json';
    scriptTag.id = 'playerScript';
    if (status) {
      scriptTag.innerHTML = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Movie',
        name: data.name,
        url: `https://${window.location.hostname}/${data.url}`,
        description:
          data.description.length > 150 ? data.description.substring(0, 150) : data.description,
        genre: [data.genre],
        dateCreated: data.dateCreated,
        releasedEvent: {
          startDate: data.startDate,
          '@type': 'PublicationEvent',
          location: {
            '@type': 'Country',
            name: data.location
          }
        },
        actor: data.cast.map(c => ({ '@type': 'Person', name: c.name })),
        director: data.director.map(d => ({ '@type': 'Person', name: d.name }))
      });
      document.head.appendChild(scriptTag);
    } else {
      const scriptTag = document.getElementById('playerScript');
      if (scriptTag !== null) {
        scriptTag.parentNode.removeChild(scriptTag);
      }
    }
  },

  jsonPlayer: (data, status, source) => {
    const headerId = document.getElementsByTagName('head')[0];
    const json = document.getElementById('jsonPlayer');

    if (json) {
      json.parentNode.removeChild(json);
    }
    const jsonHeader = document.createElement('script');
    jsonHeader.id = 'jsonPlayer';
    jsonHeader.type = 'application/ld+json';
    if (status && source === 'layout') {
      jsonHeader.innerHTML = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        description:
          'Plataforma gratis para ver contenido latinoamericano. Cientos de películas, series y documentales gratis.'
      });
      headerId.appendChild(jsonHeader);
    } else if (status && source === 'player') {
      jsonHeader.innerHTML = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        description:
          'Plataforma gratis para ver contenido latinoamericano. Cientos de películas, series y documentales gratis.',
        image: data.image
      });
      headerId.appendChild(jsonHeader);
    }
  },

  metaTag: (data, status, source) => {
    let metaTag = document.querySelector('meta[name="description"]');
    if (status) {
      metaTag.setAttribute('content', data.description);
      if (source === 'player') {
        metaTag.setAttribute(
          'content',
          data.description.length > 150 ? data.description.substring(0, 150) : data.description
        );
      }
    } else {
      metaTag.setAttribute(
        'content',
        'Disfruta de las mejores películas, series y documentales latinos. ¡Completamente gratis!'
      );
    }
  },

  titleDocuments: title => {
    if (title) {
      document.title = `${title} | Riivi`;
    } else {
      document.title = 'Riivi';
    }
  },

  loadCanonicals: () => {
    const headerId = document.getElementsByTagName('head')[0];
    const link = document.getElementById('canonical');
    if (link) {
      link.parentNode.removeChild(link);
    }
    const canonicalHeader = document.createElement('link');
    canonicalHeader.id = 'canonical';
    canonicalHeader.rel = 'canonical';
    canonicalHeader.href = window
      ? [window.location.protocol, '//', window.location.host, window.location.pathname].join('')
      : `https://${window.location.hostname}`;

    headerId.appendChild(canonicalHeader);
  }
});
