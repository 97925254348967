import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import NoUserImg from '../../images/no-user.svg';

const IdpErrorView = () => {
  const { idp } = useParams();
  const location = useLocation();
  const [backUrl, setBackUrl] = useState('/');

  useEffect(() => {
    const back = new URLSearchParams(location.search).get('back');
    if (back) {
      setBackUrl(back);
    }
  }, [location]);

  return (
    <div className="container container-pt-custom">
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <img src={NoUserImg} className="mb-5" alt="" />
          <h2 className='mb-4'>¡Vaya! No se pudo iniciar sesión con {idp}</h2>
          <div className="row justify-content-center">
            <div className="col-6 col-lg-4">
              <Button
                as={Link}
                to={backUrl}
                variant='outline-light'
                size='lg'
                block
              >
                Volver
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdpErrorView;
