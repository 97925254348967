import React from 'react';
import { isMobile } from 'react-device-detect';
import { Badge, Button, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useImageProperties from '../../../utils/hooks/useImageProperties';
import getDurationOrSeason from '../../../utils/helpers/getDurationOrSeason';

const TicketItem = ({
  assets,
  title,
  fallbackImage,
  handleShow,
  tileHoverStatus,
  genre,
  playMedia
}) => {
  const { source, altText, fallbackClass, handleClick, showFallbackText } = useImageProperties(
    assets,
    title,
    fallbackImage,
    isMobile,
    handleShow
  );

  const hoverClass = tileHoverStatus ? 'text-white' : '';

  return (
    <>
      <div className="ticket-badge-container">
        <div className="badge-wrapper">
          {title.type === 'SERIES' && (
            <Badge variant="black" className="ticket-badge font-weight-light">
              {<span className="font-weight-bold">Serie</span>}
            </Badge>
          )}
          <Badge
            variant={tileHoverStatus ? 'blur-gray' : title.resume ? 'pink-fluorescent' : 'gold-500'}
            className="ticket-badge font-weight-light"
          >
            <i className={`fas fa-ticket-alt fa-md ${hoverClass}`} />
            {title.entitlement && (
              <span className={`font-weight-bold ml-1 ${hoverClass}`}>
                {title.entitlement?.remaining}
              </span>
            )}
          </Badge>
        </div>
        <img className={fallbackClass} src={source} alt={altText} onClick={handleClick} />
        {showFallbackText && (
          <div className="fallbackContainer">
            <span className="fallbackText">{title.name}</span>
          </div>
        )}
      </div>
      {tileHoverStatus && (
        <div className="content-item p-3">
          {title.entitlement && (
            <Button variant="gold-500" size="sm" className="btn-play" onClick={playMedia(title)}>
              <i className="fas fa-play"></i>
            </Button>
          )}
          <div className="content">
            <div className="row mb-3">
              <div className="col-sm-10">
                <h5 className="text-dark font-weight-bold content-title mb-1">{title.name}</h5>
              </div>
              <div className="col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex align-items-center flex-wrap">
                  <p className="text-dark text-nowrap small mr-2 mb-0 font-weight-bold">
                    {getDurationOrSeason(title)}
                  </p>
                  <p className="text-dark small mr-2 mb-0">{genre ? genre.name : ''}</p>
                  <span className="badge badge-gold-500 badge-pill small mr-2">{title.rated}</span>
                  <p className="text-dark small mb-0">{title.release_year}</p>
                </div>
              </div>
            </div>
            {title.resume && (
              <div className="d-flex align-items-center mb-3">
                <ProgressBar
                  className="progress-bar-custom progress-bar-paid"
                  variant="warning"
                  now={title.resume.completed}
                />
                <p className="small text-dark mb-0">Quedan {title.resume.remaining}</p>
              </div>
            )}
            {!title.entitlement && (
              <Button
                className="btn-gold-500 btn-more-info text-dark btn-block"
                size="sm"
                onClick={handleShow}
              >
                <i className="fas fa-ticket-alt fa-lg mr-1" />
                <span className="ml-1">
                  {title?.monetization?.offer?.price_label || 'No disponible'}
                </span>
              </Button>
            )}
            <Button
              className="btn-light-gray btn-more-info text-dark btn-block"
              size="sm"
              onClick={handleShow}
            >
              Ver más
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

TicketItem.propTypes = {
  assets: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  fallbackImage: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  tileHoverStatus: PropTypes.bool.isRequired,
  genre: PropTypes.object.isRequired,
  playMedia: PropTypes.func.isRequired
};

export default TicketItem;
