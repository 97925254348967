import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import playerError from '../../images/player-error.png';
import PropTypes from 'prop-types';

const PlayerError = ({ source }) => {

  const buttons = () => {
    if (source === 'playerError') {
      return (
        <Button
          variant="primary"
          className="text-white mr-2"
          onClick={() => window.location.reload()}
        >
          Reintentar
        </Button>
      );
    } else {
      return (
        <Button
          as={Link}
          variant="primary"
          to="/inicio"
          className="text-white mr-2"
        >
          Volver
        </Button>
      );
    }
  };

  return (
    <div className="container-fluid container-pt-custom">
      <div className="row mb-4">
        <div className="col-10 col-sm-6 col-md-4 mx-auto text-center">
          <img src={playerError} className="d-block img-fluid mx-auto" alt="playerError" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8 mx-auto text-center">
          <h5 className="font-weight-bold mb-5">¡Vaya! Al parecer un hubo un problema al intentar reproducir este contenido</h5>
          {buttons()}
        </div>
      </div>
    </div>
  );
};

PlayerError.propTypes = {
  source: PropTypes.string
};

export default PlayerError;
