const messages = {
  IDPIFRAME_INITIALIZATION_FAILED:
    '¡Oops! Parece que algo salío mal. Por favor, vuelve a intentarlo más tarde.',
  POPUP_CLOSED_BY_USER:
    '¡Vaya! Parece que has cerrado la ventana. Si fue por error, vuelve a intentarlo.',
  ACCESS_DENIED:
    '¡Vaya! Parece que no has aceptado los permisos. Si fue por error, vuelve a intentarlo.',
  IMMEDIATE_FAILED: 'No hay usuario disponible',
  ACCOUNT_NOT_FOUND:
    '¡Vaya! No tenemos registro del correo ingresado. Por favor, vuelve a intentarlo.',
  ACCOUNT_NOT_VALIDATED: '¡Ya casi! Debes activar tu cuenta para poder acceder a Riivi!.'
};

export default () => messages;
