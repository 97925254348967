import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SliderContext from './context';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { POST_FAVOURITES } from '../../store/user/types';
import {
  SET_MODAL_ID,
  SET_SHOW_MODAL,
  SET_CURRENT_MODAL_STATE,
  SET_COLLECTION_NAME_MODAL
} from '../../store/player/types';
import { SET_IS_SEARCHING } from '../../store/search/types';
import formatUrl from '../../utils/helpers/formatUrl';
import TagManager from '../../utils/helpers/gtmDatalayer';
import { isBrowser } from 'react-device-detect';
import fallbackImage from '../../images/fallBackImage.png';
import { NormalItem, ContinueItem, BrandedItem, TvodItem, TicketItem } from './items';
import addQueryParams from '../../utils/helpers/addQueryParams';
import playerTypes from '../../utils/constants/playerTypes';

const Item = ({
  collectionIndex,
  titleIndex,
  title,
  isLogin,
  postFavourites,
  loadingFav,
  myList,
  resetSearching,
  collectionName,
  origin,
  searchQuery = '',
  continueWatching,
  branded,
  orientationTiles,
  tvod,
  setShowModal,
  showModal,
  setModalId,
  setCurrentModalState,
  setCollectionNameModal,
  ticket
}) => {
  const history = useHistory();
  const [tileHoverStatus, settileHoverStatus] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null);
  const [imageOrientation, setImageOrientation] = useState(orientationTiles);
  const { PLAYER_TYPES } = playerTypes();

  useEffect(() => {
    if (orientationTiles) {
      setImageOrientation(orientationTiles);
    }
  }, [orientationTiles]);

  const genre = title.genres ? title.genres.find(g => g) : null;
  const assets = title.assets?.reduce((acc, cur) => ({ ...acc, [cur.type]: cur.url }), {}) ?? {};

  const infoTagManager = {
    genre: genre ? genre.name : '',
    colIndex: collectionIndex,
    titIndex: titleIndex,
    collectionName: collectionName,
    titleName: title.name,
    searchQuery: searchQuery
  };

  const handleShow = () => {
    if (origin === 'discovery') {
      TagManager().moreInfoTitleDiscovery(infoTagManager);
    } else if (origin === 'explore') {
      TagManager().moreInfoTitleExplore(infoTagManager);
    } else {
      TagManager().moreInfoTitleSearch(infoTagManager);
    }
    handleMouseLeave();
    setShowModal(true);
    setModalId(title.id);
    addQueryParams({
      id: title.id,
      type: title.type.toLowerCase() === 'other' ? 'movie' : title.type.toLowerCase(),
      history
    });
    setCurrentModalState(true);
    setCollectionNameModal(collectionName);
  };

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        settileHoverStatus(true);
      }, 500)
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
    settileHoverStatus(false);
  };

  useEffect(() => {
    if (origin === 'discovery' && tileHoverStatus && !showModal) {
      TagManager().hoverTitleDiscovery(infoTagManager);
      TagManager().titleBriefFunnel();
    } else if (origin === 'explore' && tileHoverStatus && !showModal) {
      TagManager().hoverTitleExplore(infoTagManager);
      TagManager().titleBriefFunnel();
    } else if (tileHoverStatus && !showModal) {
      TagManager().hoverTitleSearch(infoTagManager);
      TagManager().titleBriefFunnel();
    }
  }, [tileHoverStatus, showModal, origin, infoTagManager]);

  const playMedia = media => () => {
    resetSearching();
    if (origin === 'discovery') {
      TagManager().playTitleBriefDiscovery(infoTagManager);
    } else if (origin === 'explore') {
      TagManager().playTitleBriefExplore(infoTagManager);
    } else {
      TagManager().playTitleBriefSearch(infoTagManager);
    }

    if (media) {
      const formatter = formatUrl();
      const formatName = formatter.getCleanedString(media.name);
      const formatType = formatter.getCleanedString(media.type);
      const type = formatter.translateType(formatType);
      let titleId;
      if (media.type === 'SERIES') {
        if (media.resume) {
          titleId = media.resume?.media_id;
        } else {
          if (media.monetization?.type === PLAYER_TYPES.TVOD && media.entitlement) {
            titleId = media.entitlement?.media.id;
          } else {
            titleId = media.series?.first_episode;
          }
        }
      } else {
        titleId = media.resume?.media_id || media.movie?.id;
      }
      if (titleId) {
        history.push(`/${type}/${formatName}/${titleId}`, { from: collectionName });
      }
    }
  };

  const myListButton = () => {
    if (isLogin) {
      return (
        <button
          onClick={favouriteCall}
          className={`fav-button ${
            myList.some(movie => movie.title_id === title.id) ? 'active' : ''
          }`}
          type="button"
          disabled={loadingFav}
        >
          <i className="fal fa-heart"></i>
        </button>
      );
    }
  };

  const favouriteCall = () => {
    if (origin === 'discovery') {
      TagManager().addToMyListBriefDiscovery(infoTagManager);
    } else if (origin === 'explore') {
      TagManager().addToMyListBriefExplore(infoTagManager);
    } else {
      TagManager().addToMyListBriefSearch(infoTagManager);
    }

    if (title.id) {
      postFavourites(title.id);
    }
  };

  const handleReaminingTime = () => {
    const remainingTime = title.resume.remaining_time || 0;
    const days = Math.floor(remainingTime / (3600 * 24));
    const hours = Math.floor((remainingTime % (3600 * 24)) / 3600);
    if (title.purchase.is_waching) {
      return `${hours} horas`;
    }
    return `${days} días`;
  };

  const items = () => {
    const ItemComponents = [
      { condition: continueWatching, component: ContinueItem },
      { condition: branded, component: BrandedItem, additionalProps: { orientationTiles } },
      {
        condition: tvod,
        component: TvodItem,
        additionalProps: { handleReaminingTime, orientationTiles }
      },
      {
        condition: ticket,
        component: TicketItem,
        additionalProps: { handleReaminingTime }
      }
    ];

    const defaultProps = {
      assets,
      title,
      handleShow,
      fallbackImage,
      tileHoverStatus,
      playMedia,
      genre,
      isLogin
    };

    const foundItem = ItemComponents.find(item => item.condition);

    if (foundItem) {
      return React.createElement(foundItem.component, {
        ...defaultProps,
        ...foundItem.additionalProps
      });
    } else {
      return <NormalItem {...defaultProps} myListButton={myListButton} />;
    }
  };

  const setItemClassNames = ({ continueWatching, branded, imageOrientation, tvod }) => {
    let classNames = 'item';

    if (continueWatching) {
      classNames += ' item-continue-watching';
    }

    if (branded) {
      classNames += ' item-branded';
      if (imageOrientation === 'landscape') {
        classNames += ' item-branded-flex-h';
      } else {
        classNames += ' item-branded-flex-v';
      }
    }

    if (tvod) {
      classNames += ' item-tvod';
      if (imageOrientation === 'landscape') {
        classNames += ' item-tvod-flex-h';
      } else {
        classNames += ' item-tvod-flex-v';
      }
    }

    return classNames;
  };

  return (
    <SliderContext.Consumer>
      {({ elementRef }) => {
        return (
          <div
            ref={elementRef}
            className={setItemClassNames({ continueWatching, branded, imageOrientation, tvod })}
            onMouseEnter={isBrowser ? handleMouseEnter : null}
            onMouseLeave={isBrowser ? handleMouseLeave : null}
          >
            {items()}
          </div>
        );
      }}
    </SliderContext.Consumer>
  );
};

Item.propTypes = {
  title: PropTypes.object.isRequired,
  isLogin: PropTypes.bool.isRequired,
  postFavourites: PropTypes.func.isRequired,
  loadingFav: PropTypes.bool.isRequired,
  myList: PropTypes.array.isRequired,
  resetSearching: PropTypes.func.isRequired,
  collectionIndex: PropTypes.number.isRequired,
  titleIndex: PropTypes.number.isRequired,
  collectionName: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
  continueWatching: PropTypes.bool,
  branded: PropTypes.bool,
  tvod: PropTypes.bool,
  orientationTiles: PropTypes.string,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setModalId: PropTypes.func.isRequired,
  setCurrentModalState: PropTypes.func.isRequired,
  setCollectionNameModal: PropTypes.func.isRequired,
  ticket: PropTypes.bool
};

const stateToProps = ({ user, player }) => ({
  isLogin: user.isLogedIn,
  loadingFav: user.loading,
  myList: user.favouriteList,
  showModal: player.showModal
});

const actionToProps = dispatch => ({
  postFavourites: payload => dispatch({ type: POST_FAVOURITES, payload }),
  resetSearching: () => dispatch({ type: SET_IS_SEARCHING, payload: false }),
  setShowModal: payload => dispatch({ type: SET_SHOW_MODAL, payload }),
  setModalId: payload => dispatch({ type: SET_MODAL_ID, payload }),
  setCurrentModalState: payload => dispatch({ type: SET_CURRENT_MODAL_STATE, payload }),
  setCollectionNameModal: payload => dispatch({ type: SET_COLLECTION_NAME_MODAL, payload })
});

export default connect(stateToProps, actionToProps)(Item);
