import { takeLatest, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { verifyRecaptcha } from '../../network';
import { POST_RECAPTCHA, SET_RECAPTCHA } from './types';

function* postRecaptcha(action) {
  try {
    const { data } = yield call(verifyRecaptcha, action.payload);
    yield put({ type: SET_RECAPTCHA, payload: data.success });
  } catch (e) {
    Sentry.configureScope(scope => {
      scope.setLevel(Sentry.Severity.Warning);
      Sentry.captureException(e);
    });
  }
}

function* watchPostRecaptcha() {
  yield takeLatest(POST_RECAPTCHA, postRecaptcha);
}

export default [watchPostRecaptcha()];
