import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalConfirm = ({ title, description, actionButtons, show }) => {
  return (
    <Modal show={show} className="modal-custom">
      <Modal.Body className="text-white">
        <h4 className="mb-4 text-center">{title}</h4>
        <div className="text-center mb-5">{description}</div>
        {actionButtons}
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfirm;
