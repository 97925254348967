import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, useLocation, withRouter, Switch, Route as Default } from 'react-router-dom';
import { connect } from 'react-redux';
import Route from './layout';
import {
  ForgotPasswordView,
  ResetPasswordView,
  HomeView,
  ConfirmView,
  PendingView,
  RegisterView,
  LoginView,
  ExploreView,
  ProfileView,
  NotFound,
  PlayerView,
  AppRecommend,
  PlayerError,
  NotAvailable,
  ComingSoon,
  TvCodeView,
  IdpAuthorizationErrorView,
  IdpErrorView,
  TrailerPlayerView
} from './views';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const Router = ({ globalTenancy, tenancy, setTenancy }) => {
  const regexPatternWithEndSlash = /\/([a-zA-Z][a-zA-Z]+[0-9]*_)?[a-zA-Z][a-zA-Z]\//;
  const regexPatternWithEndLetters = /\/([a-zA-Z][a-zA-Z]+[0-9]*_)?[a-zA-Z][a-zA-Z]$/;
  const currentLocation = window.location.pathname.replace(regexPatternWithEndSlash, '');
  const hasBasename = regexPatternWithEndSlash.test(window.location.pathname);
  const hasTenancy =
    window.location.pathname.startsWith(`/${tenancy}/`) ||
    window.location.pathname.endsWith(`/${tenancy}`);

  const handleRedirect = () => {
    if (!hasTenancy && tenancy !== '') {
      if (currentLocation !== `/${tenancy}`) {
        const queryParams = new URLSearchParams(window.location.search);
        const rqpid = queryParams.get('rqpid');
        let newLocation = `/${tenancy}/${currentLocation.replace(/^\//, '')}`;

        if (rqpid) {
          newLocation = `${newLocation}/?${queryParams.toString()}`;
        }

        if (currentLocation === '/' || regexPatternWithEndLetters.test(window.location.pathname)) {
          window.history.pushState(null, null, newLocation);
        } else if (!hasBasename || !window.location.pathname.includes(`/${tenancy}/`)) {
          window.history.pushState(null, null, newLocation);
        }
      }
    }
  };

  useEffect(() => {
    if (globalTenancy) {
      setTenancy(globalTenancy);
    }
  }, [globalTenancy, setTenancy]);

  return (
    <BrowserRouter basename={`/${tenancy}`} key={tenancy}>
      <ScrollToTop>
        <Switch>
          <Route path="/inicio" Component={HomeView} exact />
          <Route
            path="/registro"
            Component={RegisterView}
            showNav={false}
            guestRoute={true}
            exact
          />
          <Route path="/login" Component={LoginView} showSearch={false} guestRoute={true} exact />
          <Route
            path="/recuperar/clave"
            Component={ForgotPasswordView}
            guestRoute={true}
            showSearch={false}
            exact
          />
          <Route
            path="/reiniciar/clave/:token"
            Component={ResetPasswordView}
            showSearch={false}
            guestRoute={true}
            exact
          />
          <Route
            path="/exito"
            Component={PendingView}
            showNav={false}
            showSearch={false}
            guestRoute={true}
            exact
          />
          <Route
            path="/account/confirm/:token"
            Component={ConfirmView}
            showSearch={false}
            showNav={false}
            guestRoute={true}
            exact
          />
          <Route path="/coleccion/:collectionName/:collectionID" Component={ExploreView} exact />
          <Route path="/genero/:collectionName/:collectionID" Component={ExploreView} exact />
          <Route path="/popular/:collectionName/:collectionID" Component={ExploreView} exact />
          <Route path="/" exact skipRoute={true} />
          <Route path="/perfil" Component={ProfileView} privateRoute={true} exact />
          <Route
            path="/:mediaType/:mediaName/:mediaId"
            Component={PlayerView}
            exact
            showNav={false}
          />
          <Route path="/error" exact Component={NotFound} />
          <Route path="/player-error" exact Component={PlayerError} showNav={false} />
          <Route path="/descargar-app" exact Component={AppRecommend} />
          <Route path="/no-disponible" showNav={false} exact Component={NotAvailable} />
          <Route path="/pronto-disponible" showNav={false} exact Component={ComingSoon} />
          <Route
            path="/ingresar-tv"
            Component={TvCodeView}
            privateRoute={true}
            tvRoute={true}
            exact
          />
          <Route
            path="/:idp/no-autorizado"
            Component={IdpAuthorizationErrorView}
            privateRoute={false}
            exact
          />
          <Route path="/:idp/error" Component={IdpErrorView} privateRoute={false} exact />
          <Route path="/videos/:videoId" Component={TrailerPlayerView} exact showNav={false} />
          <Route path="*" Component={NotFound} />
          {handleRedirect()}
          <Default path="/health-check">
            <h3>The App is Healthy</h3>
          </Default>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

Router.propTypes = {
  globalTenancy: PropTypes.string.isRequired,
  tenancy: PropTypes.string.isRequired,
  setTenancy: PropTypes.func.isRequired
};

const stateToProps = state => ({
  globalTenancy: state.player.tenancy
});

export default connect(stateToProps)(Router);
