import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { toolboxMso } from '../../network';
import config from '../../config';
import vtrLogo from '../../images/vtr.svg';
import claroLogo from '../../images/claro.svg';

const LoginButton = ({ country, userToken, idp, toolboxError }) => {
  const location = useLocation();
  const [identityProviders, setIdentityProviders] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showIpdSection, setShowIpdSection] = useState(true);
  const { toolboxCP, toolboxSpUrl } = config().socialLogin;
  const customLogo = {
    vtr2: vtrLogo,
    cla_cl: claroLogo
  };

  const getLoginUrl = idp => {
    const loginSearchParams = new URLSearchParams({
      idp: idp.shortName,
      country: country,
      cancel: `${window.location.origin}/${country}/${idp.shortName}/error?back=${location.pathname}`,
      return:
        location?.state?.from === 'tvRoute'
          ? `${window.location.origin}/${country}/ingresar-tv?idp=${idp.shortName}`
          : `${window.location.href}?idp=${idp.shortName}`
    });
    return `${toolboxSpUrl}/v2/auth/${toolboxCP}/login.html?${loginSearchParams.toString()}`;
  };

  const getLogoutUrl = useCallback(
    url => {
      const logoutSearchParams = new URLSearchParams({ url });
      return `${toolboxSpUrl}/v2/auth/${toolboxCP}/logout.html?${logoutSearchParams.toString()}`;
    },
    [toolboxCP, toolboxSpUrl]
  );

  useEffect(() => {
    toolboxMso()
      .then(r => {
        const mso = r.data?.filter(c => c.countryCode.toLowerCase() === country);
        if (mso && mso.length) {
          setIdentityProviders(mso[0].idp);
          setShowIpdSection(true);
        } else {
          setShowIpdSection(false);
        }
      })
      .catch(() => {
        setShowIpdSection(false);
      });
  }, [country]);

  useEffect(() => {
    if (toolboxError === 'TOOLBOX_NOT_AUTHORIZED') {
      window.location.href = getLogoutUrl(
        `${window.location.origin}/${country}/${idp}/no-autorizado?back=${location.pathname}`
      );
    } else if (toolboxError === 'TOOLBOX_CANCEL') {
      window.location.href = getLogoutUrl(
        `${window.location.origin}/${country}/${idp}/error?back=${location.pathname}`
      );
    }
  }, [country, idp, location.pathname, toolboxError, getLogoutUrl]);

  useEffect(() => {
    if (identityProviders?.length > 0 && !userToken) {
      setShowLoader(false);
    }
  }, [identityProviders, userToken]);

  return showIpdSection ? (
    <>
      <div>
        {showLoader ? (
          <Spinner
            animation="border"
            variant="light"
            className="spinner-custom d-block mx-auto"
            size="sm"
          />
        ) : (
          <>
            <p className="mb-3">O con tu proveedor de internet, telefonía o TV.</p>
            <div>
              {identityProviders.map((idp, idx) => (
                <Button
                  key={idx}
                  className="login-button mb-3"
                  variant="light"
                  size="lg"
                  block
                  onClick={() => {
                    setShowLoader(true);
                    window.location.href = getLoginUrl(idp, 'login.html');
                  }}
                >
                  <img
                    className={`logo-provider mr-2 ${customLogo[idp.shortName] ? `custom ${idp.shortName}` : ''}`}
                    src={customLogo[idp.shortName] || idp.logo}
                    alt={idp.description || ''}
                  />
                  Ingresa con {idp.description}
                </Button>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

LoginButton.propTypes = {
  country: PropTypes.string.isRequired,
  toolboxError: PropTypes.string,
  userToken: PropTypes.string,
  idp: PropTypes.string
};

const stateToProps = state => ({
  country: state.player.tenancy.toLowerCase(),
  toolboxError: state.socialLogin.toolboxErrorLogin
});

export default connect(stateToProps)(LoginButton);
