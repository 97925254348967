import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TrailerPlayer from './trailerPlayer';
import { FETCH_TRAILER_SOURCE } from '../../store/player/types';
import useCSSClassChange from '../../utils/hooks/useCSSClassChange';
import useVisibilityChange from '../../utils/hooks/useVisibilityChange';

const TrailerPlayerWrapper = ({ fetchTrailerSource, trailerSource }) => {
  const { videoId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [uiVisibility, setUiVisibility] = useState(false);

  useCSSClassChange(
    '.bmpui-ui-uicontainer',
    'bmpui-player-state-finished',
    'bmpui-player-state-prepared'
  );
  useVisibilityChange('.bmpui-ui-uicontainer', 'bmpui-controls-hidden', setUiVisibility);

  useEffect(() => {
    if (!Object.entries(trailerSource).length > 0) {
      fetchTrailerSource({
        id: videoId,
        setIsLoading: setIsLoading
      });
    }
  }, [fetchTrailerSource, videoId, trailerSource]);

  return (
    <>
      <div style={{ display: isLoading ? 'block' : 'none' }}>
        <div className="spinner-container">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      </div>
      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <div id="player-trailer-container" onMouseMove={() => setUiVisibility(true)}>
          <TrailerPlayer trailerSource={trailerSource} uiVisibility={uiVisibility} />
        </div>
      </div>
    </>
  );
};

TrailerPlayerWrapper.propTypes = {
  fetchTrailerSource: PropTypes.func.isRequired,
  trailerSource: PropTypes.object.isRequired
};

const stateToProps = ({ player }) => ({
  trailerSource: player.trailerSource
});

const actionToProps = dispatch => ({
  fetchTrailerSource: payload => dispatch({ type: FETCH_TRAILER_SOURCE, payload })
});

export default connect(stateToProps, actionToProps)(TrailerPlayerWrapper);
