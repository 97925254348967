import { SET_TOAST_NOTIFICATION } from './types';

const initialState = {
  toast: {
    title:'',
    body:'',
    type: 'info',
    delay: 15000
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_TOAST_NOTIFICATION:
    return { ...state, toast: payload };
  default:
    return state;
  }
};
