const schema = {
  email: {
    type: 'email',
    max: 100,
    optional: false,
    messages: {
      emailEmpty: 'El email es obligatorio.',
      email: 'El email debe tener el siguiente formato ejemplo@correo.com',
      string: 'Debes ingresar un email',
      stringMax: 'El email es muy largo (maximo 100 caracteres).'
    }
  },
};

export default schema;
