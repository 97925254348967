import config from '../config';

const network = client => ({
  loginClient: async payload =>
    client.post(config().auth.loginUrl, {
      grant_type: 'password',
      username: payload.email,
      password: payload.password
    }),

  forgotPassword: async ({ email }) =>
    client.post(config().auth.forgotUrl, { email, host: config().auth.resetHost }),

  resetPassword: async ({ password, token }) =>
    client.post(config().auth.resetUrl, { password, token })
});

export default network;
