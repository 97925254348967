import { useEffect, useState, useRef } from 'react';

const useCSSClassChange = (selector, classContains, classAdd) => {
  const [node, setNode] = useState(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const el = document.querySelector(selector);
      if (el) {
        setNode(el);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [selector]);

  useEffect(() => {
    if (node) {
      observerRef.current = new MutationObserver(mutationsList => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (classContains && node.classList.contains(classContains)) {
              node.classList.remove(classContains);
              node.classList.add(classAdd);
            }
          }
        }
      });

      observerRef.current.observe(node, { attributes: true });
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [node, classContains, classAdd]);

  return node;
};

export default useCSSClassChange;
