import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Image, Dropdown, Navbar, Nav } from 'react-bootstrap';
import LogoRiivi from './../../images/logo-riivi.svg';
import TickerPremier from './../../images/ticket-premier-gold.svg';
import { Link, useHistory } from 'react-router-dom';
import { LoginModal } from '../../components';
import { SET_ISLOGEDIN, SET_USER_EMAIL, SET_USER_NAME } from '../../store/user/types';
import config from '../../config';
import { SET_LOGIN_MODAL } from '../../store/emailconfirmation/types';
import { POST_RESET_ERROR } from '../../store/error/types';
import { SET_IS_SEARCHING } from '../../store/search/types';
import { FETCH_BROWSE, RESET_EXPLORE_MEDIA, SET_COLLECTION_NAME } from '../../store/player/types';
import Initials from '../../utils/helpers/extractFirstLetter';
import CookieHandler from '../../utils/helpers/cookie_handler';
import TagManager from '../../utils/helpers/gtmDatalayer';
import formatUrl from '../../utils/helpers/formatUrl';
import { BrowserView, MobileView } from 'react-device-detect';
import { useScroll } from '../../utils/helpers/scrollToPremier';

const NavbarCustom = props => {
  const history = useHistory();
  const [scroll, setScroll] = useState(false);
  const [isShow, setShow] = useState(false);
  const { toolboxCP, toolboxSpUrl } = config().socialLogin;
  const {
    emptyUserName,
    emptyUserEmail,
    logedOut,
    userName,
    idp,
    isLogedIn,
    profilePic,
    modalOff,
    resetErrors,
    resetSearching,
    browseCollections,
    browse,
    collections,
    resetExploreMedia,
    resetCollectionName,
    collectionName
  } = props;
  let buttons;
  const { scrollToElement } = useScroll();

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    browseCollections();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [browseCollections]);

  const toggleSidebar = () => {
    setShow(!isShow);
    if (!isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const signOut = () => {
    CookieHandler().removeToken();
    emptyUserName();
    emptyUserEmail();
    logedOut();
    modalOff();
    history.push('/inicio');
    if (idp && /^toolbox-/.test(idp)) {
      const logoutSearchParams = new URLSearchParams({ url: window.location.href });
      window.location.href = `${toolboxSpUrl}/v2/auth/${toolboxCP}/logout.html?${logoutSearchParams.toString()}`;
    }
  };

  const serProfilePic = info => {
    const profileInitials = Initials(info.name);
    if (info.pic.length === 0) {
      return <div className="avatar-user-no-img mr-2">{profileInitials}</div>;
    }
    return <img src={profilePic} className="avatar-user-img mr-2" alt="Avatar Profile" />;
  };

  const cleanError = () => {
    resetErrors();
    TagManager().registerNavbar();
  };

  const handleScrollPremier = () => {
    scrollToElement(history);
  };

  if (isLogedIn) {
    buttons = (
      <>
        <BrowserView>
          <Dropdown>
            <Dropdown.Toggle className="btn-focus-0" id="dropdown-basic" variant="">
              {serProfilePic({ pic: profilePic, name: userName })}
              {userName}
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-purple">
              <Dropdown.Item className="dropdown-item-custom" as={Link} to="/perfil">
                Mi perfil
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-item-custom" onClick={signOut}>
                Cerrar sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </BrowserView>
        <MobileView>
          <h5 className="mb-3">Bienvenido a Riivi</h5>
          <div className="media mb-4">
            {serProfilePic({ pic: profilePic, name: userName })}
            <div className="media-body">
              <h5 className="mb-0">{userName}</h5>
            </div>
          </div>
          <Link className="d-block text-white mb-2" to="/perfil" onClick={toggleSidebar}>
            Mi perfil
          </Link>
          <Link className="d-block text-white" to="/inicio" onClick={signOut}>
            Cerrar sesión
          </Link>
        </MobileView>
      </>
    );
  }

  if (!isLogedIn) {
    buttons = (
      <>
        <MobileView>
          <h5 className="mb-2">Ingresa a Riivi</h5>
        </MobileView>
        <div className="d-flex align-items-center">
          <Button
            as={Link}
            variant="primary"
            to="/registro"
            className="text-white mr-2"
            onClick={cleanError}
          >
            Crear cuenta
          </Button>
          <LoginModal />
        </div>
      </>
    );
  }

  const tagManagerHandler = item => {
    const { list, element, type } = item;
    type === 'genero'
      ? TagManager().exploreGenre({ name: element.name, position: list.indexOf(element) })
      : TagManager().exploreCollection({ name: element.name, position: list.indexOf(element) });
  };

  const handleClickNavbar = (element, el) => {
    if (collectionName !== el?.name) {
      tagManagerHandler({ list: element.list, element: el, type: element.type });
      resetExploreMedia();
      resetCollectionName();
    }
  };

  const mapCollection = element => {
    if (element.list) {
      return (
        <div>
          {element.list.map(el => (
            <Dropdown.Item
              key={el.id}
              className="text-white"
              as={Link}
              to={`/${element.type}/${formatUrl().getCleanedString(el.name)}/${el.id}`}
              onClick={() => handleClickNavbar(element, el)}
            >
              {el.name}
            </Dropdown.Item>
          ))}
        </div>
      );
    }
  };

  const mapGenres = element => {
    if (element.list) {
      return (
        <div>
          {element.list.map(el => (
            <li key={el.id}>
              <Dropdown.Item
                key={el.id}
                className="text-white"
                as={Link}
                to={`/genero/${formatUrl().getCleanedString(el.name)}/${el.id}`}
                onClick={() => handleClickNavbar(element, el)}
              >
                {el.name}
              </Dropdown.Item>
            </li>
          ))}
        </div>
      );
    }
  };

  const exploreHandle = () => {
    TagManager().exploreClick();
  };

  return (
    <>
      <Navbar
        expand="lg"
        className={scroll ? 'bg-navbar-custom' : 'bg-gradient-navbar'}
        fixed="top"
      >
        <div className="container-fluid position-relative">
          <Navbar.Brand as={Link} to="/inicio" onClick={resetSearching} className="m-0">
            <Image src={LogoRiivi} className="d-block logo-nav" />
          </Navbar.Brand>
          {props.children}
          <BrowserView>
            <Navbar.Toggle aria-controls="basic-navbar-nav text-white">
              <i className="fal fa-bars text-white"></i>
            </Navbar.Toggle>
          </BrowserView>
          <MobileView>
            <button className="btn btn-link" onClick={toggleSidebar}>
              <i className="fal fa-bars text-white fa-lg"></i>
            </button>
          </MobileView>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="flex-lg-grow-0 text-center collapse-custom"
          >
            <Dropdown className="dropdown-explore" onClick={exploreHandle}>
              <Dropdown.Toggle variant="link" id="dropdown-basic" size="sm" className="text-white">
                Explorar
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-custom">
                <div className="row">
                  <div className="col-12 col-lg-3 mb-3">
                    <Dropdown.Header>Populares</Dropdown.Header>
                    {mapCollection({ list: browse.popular, type: 'popular' })}
                  </div>
                  <div className="col-12 col-lg-5 mb-3">
                    <Dropdown.Header>Géneros</Dropdown.Header>
                    <ul className="list-genre">
                      {mapGenres({ list: browse.genres, type: 'genero' })}
                    </ul>
                  </div>
                  <div className="col-12 col-lg-4 mb-3">
                    <Dropdown.Header>Colecciones</Dropdown.Header>
                    {mapCollection({ list: browse.collections, type: 'coleccion' })}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {collections.length > 0 && collections.some(el => el.type === 'TVOD_COLLECTION') && (
              <BrowserView>
                <div className="btn-premier">
                  <Button
                    onClick={handleScrollPremier}
                    variant="link"
                    className="text-gold-500 font-weight-bold"
                  >
                    <img className="mr-1 mb-1" src={TickerPremier} alt="Premier" />
                    Estrenos
                  </Button>
                </div>
              </BrowserView>
            )}
            <Nav className="ml-auto">
              <BrowserView>
                <div>{buttons}</div>
              </BrowserView>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <MobileView>
        <div className={`sidebar-bg ${isShow ? 'show' : ''}`}>
          <div className="sidebar">
            <div className="p-3">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={toggleSidebar}>
                  <i className="fal fa-times text-white fa-2x"></i>
                </button>
              </div>
              <div className="buttons-sidebar">{buttons}</div>
            </div>
            <hr className="hr-sidebar"></hr>
            <div className="p-3">
              <h5>Explorar</h5>
              <div className="explore-list-sidebar">
                <div className="mb-4">
                  <Dropdown.Header className="text-white mb-2">Populares</Dropdown.Header>
                  {mapCollection({ list: browse.popular, type: 'popular' })}
                </div>
                <div className="mb-4">
                  <Dropdown.Header className="text-white mb-2">Géneros</Dropdown.Header>
                  <ul className="list-unstyled">
                    {mapGenres({ list: browse.genres, type: 'genero' })}
                  </ul>
                </div>
                <div className="mb-4">
                  <Dropdown.Header className="text-white mb-2">Colecciones</Dropdown.Header>
                  {mapCollection({ list: browse.collections, type: 'coleccion' })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
};

NavbarCustom.propTypes = {
  emptyUserName: PropTypes.func.isRequired,
  emptyUserEmail: PropTypes.func.isRequired,
  logedOut: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  idp: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  isLogedIn: PropTypes.bool.isRequired,
  profilePic: PropTypes.string.isRequired,
  modalOff: PropTypes.func.isRequired,
  children: PropTypes.object,
  resetErrors: PropTypes.func.isRequired,
  resetSearching: PropTypes.func.isRequired,
  browseCollections: PropTypes.func.isRequired,
  browse: PropTypes.object.isRequired,
  collections: PropTypes.array.isRequired,
  resetExploreMedia: PropTypes.func.isRequired,
  resetCollectionName: PropTypes.func.isRequired,
  collectionName: PropTypes.string.isRequired
};

const stateToProps = ({ user, player }) => ({
  idp: user.idp,
  userName: user.userName,
  userEmail: user.userEmail,
  isLogedIn: user.isLogedIn,
  profilePic: user.profilePic,
  browse: player.browseResult,
  collections: player.collections,
  collectionName: player.collectionName
});

const actionToProps = dispatch => ({
  emptyUserName: () => dispatch({ type: SET_USER_NAME, payload: '' }),
  emptyUserEmail: () => dispatch({ type: SET_USER_EMAIL, payload: '' }),
  logedOut: () => dispatch({ type: SET_ISLOGEDIN, payload: false }),
  modalOff: () => dispatch({ type: SET_LOGIN_MODAL, payload: false }),
  resetErrors: payload => dispatch({ type: POST_RESET_ERROR, payload }),
  resetSearching: () => dispatch({ type: SET_IS_SEARCHING, payload: false }),
  browseCollections: () => dispatch({ type: FETCH_BROWSE, payload: true }),
  resetExploreMedia: () => dispatch({ type: RESET_EXPLORE_MEDIA, payload: [] }),
  resetCollectionName: () => dispatch({ type: SET_COLLECTION_NAME, payload: '' })
});

export default connect(stateToProps, actionToProps)(NavbarCustom);
