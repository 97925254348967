import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PhoneApp from '../../images/phone-app.png';
import LogoGooglePlay from '../../images/logo-play-store.svg';
import LogoAppStore from '../../images/logo-app-store.svg';

const AppRecommend = () => {
  return(
    <div className="container container-pt-custom">
      <div className="row mb-3">
        <div className="col-12 text-center">
          <h3 className="mb-4">Mejora tu experiencia</h3>
          <p>Descarga nuestra aplicación y disfruta Riivi de la mejor manera</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <p className="small d-block">
            Descarga nuestra aplicación
          </p>
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="d-flex justify-content-center landing-logo-app mb-5">
          <a href="https://play.google.com/store/apps/details?id=com.riivi.riivi">
            <img src={LogoGooglePlay} className="logo-app-recommend mr-2" alt="Logo Google" />
          </a>
          <a href="https://apps.apple.com/cl/app/riivi/id1549483558">
            <img src={LogoAppStore} className="logo-app-recommend" alt="Logo App Store" />
          </a>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-4 d-flex justify-content-center mx-auto">
          <img src={PhoneApp} alt="" className="img-fluid"/>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 text-center">
          <Button as={Link} variant="link" to="/inicio" className="text-purple-pink">No gracias, deseo continuar a riivi.com</Button>
        </div>
      </div>
    </div>
  );
};

export default AppRecommend;