import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form, FormControl, Spinner, Container } from 'react-bootstrap';
import LogoRiivi from './../../images/logo-riivi.svg';
import ImgTvCode from './../../images/img-tv-code.svg';
import validator from '../../utils/validator';
import { tvCodeSchema } from '../../utils/validator/schemas';
import { POST_TV_CODE } from '../../store/login/types';

const TvCode = ({ postTvCode }) => {
  const [tvCode, setTvCode] = useState({ code: '' });
  const [validationErrors, setValidationErrors] = useState({ code: '' });
  const [message, setMessage] = useState('');
  const [isLoged, setIsLoged] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);

  const handleResponse = response => {
    if (response.success) {
      setIsLoged(true);
      setLoadLogin(false);
    } else {
      setMessage(response.message);
      setLoadLogin(false);
    }
  };

  const handleInputChange = event => {
    setTvCode({ ...tvCode, [event.target.name]: event.target.value });
    setValidationErrors({ ...validationErrors, [event.target.name]: '' });
    setMessage('');
  };

  const handleSubmit = event => {
    event.preventDefault();
    const errors = validator(tvCodeSchema, tvCode);

    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationErrors(messages);
      return false;
    }

    postTvCode({ code: tvCode.code, response: handleResponse });
    setLoadLogin(true);
  };

  return (
    <Container className="container-pt-custom">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-10 col-md-8 col-lg-6">
          <img src={LogoRiivi} alt="" className="d-block mx-auto mb-5" />
          {isLoged ?
            (<>
              <img src={ImgTvCode} alt="" className="d-block mx-auto mb-5" />
              <h2 className="text-white mb-1 text-center">¡Ya tienes tu cuenta vinculada con tu tv!</h2>
            </>) :
            (<>
              <h5 className="text-muted mb-1 text-center">Ingresar</h5>
              <h3 className="text-white mb-4 text-center">Ingresa el código de 4 dígitos que está en tu tv</h3>
              <div className="d-flex justify-content-center">
                <form noValidate onSubmit={handleSubmit} className="col-sm-10 col-md-6">
                  <Form.Group className="form-label-group">
                    <FormControl
                      name="code"
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Ingresa tu código"
                      isInvalid={validationErrors.code || message !== ''}
                      maxLength="4"
                    />
                    <Form.Label>Ingresa tu código</Form.Label>
                    <Form.Control.Feedback type="invalid">{message === '' ? 'Debes ingresar un código válido' : message}</Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-center">
                    <Button variant="primary" type="submit" size="lg" className="btn-block">
                      {loadLogin ? (<Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : 'Continuar'}
                    </Button>
                  </div>
                </form>
              </div>
            </>
            )}
        </div>
      </div>
    </Container >
  );
};

TvCode.propTypes = {
  postTvCode: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  postTvCode: payload => dispatch({ type: POST_TV_CODE, payload })
});

export default connect(null, actionsToProps)(TvCode);
