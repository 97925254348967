import { takeLatest, put } from 'redux-saga/effects';
import { SET_IS_SEARCHING, SET_SEARCH } from './types';

function* setIsSearching(action) {
  yield put({ type: SET_IS_SEARCHING, payload: action.payload });
}

function* watchsetIsSearching() {
  yield takeLatest(SET_SEARCH, setIsSearching);
}

export default [watchsetIsSearching()];
