import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import formatUrl from '../../utils/helpers/formatUrl';
import { SET_SHOW_SUGGEST, SET_CREDITS_START, SET_SUGGEST } from '../../store/player/types';
import adServer from '../../utils/constants/adServer';

const NextEpisode = ({
  suggest,
  clearShowSuggest,
  clearCreditsStart,
  clearSuggest,
  handleShowCredits,
  handleShowSuggest,
  playerInit,
  clearPlaylist,
  clearVideoData
}) => {
  const { next_episode, title } = suggest;
  const history = useHistory();
  const [nextEpisode, setNextEpisode] = useState(10);
  const { LOCATIONS } = adServer();

  setTimeout(() => {
    if (nextEpisode > 0) {
      setNextEpisode(nextEpisode - 1);
    }
  }, 1000);

  const playNext = useCallback(() => {
    const formatName = formatUrl().getCleanedString(title.name);
    if (title.type) {
      const type = formatUrl().translateType(formatUrl().getCleanedString(title.type));
      history.replace(`/${type}/${formatName}/${next_episode.id}`, {
        from: LOCATIONS.INTERNALNAVIGATION
      });
    } else {
      history.replace(`/series/${formatName}/${next_episode.id}`, {
        from: LOCATIONS.INTERNALNAVIGATION
      });
    }
    clearShowSuggest();
    clearCreditsStart();
    clearSuggest();
    clearPlaylist();
    clearVideoData();
    playerInit.current = false;
  }, [
    next_episode,
    history,
    title,
    clearShowSuggest,
    clearCreditsStart,
    clearSuggest,
    LOCATIONS,
    clearPlaylist,
    clearVideoData,
    playerInit
  ]);

  const handleCredits = () => {
    clearShowSuggest();
    handleShowCredits(true);
    handleShowSuggest(false);
  };

  useEffect(() => {
    if (nextEpisode === 1) {
      playNext();
    }
  }, [nextEpisode, playNext]);

  return (
    <div className="next-episode">
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-6 d-none d-sm-block">
          <div className="position-relative mb-3">
            <img
              src={next_episode.thumbnail}
              alt="Next episode"
              className="img-fluid episode-cover"
            />
            <button className="btn play-icon" onClick={() => playNext()}>
              <i className="fas fa-play"></i>
            </button>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-6">
          <h6 className="text-purple-pink">Próximo episodio </h6>
          <h2 className="text-white title-episode">
            T{next_episode.season.season_nr}:E{next_episode.episode_nr} {next_episode.name}
          </h2>
          <p className="text-white episode-duration">{next_episode.duration}</p>
          <p className="text-white next-episode-description">{next_episode.description}</p>
          <div className="row mr-3">
            <div className="col-sm-12 d-flex justify-content-between">
              <button className="btn btn-primary" onClick={() => handleCredits()}>
                Ver créditos
              </button>
              <button className="btn btn-link text-white btn-loading" onClick={() => playNext()}>
                <span>
                  Siguiente episodio en {nextEpisode} <i className="fas fa-play"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NextEpisode.propTypes = {
  suggest: PropTypes.object.isRequired,
  clearShowSuggest: PropTypes.func.isRequired,
  clearCreditsStart: PropTypes.func.isRequired,
  clearSuggest: PropTypes.func.isRequired,
  handleShowCredits: PropTypes.func.isRequired,
  handleShowSuggest: PropTypes.func.isRequired,
  playerInit: PropTypes.func.isRequired,
  clearPlaylist: PropTypes.func.isRequired,
  clearVideoData: PropTypes.func.isRequired
};

const stateToProps = state => ({
  suggest: state.player.suggest
});

const actionToProps = dispatch => ({
  clearShowSuggest: () => dispatch({ type: SET_SHOW_SUGGEST, payload: false }),
  clearCreditsStart: () => dispatch({ type: SET_CREDITS_START, payload: 0 }),
  clearSuggest: () => dispatch({ type: SET_SUGGEST, payload: {} })
});

export default connect(stateToProps, actionToProps)(NextEpisode);
