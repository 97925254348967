import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { default as Facebook } from 'react-facebook-login';
import * as Sentry from '@sentry/react';
import config from '../../config';
import authHelper from '../../utils/helpers/auth';
import { POST_FACEBOOK_LOGIN, SET_ERROR_FACEBOOK_LOGIN } from '../../store/social/types';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';
import TagManager from '../../utils/helpers/gtmDatalayer';

const FacebookLogin = props => {
  const {
    facebookLogin,
    setFacebookLoginError,
    title,
    facebookLoginError,
    source,
    isLoged,
    dispatchToast
  } = props;
  const [loadLogin, setLoadLogin] = useState(false);

  const responseFacebook = response => {
    facebookLogin({ accessToken: response.accessToken, login: authHelper().login });
    setLoadLogin(true);
    if (response.new_account) {
      dispatchToast({
        title: '¡Listo!',
        body: '¡Te has registrado exitosamente!',
        type: 'success',
        delay: 15000
      });
    }
  };

  const handleLoginFailure = e => {
    Sentry.configureScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal);
      Sentry.captureException(e);
    });
    setFacebookLoginError('¡Vaya! No es posible conectarse con Facebook en este momento.');
  };

  useEffect(() => {
    if (isLoged) {
      setLoadLogin(false);
    }
  }, [isLoged]);

  const facebookEvent = () => {
    if (source === 'login') {
      TagManager().loginFacebook();
    }
    if (source === 'register') {
      TagManager().signupFacebook();
    }
  };

  return (
    <form>
      <div className="mb-3">
        <Facebook
          appId={config().socialLogin.facebookKey}
          autoLoad={false}
          reauthenticate={true}
          textButton={loadLogin ? (<Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />) : title}
          fields={config().socialLogin.facebookFields}
          callback={responseFacebook}
          onFailure={handleLoginFailure}
          icon={loadLogin ? null : <i className="fab fa-facebook text-primary mr-2"></i>}
          cssClass="my-facebook-button-class"
          onClick={facebookEvent}
          isMobile={false}
        />
        <p className="text-danger small mt-1">{facebookLoginError}</p>
      </div>
    </form>
  );
};

FacebookLogin.propTypes = {
  title: PropTypes.string.isRequired,
  facebookLogin: PropTypes.func.isRequired,
  setFacebookLoginError: PropTypes.func.isRequired,
  facebookLoginError: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  isLoged: PropTypes.bool.isRequired,
  dispatchToast: PropTypes.func.isRequired
};

const stateToProps = state => ({
  facebookLoginError: state.socialLogin.facebookErrorLogin,
  isLoged: state.user.isLogedIn
});

const actionsToProps = dispatch => ({
  facebookLogin: payload => dispatch({ type: POST_FACEBOOK_LOGIN, payload }),
  setFacebookLoginError: payload => dispatch({ type: SET_ERROR_FACEBOOK_LOGIN, payload }),
  dispatchToast: payload => dispatch({ type: SET_TOAST_NOTIFICATION, payload })
});

export default connect(stateToProps, actionsToProps)(FacebookLogin);
