import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  POST_EMAIL_CONFIRMATION,
  POST_RE_SEND_EMAIL_CONFIRMATION,
  SET_LOGIN_MODAL
} from '../../store/emailconfirmation/types';
import IconAccountActivated from '../../images/icon-account-activated.svg';
import IconExpired from '../../images/icon-expired.svg';

const EmailConfirmation = props => {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const { token } = useParams();
  const location = useLocation();
  const CONST_RE_SEND = 'RESEND';
  const CONST_REDIRECT = 'REDIRECT';
  const {
    emailConfirm,
    confirmError,
    buttonStatus,
    reSendEmailConfirmation,
    reSendMessage,
    modalOn
  } = props;
  let buttonRetry;
  let buttonSuccess;

  useEffect(() => {
    setEmail(new URLSearchParams(location.search).get('email'));
    emailConfirm({ token });
  }, [emailConfirm, location, token]);

  const reSendEmail = () => {
    reSendEmailConfirmation({ email });
  };

  const homeRedirect = () => {
    modalOn();
    history.push('/inicio');
  };

  if (buttonStatus === CONST_RE_SEND) {
    buttonRetry = (
      <Button variant="primary" onClick={reSendEmail}>
        Reenviar
      </Button>
    );
  }

  if (buttonStatus === CONST_REDIRECT) {
    buttonSuccess = (
      <Button variant="primary" onClick={homeRedirect}>
        Comenzar
      </Button>
    );
  }

  const showError = error => {
    switch (error.type) {
    case 'ALREADY_VALIDATED':
      return <img src={IconExpired} alt="" className="mb-5" />;
    case 'CONFIRM_TOKEN_EXPIRED':
      return <img src={IconExpired} alt="" className="mb-5" />;
    case 'CONFIRMATION':
      return <img src={IconAccountActivated} alt="" className="mb-5" />;
    default:
    }
  };

  return (
    <form>
      <div className="row mb-4">
        <div className="col-sm-8 mx-auto text-center">
          {showError(confirmError)}
          <h6 className="font-weight-bold mb-4">{confirmError.message}</h6>
          <div>
            {buttonRetry}
            {buttonSuccess}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8 mx-auto text-center">
          <p className="text-danger">{reSendMessage}</p>
        </div>
      </div>
    </form>
  );
};

EmailConfirmation.propTypes = {
  confirmError: PropTypes.object.isRequired,
  emailConfirm: PropTypes.func.isRequired,
  finalTitle: PropTypes.array.isRequired,
  buttonStatus: PropTypes.string.isRequired,
  reSendEmailConfirmation: PropTypes.func.isRequired,
  reSendMessage: PropTypes.string.isRequired,
  modalOn: PropTypes.func.isRequired
};

const stateToProps = state => ({
  confirmError: state.emailconfirmation.emailConfirmed,
  finalTitle: state.emailconfirmation.title,
  buttonStatus: state.emailconfirmation.button,
  reSendMessage: state.emailconfirmation.reSendEmailConfirmed
});

const actionToProps = dispatch => ({
  emailConfirm: payload => dispatch({ type: POST_EMAIL_CONFIRMATION, payload }),
  reSendEmailConfirmation: payload => dispatch({ type: POST_RE_SEND_EMAIL_CONFIRMATION, payload }),
  modalOn: () => dispatch({ type: SET_LOGIN_MODAL, payload: true })
});

export default connect(stateToProps, actionToProps)(EmailConfirmation);
