import { combineReducers } from 'redux';
import recaptcha from './recaptcha';
import registerservice from './register';
import error from './error';
import emailconfirmation from './emailconfirmation';
import login from './login';
import user from './user';
import socialLogin from './social';
import player from './player';
import notification from './notification';
import search from './search';

export default combineReducers({
  recaptcha,
  registerservice,
  error,
  emailconfirmation,
  login,
  user,
  socialLogin,
  player,
  notification,
  search
});
