import React from 'react';
import Img404 from '../../images/img-404.png';

const InternalError = () => {
  return (
    <div className="container-fluid container-pt-custom">
      <div className="row mb-4">
        <div className="col-10 col-sm-6 col-md-4 mx-auto text-center">
          <img src={Img404} className="d-block img-fluid mx-auto" alt="Error" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8 mx-auto text-center">
          <h5 className="font-weight-bold mb-1">¡Oh, ocurrio un problema!</h5>
          <p>La página que buscas no existe o no esta por el momento</p>
        </div>
      </div>
    </div>
  );
};

export default InternalError;
