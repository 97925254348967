export const SET_COLLECTIONS = 'player/SET_COLLECTIONS';
export const SET_MOVIE_DETAILS = 'player/SET_MOVIE_DETAILS';
export const SET_COLLECTION_TITLES = 'player/SET_COLLECTION_TITLES';
export const SET_ERROR_CATEGORY_LIST = 'player/SET_ERROR_CATEGORY_LIST';
export const SET_ERROR_CATEGORY_MOVIES = 'player/SET_ERROR_CATEGORY_MOVIES';
export const SET_FEATURED_MEDIA = 'player/SET_FEATURED_MEDIA';
export const SET_FEATURED_MEDIA_DETAILED_INFO = 'player/SET_FEATURED_MEDIA_DETAILED_INFO';
export const SET_MEDIA_DETAILED_INFO = 'player/SET_MEDIA_DETAILED_INFO';
export const SET_MEDIA_INFO = 'player/SET_MEDIA_INFO';
export const SET_EXPLORE_MEDIA = 'player/SET_EXPLORE_MEDIA';
export const RESET_EXPLORE_MEDIA = 'player/RESET_EXPLORE_MEDIA';
export const SET_EXPLORE_HAS_MORE = 'player/SET_EXPLORE_HAS_MORE';
export const SET_PLAYER_STATUS = 'player/SET_PLAYER_STATUS';
export const SET_BROWSE = 'player/SET_BROWSE';
export const POST_RESUME_TIME = 'saga/player/POST_RESUME_TIME';
export const RESUME_TIME_SENDED = 'saga/player/RESUME_TIME_SENDED';
export const FETCH_RESUME_TIME = 'saga/player/FETCH_RESUME_TIME';
export const SET_RESUME_TIME = 'player/SET_RESUME_TIME';
export const FETCH_PLAYLIST = 'saga/player/FETCH_PLAYLIST';
export const SET_PLAYLIST = 'player/SET_PLAYLIST';
export const LOADING_PLAYLIST = 'player/LOADING_PLAYLIST';
export const SET_ERROR_PLAYLIST = 'player/SET_ERROR_PLAYLIST';
export const FETCH_VIDEO_DATA = 'saga/player/FETCH_VIDEO_DATA';
export const SET_VIDEO_DATA = 'player/SET_VIDEO_DATA';
export const LOADING_VIDEO_DATA = 'player/LOADING_VIDEO_DATA';
export const FETCH_COLLECTIONS = 'saga/player/FETCH_COLLECTIONS';
export const FETCH_FEATURED_TITLE = 'saga/player/FETCH_FEATURED_TITLE';
export const FETCH_MOVIE_METADATA = 'saga/player/FETCH_MOVIE_METADATA';
export const FETCH_EXPLORE_MOVIES = 'saga/player/FETCH_EXPLORE_MOVIES';
export const FETCH_BROWSE = 'saga/player/FETCH_BROWSE';
export const FETCH_EXPLORE_GENRE = 'saga/player/FETCH_EXPLORE_GENRE';
export const SET_COLLECTION_NAME = 'saga/player/SET_COLLECTION_NAME';
export const SET_TITLE_NAME = 'saga/player/SET_TITLE_NAME';
export const SET_TITLE_ID = 'saga/player/SET_TITLE_ID';
export const FETCH_EPISODES = 'saga/player/FETCH_EPISODES';
export const SET_EPISODES = 'saga/player/SET_EPISODES';
export const RESET_EPISODES = 'saga/player/RESET_EPISODES';
export const FETCH_SUGGEST = 'saga/player/FETCH_SUGGEST';
export const SET_SUGGEST = 'saga/player/SET_SUGGEST';
export const SET_SHOW_SUGGEST = 'saga/player/SET_SHOW_SUGGEST';
export const SET_ERROR_SUGGEST = 'player/SET_ERROR_SUGGEST';
export const SET_CREDITS_START = 'saga/player/SET_CREDITS_START';
export const SET_SERVICE_AVAILABLE = 'player/SET_SERVICE_AVAILABLE';
export const FETCH_SERVICE_AVAILABLE = 'saga/FETCH_SERVICE_AVAILABLE';
export const FETCH_PREVIOUS_NEXT_EPISODE = 'saga/FETCH_PREVIOUS_NEXT_EPISODE';
export const SET_PREVIOUS_NEXT_EPISODE = 'saga/SET_PREVIOUS_NEXT_EPISODE';
export const SET_TENANCY = 'player/SET_TENANCY';
export const SET_USER_ADULT = 'player/SET_USER_ADULT';
export const SET_MUX_DATA = 'player/SET_MUX_DATA';
export const SET_SHOW_MODAL = 'player/SET_SHOW_MODAL';
export const SET_MODAL_ID = 'player/SET_MODAL_ID';
export const GET_MODAL_DATA = 'player/GET_MODAL_DATA';
export const SET_MODAL_DATA = 'player/SET_MODAL_DATA';
export const SET_MODAL_ERROR = 'player/SET_MODAL_ERROR';
export const SET_CURRENT_MODAL_STATE = 'player/SET_CURRENT_MODAL_STATE';
export const SET_COLLECTION_NAME_MODAL = 'player/SET_COLLECTION_NAME_MODAL';
export const FETCH_TRAILER_SOURCE = 'saga/player/FETCH_TRAILER_SOURCE';
export const SET_TRAILER_SOURCE = 'saga/player/SET_TRAILER_SOURCE';
export const SET_EPISODES_HAS_MORE = 'player/SET_EPISODES_HAS_MORE';
