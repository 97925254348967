import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Featured from './';

const FeaturedSection = () => {
  return <Featured />;
};

FeaturedSection.propTypes = {
  tenancy: PropTypes.string.isRequired,
};

const stateToProps = state => ({
  tenancy: state.player.tenancy.toLowerCase(),
});

export default connect(stateToProps)(FeaturedSection);
