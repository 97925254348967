import React from 'react';
import { Container } from 'react-bootstrap';
import { ResetForm } from '../../components';

const ResetView = () => {
  return (
    <Container className="container-pt-custom">
      <div className="row">
        <div className="col-sm-10 col-md-8 col-lg-6">
          <h4 className="text-white mb-3">Nueva contraseña</h4>
          <p className="text-white mb-4">
            Ahora debes ingresar tu nueva contraseña
          </p>
          <ResetForm />
        </div>
      </div>
    </Container>
  );
};

export default ResetView;
