import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_COLLECTIONS } from '../../store/player/types';
import { FeaturedTile, ContinueWatching } from '../';
import Footer from '../footer';
import TagManagerHelper from '../../utils/helpers/gtmDatalayer';
import playerScript from '../../utils/helpers/playerScript';
import { Col, Row } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import CollectionWrapper from './collectionWrapper';

const Home = () => {
  const scriptsLoader = playerScript();
  const dispatch = useDispatch();
  const { collections, tenancy } = useSelector(({ player }) => player);

  useEffect(() => {
    if (tenancy) {
      dispatch({ type: FETCH_COLLECTIONS });
    }
  }, [tenancy, dispatch]);

  useEffect(() => {
    TagManagerHelper().discoveryFunnel();
    scriptsLoader.titleDocuments('Home');
    scriptsLoader.metaTag(
      {
        description: `Conoce nuestro catálogo con las mejores películas, series y documentales chilenos y latinos.
      ¡Completamente gratis! Tenemos el mejor contenido en Dramas, Comedias, Suspenso, Terror y Romance`
      },
      true
    );
  }, [scriptsLoader]);

  const SkeletonHome = () => {
    const skeletonArray = [...Array(3).keys()];
    return skeletonArray.map((_, index) => (
      <div className="container-fluid overflow-hidden py-2" key={index}>
        <Row className="mb-4">
          <Col xs="6" sm="4" lg="2">
            <SkeletonTheme color="#eee" highlightColor="#fff">
              <Skeleton height={36} />
            </SkeletonTheme>
          </Col>
        </Row>
        <Row>
          <SkeletonTheme color="#eee" highlightColor="#fff">
            <Col xs="12" className="d-flex">
              {[...Array(7)].map((_, index) => (
                <Skeleton height={130} className="skeleton-box-1 mx-1 mr-0" key={index} />
              ))}
            </Col>
          </SkeletonTheme>
        </Row>
      </div>
    ));
  };

  const render = () => {
    if (collections.length < 1) {
      return <SkeletonHome />;
    } else {
      return (
        <div className="container-fluid overflow-hidden px-0 pb-8">
          {collections.map((collection, index) => (
            <CollectionWrapper key={collection.id} collection={collection} index={index} />
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <FeaturedTile />
      <ContinueWatching />
      {render()}
      <Footer />
    </>
  );
};

export default Home;
