import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';

const Notification = ({ data, close }) => {
  const STYLE = {
    info: 'toast-info',
    warning: 'toast-warning',
    success: 'toast-success',
    danger: 'toast-danger'
  };

  const render = () => {
    if (data && data.title !== '' && data.body !== '') {
      const { title, body, type, delay, campaign } = data;
      return (
        <Toast
          show={true}
          onClose={close}
          style={campaign && { backgroundColor: '#D78BFE', color: '#120024' }}
          className={STYLE[type]}
          delay={delay}
          autohide={campaign ? false : true}
        >
          <Toast.Header>
            <strong className="mr-auto" style={campaign && { color: '#120024' }}>
              {title}
            </strong>
          </Toast.Header>
          <Toast.Body>{body}</Toast.Body>
        </Toast>
      );
    }
  };

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        float: 'right',
        position: 'relative',
        minHeight: '100vh',
        zIndex: 1080
      }}
    >
      {render()}
    </div>
  );
};

Notification.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func.isRequired
};

const stateToProps = state => ({
  data: state.notification.toast
});

const actionsToProps = dispatch => ({
  close: () =>
    dispatch({
      type: SET_TOAST_NOTIFICATION,
      payload: {
        title: '',
        body: '',
        type: 'info',
        delay: 15000
      }
    })
});

export default connect(stateToProps, actionsToProps)(Notification);
