const getDurationOrSeason = title => {
  if (title.movie && title.movie.duration) {
    return title.movie.duration;
  }
  if (title.series && title.series.seasons_count) {
    return `${title.series.seasons_count}T`;
  }
  if (title.resume) {
    return `T${title.resume.season_nr}:E${title.resume.episode_nr}`;
  }
  return '';
};

export default getDurationOrSeason;
