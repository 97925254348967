import { all } from 'redux-saga/effects';
import recaptcha from './recaptcha/saga';
import register from './register/saga';
import error from './error/saga';
import emailConfirm from './emailconfirmation/saga';
import login from './login/saga';
import userInfo from './user/saga';
import socialLogin from './social/saga';
import player from './player/saga';
import search from './search/saga';

export default function* rootSaga() {
  yield all([
    ...recaptcha,
    ...register,
    ...error,
    ...emailConfirm,
    ...login,
    ...userInfo,
    ...socialLogin,
    ...player,
    ...search
  ]);
}
