import { takeLatest, put, call, takeEvery, retry } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import {
  userInfo,
  deleteProfile,
  postFavourite,
  getFavourite,
  removeWatching,
  getRating,
  postRating,
  fetchUserTitles,
  fetchResumeTitles,
  fetchEntitlements,
  postEntitlementKey,
  fetchEntitlementsMedia,
  fetchMediaSign
} from '../../network';
import CookieHandler from '../../utils/helpers/cookie_handler';
import {
  GET_USER_INFO,
  DELETE_ACCOUNT,
  SET_DELETE_ACCOUNT,
  SET_USER_PROFILE,
  SET_ISLOGEDIN,
  SET_PROFILE_PIC,
  POST_FAVOURITES,
  SET_LOADING_FAVOURITE,
  SET_FAVOURITE_LIST,
  FETCH_FAVORITES_LIST,
  REMOVE_WATCHING,
  TITLE_REMOVED,
  FETCH_RATING_LIST,
  SET_LOADING_RATING,
  SET_RATING_LIST,
  POST_RATING,
  FETCH_RESUME_DATA,
  SET_RESUME_DATA,
  FETCH_CONTINUE_WATCHING,
  LOADING_CONTINUE_WATCHING,
  SET_CONTINUE_WATCHING,
  FETCH_ENTITLEMENTS,
  SET_ENTITLEMENTS,
  FETCH_FAVORITES,
  SET_FAVORITES_MEDIA,
  LOADING_ENTITLEMENTS,
  LOADING_FAVORITES,
  FETCH_ENTITLEMENTS_LIST,
  SET_ENTITLEMENTS_LIST,
  POST_ENTITLEMENT_KEY,
  SET_ENTITLEMENT_KEY,
  FETCH_ENTITLEMENT_MEDIA,
  SET_ENTITLEMENT_MEDIA,
  SET_ENTITLEMENT_MEDIA_ERROR,
  LOADING_ENTITLEMENT_MEDIA,
  POST_MEDIA_SIGN,
  SET_MEDIA_SIGN_TOKEN,
  LOADING_SIGN_TOKEN
} from './types';
import transformAssets from '../../utils/helpers/transformAssets';

function* getUserInfo() {
  try {
    const response = yield call(userInfo);
    if (response.data.profile_picture) {
      yield put({ type: SET_PROFILE_PIC, payload: response.data.profile_picture });
    } else {
      yield put({ type: SET_PROFILE_PIC, payload: '' });
    }
    yield put({ type: SET_ISLOGEDIN, payload: true });
    yield put({
      type: SET_USER_PROFILE,
      payload: {
        firstName: response.data.first_name,
        email: response.data.email,
        idp: response.data.idp || '',
        createdAt: response.data.created_at
      }
    });
  } catch (e) {
    const { response } = e;
    yield put({ type: SET_ISLOGEDIN, payload: false });
    if (response && response.status !== 401) {
      CookieHandler().removeToken();
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* deleteAccount() {
  try {
    yield call(deleteProfile);
    yield put({ type: SET_DELETE_ACCOUNT, payload: true });
  } catch (e) {
    const { response } = e;

    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* getFavoritesList() {
  try {
    const responseFav = yield call(getFavourite);
    yield put({ type: SET_FAVOURITE_LIST, payload: responseFav.data.titles });
  } catch (e) {
    const { response } = e;

    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* postFavourites(action) {
  try {
    yield put({ type: SET_LOADING_FAVOURITE, payload: true });
    yield call(postFavourite, action.payload);
    yield put({ type: SET_LOADING_FAVOURITE, payload: false });

    const responseFav = yield call(getFavourite);
    yield put({ type: SET_FAVOURITE_LIST, payload: responseFav.data.titles });
    const response = yield call(fetchUserTitles, { type: 'favorites' });
    yield put({ type: SET_FAVORITES_MEDIA, payload: response.data.favorites });
  } catch (e) {
    const { response } = e;

    yield put({ type: SET_LOADING_FAVOURITE, payload: false });
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* removeContinueWatching(action) {
  try {
    const response = yield call(removeWatching, action.payload);
    if (response.data.action === 'MOVIE_REMOVED' || response.data.action === 'SERIES_REMOVED') {
      yield put({ type: TITLE_REMOVED, payload: true });
      yield put({ type: TITLE_REMOVED, payload: false });
    }
  } catch (e) {
    const { response } = e;

    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* getRatingList() {
  try {
    const response = yield call(getRating);
    yield put({ type: SET_RATING_LIST, payload: response.data.titles });
  } catch (e) {
    const { response } = e;

    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* postRatingTitle(action) {
  try {
    yield put({ type: SET_LOADING_RATING, payload: true });
    yield call(postRating, action.payload);
    const response = yield call(getRating);
    yield put({ type: SET_LOADING_RATING, payload: false });
    yield put({ type: SET_RATING_LIST, payload: response.data.titles });
  } catch (e) {
    const { response } = e;

    yield put({ type: SET_LOADING_RATING, payload: false });
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* getResumeData(action) {
  try {
    const response = yield call(fetchResumeTitles, action.payload);
    yield put({
      type: SET_RESUME_DATA,
      payload: response.data
    });
  } catch (e) {
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* getWatchingMedia() {
  try {
    yield put({ type: LOADING_CONTINUE_WATCHING, payload: true });
    const response = yield call(fetchUserTitles, { type: 'watching' });
    yield put({
      type: SET_CONTINUE_WATCHING,
      payload: response.data.watching
    });
    yield put({ type: LOADING_CONTINUE_WATCHING, payload: false });
  } catch (e) {
    yield put({ type: LOADING_CONTINUE_WATCHING, payload: false });
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* getEntitlements() {
  try {
    yield put({ type: LOADING_ENTITLEMENTS, payload: true });
    const response = yield call(fetchUserTitles, { type: 'entitlements' });
    const { entitlements } = response.data;

    const responseWithAssets = entitlements.map(item => {
      return {
        ...item,
        assets: transformAssets(item)
      };
    });

    yield put({
      type: SET_ENTITLEMENTS,
      payload: responseWithAssets
    });
    yield put({ type: LOADING_ENTITLEMENTS, payload: false });
  } catch (e) {
    yield put({ type: LOADING_ENTITLEMENTS, payload: false });
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* getFavoritesMedia() {
  try {
    yield put({ type: LOADING_FAVORITES, payload: true });
    const response = yield call(fetchUserTitles, { type: 'favorites' });
    const { favorites } = response.data;

    const responseWithAssets = favorites.map(item => {
      return {
        ...item,
        assets: transformAssets(item)
      };
    });

    yield put({ type: SET_FAVORITES_MEDIA, payload: responseWithAssets });
    yield put({ type: LOADING_FAVORITES, payload: false });
  } catch (e) {
    yield put({ type: LOADING_FAVORITES, payload: false });
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* getEntitlementsList() {
  try {
    const response = yield call(fetchEntitlements);
    yield put({ type: SET_ENTITLEMENTS_LIST, payload: response.data.entitlements });
  } catch (e) {
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    }
  }
}

function* postActivateEntitlementKey(action) {
  try {
    yield put({ type: SET_ENTITLEMENT_KEY, payload: false });
    const response = yield retry(3, 5000, postEntitlementKey, action.payload);
    yield put({ type: SET_ENTITLEMENT_MEDIA, payload: response.data });
    yield put({ type: SET_ENTITLEMENT_KEY, payload: true });
  } catch (e) {
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Critical);
        Sentry.captureException(e);
      });
    }
  }
}

function* getEntitlementMedia(action) {
  try {
    yield put({ type: LOADING_ENTITLEMENT_MEDIA, payload: false });
    const response = yield retry(3, 5000, fetchEntitlementsMedia, action.payload);
    yield put({ type: SET_ENTITLEMENT_MEDIA, payload: response.data });
    yield put({ type: LOADING_ENTITLEMENT_MEDIA, payload: true });
  } catch (e) {
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Critical);
        Sentry.captureException(e);
      });
    }
    yield put({
      type: SET_ENTITLEMENT_MEDIA_ERROR,
      payload: {
        status: response.status,
        message: response.statusText
      }
    });
  }
}

function* getMediaSign(action) {
  try {
    yield put({ type: LOADING_SIGN_TOKEN, payload: false });
    const response = yield retry(3, 5000, fetchMediaSign, action.payload);
    yield put({ type: SET_MEDIA_SIGN_TOKEN, payload: response.data.token });
    yield put({ type: LOADING_SIGN_TOKEN, payload: true });
  } catch (e) {
    const { response } = e;
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Critical);
        Sentry.captureException(e);
      });
    }
  }
}

function* watchGetUserInfo() {
  yield takeLatest(GET_USER_INFO, getUserInfo);
}

function* watchDeleteAccount() {
  yield takeLatest(DELETE_ACCOUNT, deleteAccount);
}

function* watchGetFavoritesList() {
  yield takeLatest(FETCH_FAVORITES_LIST, getFavoritesList);
}

function* watchPostFavourites() {
  yield takeLatest(POST_FAVOURITES, postFavourites);
}

function* watchRemoveWatching() {
  yield takeLatest(REMOVE_WATCHING, removeContinueWatching);
}

function* watchGetRatingList() {
  yield takeLatest(FETCH_RATING_LIST, getRatingList);
}

function* watchPostRating() {
  yield takeLatest(POST_RATING, postRatingTitle);
}

function* watchGetResumeData() {
  yield takeEvery(FETCH_RESUME_DATA, getResumeData);
}

function* watchWatchingMedia() {
  yield takeEvery(FETCH_CONTINUE_WATCHING, getWatchingMedia);
}

function* watchEntitlements() {
  yield takeEvery(FETCH_ENTITLEMENTS, getEntitlements);
}

function* watchFavoritesMedia() {
  yield takeEvery(FETCH_FAVORITES, getFavoritesMedia);
}

function* watchEntitlementsList() {
  yield takeEvery(FETCH_ENTITLEMENTS_LIST, getEntitlementsList);
}

function* watchPostEntitlementKey() {
  yield takeEvery(POST_ENTITLEMENT_KEY, postActivateEntitlementKey);
}

function* watchEntitlementsMedia() {
  yield takeEvery(FETCH_ENTITLEMENT_MEDIA, getEntitlementMedia);
}

function* watchMediaSign() {
  yield takeEvery(POST_MEDIA_SIGN, getMediaSign);
}

export default [
  watchGetUserInfo(),
  watchDeleteAccount(),
  watchGetFavoritesList(),
  watchPostFavourites(),
  watchRemoveWatching(),
  watchGetRatingList(),
  watchPostRating(),
  watchGetResumeData(),
  watchWatchingMedia(),
  watchEntitlements(),
  watchFavoritesMedia(),
  watchEntitlementsList(),
  watchPostEntitlementKey(),
  watchEntitlementsMedia(),
  watchMediaSign()
];
