import { useState, useRef, useEffect } from 'react';
import TagManager from '../../utils/helpers/gtmDatalayer';

const PADDINGS = 19; // TODO: calculate

const useSliding = (
  elementWidth,
  countElements,
  collectionName,
  branded,
  orientationTiles,
  tvod
) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0);
  const [viewed, setViewed] = useState(0);

  useEffect(() => {
    const padding = tvod ? 0 : PADDINGS;
    const containerWidth = containerRef.current.clientWidth - padding;

    setContainerWidth(containerWidth);
    if (branded) {
      setTotalInViewport(Math.floor(containerWidth / elementWidth + 3));
    } else if (tvod && orientationTiles === 'landscape') {
      setTotalInViewport(Math.floor(containerWidth / containerWidth));
    } else {
      setTotalInViewport(Math.floor(containerWidth / elementWidth));
    }
  }, [containerRef, elementWidth, branded, orientationTiles, tvod]);

  const handlePrev = () => {
    setViewed(viewed - totalInViewport);
    setDistance(distance + containerWidth);
    if (!hasPrev) {
      setViewed(countElements);
      setDistance(containerWidth * -1 * Math.floor(countElements / totalInViewport));
    }
    TagManager().slideCollectionCarrousel({ collection: collectionName, position: current });
  };

  const handleNext = () => {
    setViewed(viewed + totalInViewport);
    setDistance(distance - containerWidth);
    if (!hasNext) {
      setViewed(0);
      setDistance(0);
    }
    TagManager().slideCollectionCarrousel({ collection: collectionName, position: current });
  };

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)` }
  };

  const elementsBySlide = totalInViewport || countElements;
  const hasPrev = distance < 0;
  const hasNext = viewed + totalInViewport < countElements;
  const amount = Math.ceil(countElements / elementsBySlide);
  const current = viewed / elementsBySlide;

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext, amount, current };
};

export default useSliding;
