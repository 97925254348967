import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import formatUrl from '../../utils/helpers/formatUrl';
import TagManager from '../../utils/helpers/gtmDatalayer';
import { SET_SHOW_SUGGEST, SET_CREDITS_START, SET_SUGGEST } from '../../store/player/types';
import adServer from '../../utils/constants/adServer';

const Recomended = ({
  suggest,
  clearShowSuggest,
  titleName,
  clearCreditsStart,
  clearSuggest,
  handleShowCredits
}) => {
  const history = useHistory();
  const { suggestions } = suggest;
  const { name, type, series, movie } = suggestions;
  const { LOCATIONS } = adServer();

  const handleCredits = () => {
    clearShowSuggest();
    handleShowCredits(true);
  };

  const playRecomended = () => {
    TagManager().playRecommendation({
      titlePast: titleName,
      titleName: name
    });
    const formatName = formatUrl().getCleanedString(name);
    const formatType = formatUrl().translateType(formatUrl().getCleanedString(type));
    if (formatType === 'series') {
      if (series.id) {
        const firstEp = series.episodes.slice(0, 1).map((ep) => ep.id);
        history.replace(`/${formatType}/${formatName}/${firstEp}`, { from: LOCATIONS.INTERNALNAVIGATION });
      } else {
        history.replace(`/${formatType}/${formatName}/${series.id}`, { from: LOCATIONS.INTERNALNAVIGATION });
      }
    } else {
      history.replace(`/${formatType}/${formatName}/${movie.id}`, { from: LOCATIONS.INTERNALNAVIGATION });
    }
    clearShowSuggest();
    clearCreditsStart();
    clearSuggest();
  };

  return (
    <div className="recomended">
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-6 d-none d-sm-block">
          <div className="position-relative mb-3">
            <img src={suggestions?.poster} alt="Recomended" className="img-fluid recomended-cover" />
          </div>
        </div>
        <div>
          <button className="btn btn-link btn-close" aria-label="Close" onClick={() => handleCredits()}>
            <i className="fal fa-times fa-lg text-white"></i>
          </button>
        </div>
        <div className="col-12 col-sm-6 col-lg-6">
          <h6 className="text-purple-pink">Te recomendamos </h6>
          <h2 className="text-white title-movie">{suggestions?.name}</h2>
          <p className="text-white recomended-description">{suggestions?.description}</p>
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-between ">
              <button
                className="btn btn-primary"
                onClick={() => playRecomended()}>
                <i className="fas fa-play mr-2"></i> Reproducir
              </button>
              <button className="btn text-purple-pink"
                onClick={() => handleCredits()}>Ver créditos
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

Recomended.propTypes = {
  suggest: PropTypes.object.isRequired,
  clearShowSuggest: PropTypes.func.isRequired,
  titleName: PropTypes.string.isRequired,
  clearCreditsStart: PropTypes.func.isRequired,
  clearSuggest: PropTypes.func.isRequired,
  handleShowCredits: PropTypes.func.isRequired
};

const stateToProps = ({ player }) => ({
  suggest: player.suggest,
  titleName: player.titleName
});

const actionToProps = dispatch => ({
  clearShowSuggest: () => dispatch({ type: SET_SHOW_SUGGEST, payload: false }),
  clearCreditsStart: () => dispatch({ type: SET_CREDITS_START, payload: 0 }),
  clearSuggest: () => dispatch({ type: SET_SUGGEST, payload: {} })
});

export default connect(stateToProps, actionToProps)(Recomended);
