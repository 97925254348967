import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  ProgressBar,
  Tab,
  Tabs,
  Dropdown,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Card
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import handlePaymentUrl from '../../utils/helpers/handlePaymentUrl';
import tvodStatus from '../../utils/constants/statusTvod';
import playerTypes from '../../utils/constants/playerTypes';
import Episodes from './episodes';

const DetailedSheet = ({
  modalId,
  modalData,
  isLogin,
  loadingFav,
  myList,
  fetchEpisodes,
  myEpisodes,
  myRatingList,
  loadingRating,
  favouriteCall,
  ratingCall,
  playMedia,
  removeTitle,
  setGenresArray,
  seasonPurchased,
  setShowModal,
  setNextPage,
  episodesHasMore,
  resetEpisodes
}) => {
  const [season, setSeason] = useState(1);
  const [seasonsCount, setSeasonsCount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const likedRef = useRef(null);
  const history = useHistory();
  const { TVOD_STATUS } = tvodStatus();
  const { PLAYER_TYPES } = playerTypes();

  useEffect(() => {
    const season = modalData.series;
    if (season) {
      setSeasonsCount(season.seasons_count);
    }
  }, [modalData]);

  useEffect(() => {
    if (modalData?.monetization?.offer?.season_nr) {
      setSeason(modalData.monetization.offer.season_nr);
    } else if (modalData?.series?.seasons.length) {
      setSeason(modalData?.series?.seasons[0].season_nr);
    }
  }, [modalData]);

  useEffect(() => {
    checkMetadata(modalData) ? setIsLoading(true) : setIsLoading(false);
  }, [modalData]);

  const checkMetadata = media => {
    return Object.entries(media).length === 0;
  };

  const returnDuration = movie => {
    if (movie) {
      return movie.duration;
    }
  };

  const setDirectorArray = directors => {
    if (directors && directors.length > 0) {
      return (
        <div className="small">
          <span
            className={`${
              modalData.monetization?.type === PLAYER_TYPES.TVOD
                ? 'text-blur-gray'
                : 'text-purple-pastel'
            } mr-1`}
          >
            Dirigida por:
          </span>
          <span className="media-body">{directors.map(dir => dir.name).join(', ')}</span>
        </div>
      );
    }
  };

  const setCastArray = credits => {
    if (credits && credits.length > 0) {
      return (
        <div className="small">
          <span
            className={`${
              modalData.monetization?.type === PLAYER_TYPES.TVOD
                ? 'text-blur-gray'
                : 'text-purple-pastel'
            } mr-1`}
          >
            Elenco:
          </span>
          <span className="media-body">{credits.map(cred => cred.name).join(', ')}</span>
        </div>
      );
    }
  };

  const myListButton = () => {
    if (isLogin && !modalData.monetization) {
      const isFavorite = myList.some(movie => movie.title_id === modalId);
      return (
        <Button
          variant="dark"
          className={`fav-button mr-2 mb-2 mb-lg-0 ${isFavorite ? 'active' : ''}`}
          onClick={favouriteCall}
          disabled={loadingFav}
          size="lg"
        >
          <i className="far fa-heart" />
        </Button>
      );
    }
  };

  const rateMovie = () => {
    if (isLogin) {
      if (myRatingList) {
        const isRating = myRatingList.find(rating => rating.title_id === modalId);
        if (isRating === undefined) {
          likedRef.current = null;
        } else {
          likedRef.current = isRating.liked;
        }
      }
      return loadingRating ? (
        <div className="mx-5 mb-4">
          <Spinner animation="border" variant="light" size="sm" className="spinner-custom" />
        </div>
      ) : (
        <Card
          className={`${
            modalData.monetization?.type === PLAYER_TYPES.TVOD ? 'bg-blur-gray' : 'bg-purple-800'
          } rate-card mb-3`}
        >
          <Card.Body className="d-flex justify-content-center align-items-center p-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="remove-button">No es para mí</Tooltip>}
            >
              <Button
                variant="outline-light"
                size="md"
                className={`btn-circle btn-circle-detail ${
                  likedRef.current ? 'btn-rating-unselected' : ''
                }`}
                onClick={() => ratingCall('dislike')}
                disabled={loadingRating}
              >
                <i className="fas fa-thumbs-down fa-lg"></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="remove-button">Me gusta</Tooltip>}
            >
              <Button
                variant="outline-light"
                size="md"
                className={`btn-circle btn-circle-detail ${
                  likedRef.current === null ? '' : likedRef.current ? '' : 'btn-rating-unselected'
                }`}
                onClick={() => ratingCall('like')}
                disabled={loadingRating}
              >
                <i className="fas fa-thumbs-up fa-lg"></i>
              </Button>
            </OverlayTrigger>
            <p className="text-center mx-2 mb-0">{textButtonRate()}</p>
          </Card.Body>
        </Card>
      );
    }
  };

  const changeSeason = season => {
    resetEpisodes();
    fetchEpisodes({ id: season.id, page: 1 });
    setSeason(season.season_nr);
  };

  const durationOrSeason = () => {
    if (modalData.type === 'MOVIE' || modalData.type === 'OTHER') {
      return returnDuration(modalData.movie);
    } else {
      return `${seasonsCount} ${seasonsCount >= 1 ? 'Temporadas' : 'Temporada'}`;
    }
  };

  const playOrPayButton = () => {
    if (modalData.monetization?.type === PLAYER_TYPES.TVOD && !modalData.entitlement) {
      return (
        <Button
          disabled={!modalData?.monetization?.offer}
          variant="gold-500"
          size="lg"
          onClick={() => {
            if (modalData?.monetization?.offer) {
              handlePaymentUrl(modalData.id, modalData.monetization, isLogin, history);
              !isLogin && setShowModal(false);
            }
          }}
          className="button-play"
        >
          {modalData?.monetization?.offer && <i className="fas fa-ticket-alt fa-lg mr-2"></i>}
          <span className="ml-1">
            {modalData?.monetization?.offer?.price_label || 'No disponible'}
          </span>
        </Button>
      );
    } else {
      return (
        <Button
          variant={`${modalData.monetization?.type === PLAYER_TYPES.TVOD ? 'gold-500' : 'primary'}`}
          size="lg"
          onClick={() => playMedia()}
          className="button-play"
        >
          <i className="fas fa-play mr-1" /> {modalData.resume ? 'Reanudar' : 'Reproducir'}
          {modalData.monetization?.type === PLAYER_TYPES.TVOD && (
            <div className="badge badge-light badge-pill ml-1">
              <span className="text-pink-fluorescent">{modalData.entitlement?.remaining}</span>
            </div>
          )}
        </Button>
      );
    }
  };

  const titlesDetails = () => {
    return (
      <>
        {!checkMetadata(modalData) && setDirectorArray(modalData.director)}
        {!checkMetadata(modalData) && setCastArray(modalData.credits)}
        <div className="small">
          <span
            className={`${
              modalData.monetization?.type === PLAYER_TYPES.TVOD
                ? 'text-blur-gray'
                : 'text-purple-pastel'
            } mr-1`}
          >
            País de Origen:
          </span>
          <span className="media-body">{modalData.origin_country?.name}</span>
        </div>
      </>
    );
  };

  const textButtonRate = () => {
    if (likedRef.current === null) {
      return 'Calificar';
    } else if (likedRef.current) {
      return 'Me gusta';
    } else {
      return 'No es para mí';
    }
  };

  const seasonPurchaseCard = () => {
    return (
      modalData.type === 'SERIES' &&
      modalData.monetization?.type === PLAYER_TYPES.TVOD &&
      !seasonPurchased && (
        <div className="row bg-blur-gray rounded d-lg-flex d-xs-none justify-content-center align-items-center my-3 mx-1">
          <div className="col-lg-9 col-sm-12 py-2">
            <h6 className="text-white text-lg-left text-sm-center font-weight-bold mb-0">
              Compra la temporada completa
            </h6>
          </div>
          <div className="col-lg-3 col-sm-12 modal-buttons">
            <Button
              variant="white"
              size="md"
              onClick={() => {
                handlePaymentUrl(modalData.id, modalData.monetization, isLogin, history);
                !isLogin && setShowModal(false);
              }}
              className="button-play my-3"
            >
              {modalData?.monetization?.offer?.price_label}
            </Button>
          </div>
        </div>
      )
    );
  };

  const render = () => {
    if (isLoading) {
      return (
        <div className="spinner-modal">
          <Spinner animation="border" variant="light" className="spinner-custom" />
        </div>
      );
    } else {
      return (
        <div className="mt-2">
          {modalData.resume && (
            <div className="continue-status-container mb-2 mx-1">
              <div className="col-12 d-flex align-items-center">
                {modalData.type === 'SERIES' && (
                  <h5 className="text-white mb-0 mr-2">
                    T{modalData.resume.episode_season_nr}:E{modalData.resume.episode_nr}
                  </h5>
                )}
                <ProgressBar
                  className={`progress-bar-custom progress-bar-purple mb-0 ${
                    modalData.monetization?.type === PLAYER_TYPES.TVOD && 'progress-bar-paid'
                  }`}
                  variant="danger"
                  now={modalData.resume.completed}
                />
                <p className="small text-white mb-0 ml-2">Quedan {modalData.resume.remaining}</p>
              </div>
            </div>
          )}
          <div className="row my-4 mx-1">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <h2 className="font-weight-bold mb-0">{modalData.name}</h2>
              <div className="d-flex align-items-center">
                <p className="mb-0 mr-3">{durationOrSeason()}</p>
                <p className="mb-0 mr-3">{setGenresArray(modalData.genres)}</p>
                <span
                  className={`badge badge-pill mr-3 ${
                    modalData.monetization?.type === PLAYER_TYPES.TVOD
                      ? 'badge-gold-500'
                      : 'badge-primary'
                  }`}
                >
                  {modalData.rated}
                </span>
                <p className="mb-0">{modalData.release_year}</p>
              </div>
            </div>
            <div className="col-lg-6 modal-buttons">
              {myListButton()}
              {playOrPayButton()}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 mx-auto text-center">
              {modalData.resume && !modalData.monetization && (
                <div className="d-flex justify-content-center mb-3">
                  <p className="small text-white mb-0">¿No deseas continuar viendo?</p>
                  <button
                    type="button"
                    className="text-purple-pink link-button ml-2"
                    onClick={() => removeTitle()}
                  >
                    Quitar
                  </button>
                </div>
              )}
              {modalData.resume && modalData.type === 'SERIES' ? (
                <>
                  <h5>{modalData.resume.episode_name}</h5>
                  <p>{modalData.resume.episode_description}</p>
                </>
              ) : (
                <p>{modalData.description}</p>
              )}
              {modalData?.monetization?.offer && seasonPurchaseCard()}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-12 mx-auto d-flex justify-content-center align-items-center">
              {rateMovie()}
            </div>
          </div>
          {modalData.type === 'SERIES' ? (
            <Tabs
              defaultActiveKey="episodes"
              id="profile-tabs"
              className={`tabs-custom ${
                modalData.monetization?.type === PLAYER_TYPES.TVOD && 'tabs-paid'
              }`}
            >
              <Tab eventKey="episodes" title="Episodios">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-between">
                    <h5 className="ml-3">Episodios</h5>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant={`${
                          modalData.monetization?.type === PLAYER_TYPES.TVOD ? 'black' : 'default'
                        }`}
                        id="dropdown-basic"
                        className={`${
                          modalData.monetization?.type === PLAYER_TYPES.TVOD
                            ? 'text-white'
                            : 'text-purple-pink'
                        }`}
                      >
                        Temporada {season}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {modalData.series.seasons.map(season => (
                          <Dropdown.Item
                            href="#"
                            onClick={() => changeSeason(season)}
                            key={season.id}
                          >
                            Temporada {season.season_nr}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <Episodes
                  modalData={modalData}
                  myEpisodes={myEpisodes}
                  playMedia={playMedia}
                  handlePaymentUrl={handlePaymentUrl}
                  isLogin={isLogin}
                  setShowModal={setShowModal}
                  history={history}
                  PLAYER_TYPES={PLAYER_TYPES}
                  TVOD_STATUS={TVOD_STATUS}
                  setNextPage={setNextPage}
                  episodesHasMore={episodesHasMore}
                />
              </Tab>
              <Tab eventKey="details" title="Detalles">
                <div className="row">
                  <div className="col-sm-10 mx-auto text-center">
                    <p>{modalData.description}</p>
                    {titlesDetails()}
                  </div>
                </div>
              </Tab>
            </Tabs>
          ) : (
            <div className="col-sm-10 mx-auto text-center">{titlesDetails()}</div>
          )}
        </div>
      );
    }
  };

  return render();
};

DetailedSheet.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  modalData: PropTypes.object.isRequired,
  isLogin: PropTypes.bool.isRequired,
  loadingFav: PropTypes.bool.isRequired,
  myList: PropTypes.array.isRequired,
  fetchEpisodes: PropTypes.func.isRequired,
  myEpisodes: PropTypes.array.isRequired,
  myRatingList: PropTypes.array.isRequired,
  loadingRating: PropTypes.bool.isRequired,
  favouriteCall: PropTypes.func.isRequired,
  ratingCall: PropTypes.func.isRequired,
  playMedia: PropTypes.func.isRequired,
  removeTitle: PropTypes.func.isRequired,
  setGenresArray: PropTypes.func.isRequired,
  setNextPage: PropTypes.func.isRequired,
  episodesHasMore: PropTypes.bool.isRequired,
  resetEpisodes: PropTypes.func.isRequired
};

export default DetailedSheet;
