import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  LOADING_PLAYLIST,
  LOADING_VIDEO_DATA,
  SET_CREDITS_START,
  SET_MUX_DATA,
  SET_PLAYLIST,
  SET_RESUME_TIME,
  SET_TITLE_ID,
  SET_TITLE_NAME,
  SET_USER_ADULT,
  SET_VIDEO_DATA
} from '../../store/player/types';
import {
  LOADING_ENTITLEMENT_MEDIA,
  LOADING_SIGN_TOKEN,
  SET_ENTITLEMENT_KEY,
  SET_ENTITLEMENT_MEDIA,
  SET_MEDIA_SIGN_TOKEN,
  SET_ENTITLEMENT_MEDIA_ERROR
} from '../../store/user/types';

export function useClearPlayerStates() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: SET_PLAYLIST, payload: {} });
      dispatch({ type: SET_VIDEO_DATA, payload: {} });
      dispatch({ type: SET_MEDIA_SIGN_TOKEN, payload: '' });
      dispatch({ type: LOADING_VIDEO_DATA, payload: false });
      dispatch({ type: LOADING_PLAYLIST, payload: false });
      dispatch({ type: LOADING_ENTITLEMENT_MEDIA, payload: false });
      dispatch({ type: LOADING_SIGN_TOKEN, payload: false });
      dispatch({ type: SET_MUX_DATA, payload: {} });
      dispatch({ type: SET_TITLE_ID, payload: '' });
      dispatch({ type: SET_TITLE_NAME, payload: '' });
      dispatch({ type: SET_ENTITLEMENT_KEY, payload: false });
      dispatch({ type: SET_ENTITLEMENT_MEDIA, payload: {} });
      dispatch({ type: SET_RESUME_TIME, payload: 0 });
      dispatch({ type: SET_CREDITS_START, payload: 0 });
      dispatch({ type: SET_USER_ADULT, payload: false});
      dispatch({ type: SET_ENTITLEMENT_MEDIA_ERROR, payload: {} });
    };
  }, [dispatch]);
}
