import { takeLatest, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { emailConfirmation, reSendConfirmation } from '../../network';
import {
  SET_EMAIL_CONFIRMATION,
  POST_EMAIL_CONFIRMATION,
  SET_TITLE_CONFIRMATION,
  SET_BUTTON_CONFIRMATION,
  POST_RE_SEND_EMAIL_CONFIRMATION,
  SET_RE_SEND_EMAIL_CONFIRMATION
} from './types';
import { emailConfirmErrors } from '../../utils/saga_errors';

function* postEmailRegistration(action) {
  try {
    yield call(emailConfirmation, action.payload);
    yield put({ type: SET_TITLE_CONFIRMATION, payload: [emailConfirmErrors.CONFIRMATION] });
    yield put({
      type: SET_EMAIL_CONFIRMATION,
      payload: { type: 'CONFIRMATION', message: emailConfirmErrors.CONFIRMATION_MESSAGE }
    });
    yield put({ type: SET_BUTTON_CONFIRMATION, payload: 'REDIRECT' });
  } catch (e) {
    const { response } = e;
    if (response && response.status === 422) {
      if (response.data.error === 'ALREADY_VALIDATED') {
        yield put({
          type: SET_EMAIL_CONFIRMATION,
          payload: { type: 'ALREADY_VALIDATED', message: emailConfirmErrors.ALREADY_VALIDATED }
        });
        yield put({ type: SET_TITLE_CONFIRMATION, payload: ['Ups!, cuenta ya verificada'] });
        yield put({ type: SET_BUTTON_CONFIRMATION, payload: 'REDIRECT' });
      } else if (response.data.error === 'CONFIRM_TOKEN_EXPIRED') {
        yield put({
          type: SET_EMAIL_CONFIRMATION,
          payload: {
            type: 'CONFIRM_TOKEN_EXPIRED',
            message: emailConfirmErrors.CONFIRM_TOKEN_EXPIRED
          }
        });
        yield put({
          type: SET_TITLE_CONFIRMATION,
          payload: [emailConfirmErrors.CONFIRM_TOKEN_EXPIRED]
        });
        yield put({ type: SET_BUTTON_CONFIRMATION, payload: 'RESEND' });
      }
    } else if (response) {
      Sentry.configureScope(scope =>{
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* postReSendEmailRegistration(action) {
  try {
    yield call(reSendConfirmation, action.payload);
    yield put({ type: SET_RE_SEND_EMAIL_CONFIRMATION, payload: '' });
  } catch (e) {
    const { response } = e;
    if (response && response.status === 422) {
      yield put({
        type: SET_RE_SEND_EMAIL_CONFIRMATION,
        payload: emailConfirmErrors.ALREADY_VALIDATED
      });
      yield put({ type: SET_BUTTON_CONFIRMATION, payload: 'REDIRECT' });
    } else if (response) {
      Sentry.configureScope(scope =>{
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* watchEmailConfirmation() {
  yield takeLatest(POST_EMAIL_CONFIRMATION, postEmailRegistration);
}

function* watchReSendEmailConfirmation() {
  yield takeLatest(POST_RE_SEND_EMAIL_CONFIRMATION, postReSendEmailRegistration);
}

export default [watchEmailConfirmation(), watchReSendEmailConfirmation()];
