import { takeLatest, put } from 'redux-saga/effects';
import { SET_ERROR_MESSAGE, POST_ERROR_MESSAGE, RESET_ERROR_MESSAGE, POST_RESET_ERROR } from './types';

function* postError(action) {
  yield put({ type: SET_ERROR_MESSAGE, payload: action.payload });
}

function* watchPostError() {
  yield takeLatest(POST_ERROR_MESSAGE, postError);
}

function* postResetError() {
  try {
    yield put({ type: RESET_ERROR_MESSAGE, payload: '' });
  } catch (e) {
    console.log(e);
  }
}

function* watchPostResetError() {
  yield takeLatest(POST_RESET_ERROR, postResetError);
}

export default [watchPostError(), watchPostResetError()];
