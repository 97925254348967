import TagManager from '../../utils/helpers/gtmDatalayer';
import AdsMacros from '../../utils/helpers/adsMacros';
import adsPosition from '../../utils/constants/adsPosition';
import config from '../../config';

const handlePlayerEvents = ({
  videoData,
  playerRef,
  setCurrentTime,
  setPostResumeOnce,
  playTimeCounter,
  handleErrorPlayer,
  valueAd,
  setIsFullscreen,
  setPositionSuggest,
  setAdsFinished,
  setPlaybackFinished,
  location,
  identityProvider,
  playlist,
  eventsBrightcoveCollector,
  BRIGHTCOVE_EVENTS,
  PROVIDER_TYPES,
  setCanPostEngagement
}) => {
  const { ADS_POSITION } = adsPosition();
  const {
    Paused,
    Playing,
    TimeChanged,
    AdStarted,
    AdFinished,
    PlaybackFinished,
    StallStarted,
    StallEnded,
    Error,
    AdBreakStarted,
    AdBreakFinished,
    Destroy,
    SourceLoaded,
    ViewModeChanged
  } = window.bitmovin.player.core.PlayerEvent;

  let adPosition = null;
  let funnel25 = true;
  let funnel50 = true;
  let funnel75 = true;
  let joinTimeStart = new Date().getTime();
  let joinTimeEnd = 0;
  let joinTime = 0;
  let loadTimeStart = 0;
  let loadTimeEnd = 0;
  let loadTime = 0;
  let playTimeStart = 0;
  let playTimeEnd = 0;
  let playTime = 0;
  let buffer = 0;
  let buffering = 0;
  let interruptions = 0;
  let playerReadyStart = new Date().getTime();
  let playerReadyEnd = 0;
  let resumeInterval = null;
  let suggestInterval = null;
  let hasAds = false;
  let midrollInterval = null;

  const setPlayTime = type => {
    type === 'playTimeStart'
      ? (playTimeStart = new Date().getTime())
      : (playTimeEnd = new Date().getTime());

    if (playTimeStart > 0 && playTimeEnd > 0) {
      playTime = playTimeEnd - playTimeStart;
    }
  };

  const setContentLoadTime = type => {
    type === 'loadTimeStart'
      ? (loadTimeStart = new Date().getTime())
      : (loadTimeEnd = new Date().getTime());

    if (loadTimeStart !== 0 && loadTimeEnd !== 0) {
      loadTime = loadTimeEnd - loadTimeStart;
      TagManager().contentLoadTime({ value: loadTime });
    }
  };

  const setBuffer = data => {
    const { start, end } = data[0];
    buffer = end - start;
    buffering = buffering + buffer;
    TagManager().buffer({ value: buffer });
  };

  const setCounterInterruptions = () => {
    interruptions += 1;
  };

  const destroyPlayer = () => {
    setPlayTime('playTimeEnd');
    const ratio = parseFloat((buffering / playTime).toFixed(3));
    TagManager().playTime({ value: playTime });
    TagManager().interruptions({ value: interruptions });
    TagManager().bufferRatio({ value: ratio });
  };

  const clearIntervals = () => {
    clearInterval(resumeInterval);
    clearInterval(suggestInterval);
    clearInterval(midrollInterval);
  };

  const handlePlaybackInitialized = () => {
    if (!resumeInterval) {
      resumeInterval = setInterval(() => {
        setPostResumeOnce(true);
        setCanPostEngagement(true);
      }, playTimeCounter);
    }
    if (!suggestInterval) {
      suggestInterval = setInterval(() => {
        setPositionSuggest(true);
      }, 1000);
    }
    if (!midrollInterval) {
      midrollInterval = setInterval(() => {
        playerRef.current.ads.schedule(
          AdsMacros({
            data: videoData?.macros,
            location: location?.state,
            position: ADS_POSITION.MIDROLL,
            identityProvider
          })
        );
      }, config().midrollInterval);
    }
    TagManager().firstFrameFunnel();
    eventsBrightcoveCollector({
      eventType: BRIGHTCOVE_EVENTS.VIDEO_VIEW,
      videoData,
      playlist,
      PROVIDER_TYPES
    });
  };

  playerRef.current.on(SourceLoaded, () => {
    playerReadyEnd = new Date().getTime();
    const playerReady = playerReadyEnd - playerReadyStart;
    TagManager().playerReady({ value: playerReady });
    eventsBrightcoveCollector({
      eventType: BRIGHTCOVE_EVENTS.VIDEO_IMPRESSION,
      videoData,
      playlist,
      PROVIDER_TYPES
    });
    clearIntervals();
  });

  playerRef.current.on(AdStarted, e => {
    TagManager().adStartedFunnel({
      adposition: adPosition,
      adtitle: e.ad.data.adTitle,
      title: videoData.titleName,
      title_id: videoData.titleId
    });
    joinTimeEnd = new Date().getTime();
    if (joinTimeStart > 0 && joinTimeEnd > 0) {
      joinTime = joinTimeEnd - joinTimeStart;
      TagManager().joinTime({ value: joinTime });
    }
  });

  playerRef.current.on(AdBreakStarted, e => {
    if (e.adBreak && adPosition === null) {
      const { position } = e.adBreak;
      adPosition = position;
    }
    hasAds = true;
    setAdsFinished(false);
  });

  playerRef.current.on(AdFinished, e => {
    TagManager().adCompleteFunnel({
      adposition: adPosition,
      adtitle: e.ad.data.adTitle,
      title: videoData.titleName,
      title_id: videoData.titleId
    });
    TagManager().conversion({
      adposition: adPosition,
      adtitle: e.ad.data.adTitle,
      title: videoData.titleName,
      title_id: videoData.titleId,
      value: valueAd
    });
    setContentLoadTime('loadTimeStart');
  });

  playerRef.current.on(AdBreakFinished, () => {
    setContentLoadTime('loadTimeEnd');
    setPlayTime('playTimeStart');
    hasAds = false;
    setAdsFinished(true);
    handlePlaybackInitialized();
  });

  playerRef.current.on(Paused, () => {
    setPlayTime('playTimeEnd');
  });

  playerRef.current.on(Playing, e => {
    if (e.issuer === 'api') {
      handlePlaybackInitialized();
    }
  });

  playerRef.current.on(ViewModeChanged, () => {
    const viewMode = playerRef.current.getViewMode();
    viewMode === 'fullscreen' ? setIsFullscreen(true) : setIsFullscreen(false);
  });

  playerRef.current.on(TimeChanged, e => {
    const duration = playerRef.current.getDuration();
    const { time } = e;
    const value = time / duration;
    !hasAds && setCurrentTime(Math.floor(time));
    if (!e.issuer) {
      if (value > 0.25 && funnel25) {
        TagManager().play25Funnel();
        funnel25 = false;
      } else if (value > 0.5 && funnel50) {
        TagManager().play50Funnel();
        funnel50 = false;
      } else if (value > 0.75 && funnel75) {
        TagManager().play75Funnel();
        funnel75 = false;
      }
    }
  });

  playerRef.current.on(PlaybackFinished, () => {
    setPlayTime('playTimeEnd');
    TagManager().playCompleteFunnel();
    setPlaybackFinished(true);
  });

  playerRef.current.on(StallStarted, () => {
    setPlayTime('playTimeEnd');
  });

  playerRef.current.on(StallEnded, () => {
    const buff = playerRef.current.getBufferedRanges();
    setBuffer(buff);
    setCounterInterruptions();
  });

  playerRef.current.on(Error, e => {
    handleErrorPlayer();
    TagManager().errorPlayer(e);
  });

  playerRef.current.on(Destroy, () => {
    clearIntervals();
    destroyPlayer();
  });
};

export default handlePlayerEvents;
