const schema = {
  name: {
    type: 'string',
    empty: false,
    optional: false,
    messages: {
      required: 'Debes ingresar tu nombre.',
      string: 'Por favor ingresar nombre de usuario valido (letras y numeros).',
      stringEmpty: 'Debes ingresar tu nombre.'
    }
  },
  email: {
    type: 'email',
    max: 100,
    optional: false,
    messages: {
      emailEmpty: 'El email es obligatorio.',
      email: 'El correo tiene un formato incorrecto.',
      string: 'El email es obligatorio.',
      stringMax: 'El correo electrónico supera el largo admitido.'
    }
  },
  password: {
    type: 'string',
    min: 6,
    max: 100,
    optional: false,
    pattern: '(?=.*?[A-Z])(?=.*?[0-9])',
    messages: {
      required: 'Debes ingresar una contraseña',
      string: 'Debes ingresar una combinación de letras y números.',
      stringMin: 'Debes ingresar al menos 6 carácteres.',
      stringMax: 'Su contraseña es muy larga.',
      stringPattern: 'La contraseña debe contener mayúsculas y números'
    }
  }
};

export default schema;
