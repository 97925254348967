import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useImageProperties from '../../../utils/hooks/useImageProperties';

const ContinueItem = ({ assets, title, fallbackImage, handleShow, tileHoverStatus, playMedia }) => {
  const { source, altText, fallbackClass, handleClick, showFallbackText } = useImageProperties(
    assets,
    title,
    fallbackImage,
    isMobile,
    handleShow
  );

  return (
    <>
      <div className="tile-continue-watching">
        <div className="image-container">
          <img className={fallbackClass} src={source} alt={altText} onClick={handleClick} />
        </div>
        {showFallbackText && (
          <div className="fallbackContainer">
            <span className="fallbackText">{title.name}</span>
          </div>
        )}
        {tileHoverStatus && (
          <Button className="btn-play btn-blur" size="sm" onClick={playMedia(title)}>
            <i className="fas fa-play fa-lg"></i>
          </Button>
        )}
      </div>
      <div className="row position-relative">
        <div className="col-md-8 mx-auto">
          <ProgressBar
            className="progress-bar-custom progress-bar-purple mt-2 mb-2"
            variant="danger"
            now={title.resume.completed}
          />
        </div>
        {tileHoverStatus && (
          <div className="position-absolute w-100 d-flex justify-content-end">
            <Button
              variant="link"
              className="text-white btn btn-info-tile"
              size="sm"
              onClick={handleShow}
            >
              <i className="far fa-info-circle fa-2x"></i>
            </Button>
          </div>
        )}
      </div>
      {tileHoverStatus && (
        <div className="position-absolute w-100 text-center text-resume">
          <p className="small text-white mb-0">Quedan {title.resume.remaining}</p>
        </div>
      )}
    </>
  );
};

ContinueItem.propTypes = {
  assets: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  fallbackImage: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  tileHoverStatus: PropTypes.bool.isRequired,
  playMedia: PropTypes.func.isRequired
};

export default ContinueItem;
