import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import { Slider } from '../';
import BgCategories from './styles';
import Skeleton from 'react-loading-skeleton';
import { isMobile } from 'react-device-detect';

const CategoryBrand = ({
  id,
  name,
  sponsor,
  description,
  background_url,
  opacity,
  type,
  orientation_tiles,
  collectionIndex
}) => {
  const [titles, setTitles] = useState([]);
  const collectionTitles = useSelector(({ player }) => player.collectionsTitles);

  useEffect(() => {
    const collection = collectionTitles.find(collection => collection.id === id);
    if (collection && collection.items.length > 0) {
      setTitles(collection.items);
    }
  }, [collectionTitles, id]);

  const renderBranded = () => {
    return (
      <div className="d-flex">
        {sponsor && sponsor.cover_url && (
          <div className="branded-banner">
            <img src={sponsor.cover_url} alt={sponsor.owner} />
          </div>
        )}
        {name && description && (
          <div className="branded-container-info">
            <div className="branded-title">
              <h3>{name}</h3>
              <p>{description}</p>
            </div>
            <div className="branded-logo-container">
              {sponsor && sponsor.logo_url && (
                <div className="d-flex align-items-center mb-3">
                  <p className="mt-3 mr-2">Por</p>
                  <div className="branded-logo">
                    <img src={sponsor.logo_url} alt={sponsor.owner} />
                  </div>
                </div>
              )}
              {sponsor && sponsor.button_text && (
                <Button
                  className="btn-branded"
                  style={{
                    backgroundColor: `#${sponsor.button_color}`,
                    borderColor: `#${sponsor.button_color}`
                  }}
                  onClick={() => window.open(sponsor.button_url, '_blank')}
                >
                  {sponsor.button_text}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const SkeletonBranded = () => (
    <div className="container-fluid overflow-hidden py-2">
      <Row>
        <Col xs="12" sm="12" md="6" lg="5" xl="4" className="skeleton-branded-container">
          <div className="d-flex">
            <div className="skeleton-branded-banner">
              <Skeleton width={120} height={280} />
            </div>
            <div className="skeleton-branded-container-info">
              <div className="skeleton-branded-title">
                <Skeleton className="skeleton-info-title" height={25} />
                <Skeleton className="skeleton-info-title" height={25} />
                <Skeleton className="skeleton-info-description" height={20} />
                <Skeleton className="skeleton-info-description" height={20} />
              </div>
              <div className="skeleton-branded-logo">
                <Skeleton className="skeleton-info-logo" height={40} />
                <Skeleton className="skeleton-info-button" height={40} />
              </div>
            </div>
          </div>
        </Col>
        <Col xs="12" sm="12" md="6" lg="7" xl="8" className="d-flex">
          {[...Array(7)].map((_, i) => (
            <Skeleton key={i} className="skeleton-box-branded mx-1" />
          ))}
        </Col>
      </Row>
    </div>
  );

  const render = () => {
    if (titles.length < 1) {
      return <SkeletonBranded />;
    }
    return (
      <BgCategories background={background_url} type={type} brandedOpacity={opacity}>
        <div className="container-fluid container-category py-3">
          {isMobile ? (
            <Row>
              <Col xs="12" className="branded-container">
                {renderBranded()}
              </Col>
              <Col xs="12">
                <Slider collectionName={name} branded={true}>
                  {titles.map((title, index) => (
                    <Slider.Item
                      key={title.id}
                      title={title}
                      collectionIndex={collectionIndex}
                      titleIndex={index}
                      collectionName={name}
                      origin="discovery"
                      branded={true}
                      orientationTiles={orientation_tiles}
                    />
                  ))}
                </Slider>
              </Col>
            </Row>
          ) : (
            <div className="branded-carousel">
              <Slider collectionName={name} branded={true}>
                {renderBranded()}
                {titles.map((title, index) => (
                  <Slider.Item
                    key={title.id}
                    title={title}
                    collectionIndex={collectionIndex}
                    titleIndex={index}
                    collectionName={name}
                    origin="discovery"
                    branded={true}
                    orientationTiles={orientation_tiles}
                  />
                ))}
              </Slider>
            </div>
          )}
        </div>
      </BgCategories>
    );
  };

  return render();
};

CategoryBrand.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sponsor: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  background_url: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  orientation_tiles: PropTypes.string.isRequired,
  collectionIndex: PropTypes.number.isRequired
};

export default CategoryBrand;
