import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import NoPackageImg from '../../images/no-package.svg';

const IdpAuthorizationErrorView = () => {
  const { idp } = useParams();
  const location = useLocation();
  const [backUrl, setBackUrl] = useState('/');
  const idpWebsite = {
    vtr: 'https://vtr.com/',
    vtr2: 'https://vtr.com/',
    prensatoolbox: 'https://www.toolboxtve.com/',
  };

  useEffect(() => {
    const back = new URLSearchParams(location.search).get('back');
    if (back) {
      setBackUrl(back);
    }
  }, [location]);

  return (
    <div className="container container-pt-custom">
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <img src={NoPackageImg} className="mb-5" alt="" />
          <h2 className='mb-4'>Lo sentimos. No tienes acceso a Riivi con tu paquete de suscripción.</h2>
          <p className='mb-5'>Ponte en contacto con tu proveedor y consíguelo hoy mismo.</p>
          <div className="row justify-content-center">
            <div className="col-6 col-lg-4">
              <Button
                as={Link}
                to={backUrl}
                variant='outline-light'
                size='lg'
                block
              >
                Volver
              </Button>
            </div>
            {idpWebsite[idp] && <div className="col-6 col-lg-4">
              <Button
                variant='primary'
                size='lg'
                block
                onClick={() => {
                  window.open(idpWebsite[idp], '_blank');
                }}
              >
                Contratar
              </Button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdpAuthorizationErrorView;
