import React from 'react';
import PropTypes from 'prop-types';

const SlideButton = ({ onClick, type }) => (
  <button className={`slide-button slide-button--${type}`} onClick={onClick}>
    <span>
      <i className="fal fa-angle-down fa-lg"></i>
    </span>
  </button>
);

SlideButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default SlideButton;
