import { useEffect, useState, useCallback } from 'react';

export function useMatchEntitlements({
  entitlementsList = [],
  collectionTitles = [],
  isLogin,
  continueWatching = [],
  titleId,
  source
}) {
  const [titles, setTitles] = useState([]);

  const sources = {
    categoryTvod: 'categoryTvod',
    explore: 'explore'
  };

  const indexArrayByKey = useCallback(
    (array, key) =>
      array.reduce((acc, curr) => {
        acc[curr[key]] = curr;
        return acc;
      }, {}),
    []
  );

  const mergeEntitlements = useCallback(
    (titles, entitlements) => {
      const indexedEntitlements = indexArrayByKey(entitlements, 'title_id');
      return titles.map(item => ({
        ...item,
        entitlement: indexedEntitlements[item.id]
      }));
    },
    [indexArrayByKey]
  );

  const mergeContinueWatching = useCallback(
    (titles, entitlements, continueWatching) => {
      const indexedEntitlements = indexArrayByKey(entitlements, 'title_id');
      const indexedContinueWatching = indexArrayByKey(continueWatching, 'id');
      return titles.map(item => ({
        ...item,
        entitlement: indexedEntitlements[item.id],
        resume: indexedContinueWatching[item.id]?.resume
      }));
    },
    [indexArrayByKey]
  );

  useEffect(() => {
    if (source === sources.categoryTvod) {
      const collection = collectionTitles.find(collection => collection.id === titleId);

      if (collection?.items?.length > 0) {
        let mergedTitles = collection.items;
        if (isLogin && entitlementsList?.length > 0) {
          mergedTitles = mergeContinueWatching(
            collection.items,
            entitlementsList,
            continueWatching
          );
        }
        setTitles(mergedTitles);
      }
    }
  }, [
    collectionTitles,
    titleId,
    isLogin,
    entitlementsList,
    continueWatching,
    mergeContinueWatching,
    source,
    sources.categoryTvod
  ]);

  useEffect(() => {
    if (source === sources.explore) {
      let mergedTitles = collectionTitles;

      if (isLogin && entitlementsList?.length > 0) {
        mergedTitles = mergeEntitlements(collectionTitles, entitlementsList);
      }
      setTitles(mergedTitles);
    }
  }, [collectionTitles, entitlementsList, mergeEntitlements, isLogin, source, sources.explore]);

  return { titles };
}
