import React from 'react';
import PropTypes from 'prop-types';

const Navigation = ({ explore, amount, current = 0, branded }) => {
  return (
    <div className={`container-dots ${explore || Number.isNaN(amount) ? 'd-none' : ''}`}>
      <div className="dots">
        {[...Array(amount)].map((_, idx) => (
          <button key={idx} className={`${branded ? 'dot-branded' : 'dot'} ${current === idx ? 'active' : ''}`} />
        ))}
      </div>
    </div>
  );
};

Navigation.propTypes = {
  explore: PropTypes.bool,
  amount: PropTypes.number.isRequired,
  current: PropTypes.number,
  branded: PropTypes.bool
};

export default Navigation;
