import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FormControl, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
import { POST_RECAPTCHA, SET_RECAPTCHA } from '../../store/recaptcha/types';
import { POST_REGISTER_SERVICE, POST_REGISTER_RRSS } from '../../store/register/types';
import { POST_ERROR_MESSAGE } from '../../store/error/types';
import validator from '../../utils/validator';
import { registerSchema } from '../../utils/validator/schemas';
import TagManager from '../../utils/helpers/gtmDatalayer';
import { SET_TOAST_NOTIFICATION } from '../../store/notification/types';

const signupEmail = () => {
  TagManager().signupEmail();
};

const RegisterForm = props => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [registerForm, setRegisterForm] = useState({
    name: '',
    email: '',
    password: ''
  });
  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    password: '',
    recaptcha: ''
  });

  const {
    isRecaptchaValid,
    verifyRecaptcha,
    expireRecaptcha,
    registerError,
    sendRegistration,
    registeredRRSS,
    dispatchToast,
    resetRegisteredRRSS
  } = props;

  useEffect(() => {
    if (registeredRRSS) {
      dispatchToast({
        title: '¡Listo!',
        body: `Se ha vinculado una contraseña a tu cuenta.
        Ahora puedes optar por iniciar sesión con contraseña.`,
        type: 'success',
        delay: 15000
      });
      setTimeout(() => {
        resetRegisteredRRSS();
      }, 15000);
    };
  });

  const handleInputChange = event => {
    setRegisterForm({ ...registerForm, [event.target.name]: event.target.value });
    setValidationErrors({ ...validationErrors, [event.target.name]: '' });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const errors = validator(registerSchema, registerForm);

    if (!isRecaptchaValid) {
      errors.push({ field: 'recaptcha', message: 'Por favor contestar el captcha' });
    }
    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationErrors(messages);
      return false;
    }
    sendRegistration({ ...registerForm, history });
  };

  const handleReCaptchaChange = response => {
    TagManager().checkboxCaptcha();
    validationErrors.recaptcha = '';
    if (response) {
      verifyRecaptcha({ response });
    } else {
      expireRecaptcha();
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputEvents = event => {
    if (event.target.name === 'name') {
      TagManager().inputNameSignup();
    }
    if (event.target.name === 'email') {
      TagManager().inputMailSignup();

    }
    if (event.target.name === 'password') {
      TagManager().inputPasswordSignup();

    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div
        style={{
          justifyContent: 'start',
          alignItems: 'start'
        }}
      >
        <Form.Group className="form-label-group">
          <FormControl
            name="name"
            type="text"
            placeholder="Ingresa tu nombre"
            onChange={handleInputChange}
            isInvalid={validationErrors.name}
            onClick={inputEvents}
          />
          <Form.Label>Nombre</Form.Label>
          <Form.Control.Feedback type="invalid">{validationErrors.name}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-label-group">
          <FormControl
            name="email"
            type="text"
            placeholder="Ingresa tu correo electrónico"
            autoComplete="username"
            onChange={handleInputChange}
            isInvalid={validationErrors.email}
            onClick={inputEvents}
          />
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-label-group">
          <FormControl
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Ingresa una contraseña"
            autoComplete="new-password"
            onChange={handleInputChange}
            isInvalid={validationErrors.password}
            className="form-password"
            onClick={inputEvents}
          />
          <Button className="btn-pass border-0" variant="link" onClick={toggleShowPassword}>
            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
          </Button>
          <Form.Label>Crea tu contraseña</Form.Label>
          <Form.Text className="text-white text-helper">
            La contraseña debe tener al menos una mayúscula y un número
          </Form.Text>
          <Form.Control.Feedback type="invalid">{validationErrors.password}</Form.Control.Feedback>
        </Form.Group>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ReCAPTCHA
          sitekey={config().recaptcha.key}
          size="normal"
          onChange={handleReCaptchaChange}
          onExpired={expireRecaptcha}
          theme="dark"
        />
      </div>
      <div
        className="invalid-feedback m-3"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {validationErrors.recaptcha !== '' ? validationErrors.recaptcha : ''}
        {registerError}
      </div>
      <Button variant="primary" type="submit" className="btn-block mb-4" size="lg" onClick={signupEmail}>
        Crea tu cuenta
      </Button>
    </form>
  );
};

RegisterForm.propTypes = {
  isRecaptchaValid: PropTypes.bool.isRequired,
  registerError: PropTypes.array.isRequired,
  verifyRecaptcha: PropTypes.func.isRequired,
  expireRecaptcha: PropTypes.func.isRequired,
  sendRegistration: PropTypes.func.isRequired,
  displayError: PropTypes.func.isRequired,
  registeredRRSS: PropTypes.bool.isRequired,
  dispatchToast: PropTypes.func.isRequired,
  resetRegisteredRRSS: PropTypes.func.isRequired
};

const stateToProps = state => ({
  isRecaptchaValid: state.recaptcha.isValid,
  registerError: state.error.message,
  registeredRRSS: state.registerservice.registeredRRSS
});

const actionsToProps = dispatch => ({
  verifyRecaptcha: payload => dispatch({ type: POST_RECAPTCHA, payload }),
  expireRecaptcha: () => dispatch({ type: SET_RECAPTCHA, payload: false }),
  sendRegistration: payload => dispatch({ type: POST_REGISTER_SERVICE, payload }),
  displayError: payload => dispatch({ type: POST_ERROR_MESSAGE, payload }),
  dispatchToast: payload => dispatch({ type: SET_TOAST_NOTIFICATION, payload }),
  resetRegisteredRRSS: payload => dispatch({ type: POST_REGISTER_RRSS, payload })
});

export default connect(stateToProps, actionsToProps)(RegisterForm);
