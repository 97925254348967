import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'react-bootstrap';
import { Slider } from '..';
import Skeleton from 'react-loading-skeleton';
import PlayerTvod from './PlayerTvod';
import PropTypes from 'prop-types';
import { FETCH_ENTITLEMENTS_LIST, SET_TOGGLE_CATEGORY_TVOD } from '../../store/user/types';
import { useScroll } from '../../utils/helpers/scrollToPremier';
import { useMatchEntitlements } from '../../utils/hooks/useMatchEntitlements';

const CategoryTvod = ({
  id,
  collectionIndex,
  orientation_tiles,
  isLogin,
  collectionTitles,
  getEntitlementsList,
  entitlementsList,
  continueWatching,
  setToggleCategoryTvod,
  toggleCategoryTvod,
  name,
  description,
  sponsor
}) => {
  const { scrollRef } = useScroll();
  const { titles } = useMatchEntitlements({
    entitlementsList,
    collectionTitles,
    isLogin,
    continueWatching,
    titleId: id,
    source: 'categoryTvod'
  });

  useEffect(() => {
    isLogin && getEntitlementsList();
  }, [getEntitlementsList, isLogin]);

  const toggler = () => {
    setToggleCategoryTvod(!toggleCategoryTvod);
  };

  const renderInfo = () => {
    return (
      <div id="ticket" className="container-content text-center text-lg-left mb-5">
        <p className="text-uppercase text-gold-500 mb-1">
          <i className="fas fa-ticket-alt" /> Compra tu ticket
        </p>
        <h1>{name}.</h1>
        <p>{description}.</p>
        <Button
          className={
            toggleCategoryTvod ? 'btn-content btn-focus-0' : 'btn-content-disabled btn-focus-0'
          }
          onClick={toggler}
        >
          {toggleCategoryTvod ? (
            <span>{sponsor.button_text}</span>
          ) : (
            <span>
              <i className="fas fa-eye-slash mr-2" />
              Ocultar contenido
            </span>
          )}
        </Button>
      </div>
    );
  };

  const SkeletonTvod = () => (
    <div className="container-fluid overflow-hidden my-5 py-2">
      <Row>
        <Col xs="12" md="5" lg="4" className="skeleton-info-tvod">
          <Skeleton width={230} height={40} />
          <Skeleton width={150} height={30} />
          <Skeleton width={150} height={35} />
          <Skeleton width={150} height={35} />
          <Skeleton className="my-3" width={200} height={45} />
        </Col>
        <Col xs="12" md="7" lg="8" className="d-flex justify-content-center">
          <Skeleton className="skeleton-player-tvod" />
        </Col>
      </Row>
    </div>
  );

  const render = () => {
    if (titles.length < 1) {
      return <SkeletonTvod />;
    }
    return (
      <div id="carousel-custom">
        <div className="bg-slider">
          <div className="container-fluid container-category overflow-hidden">
            <div ref={scrollRef} className="tvod-carousel">
              <Row className="justify-content-lg-between">
                <Col xs="12" lg="3" className="align-items-center justify-content-center d-flex">
                  <div className="content mt-4">{renderInfo()}</div>
                </Col>
                <Col xs="12" lg="9" className="tvod-slider-carousel">
                  <div className="tvod-carousel py-5">
                    <div className="position-relative">
                      <div
                        style={{
                          opacity: toggleCategoryTvod ? '1' : '0',
                          transition: toggleCategoryTvod ? 'opacity 1s' : 'opacity 1s'
                        }}
                        className="player-tvod"
                      >
                        <div id="player-tvod-container" className="mr-2">
                          <PlayerTvod name={name} sponsor={sponsor} />
                        </div>
                      </div>
                      <div className="container-slider-tvod">
                        <div
                          style={{
                            transform: toggleCategoryTvod ? 'translateX(200%)' : 'translateX(0)',
                            transition: toggleCategoryTvod ? 'all .8s' : 'all .8s'
                          }}
                        >
                          {
                            <div className={`slider-tvod ${orientation_tiles.toLowerCase()}`}>
                              <Slider
                                collectionName="TVOD"
                                orientationTiles={orientation_tiles.toLowerCase()}
                                tvod={true}
                              >
                                {titles.map((title, index) => (
                                  <Slider.Item
                                    key={title.id}
                                    title={title}
                                    collectionIndex={collectionIndex}
                                    titleIndex={index}
                                    collectionName="TVOD"
                                    origin="discovery"
                                    tvod={true}
                                    owned={false}
                                    orientationTiles={orientation_tiles.toLowerCase()}
                                    isLogin={isLogin}
                                  />
                                ))}
                              </Slider>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

CategoryTvod.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  sponsor: PropTypes.object.isRequired,
  isLogin: PropTypes.bool,
  collectionTitles: PropTypes.array.isRequired,
  getEntitlementsList: PropTypes.func.isRequired,
  entitlementsList: PropTypes.array.isRequired,
  continueWatching: PropTypes.array.isRequired,
  setToggleCategoryTvod: PropTypes.func.isRequired,
  toggleCategoryTvod: PropTypes.bool.isRequired
};

const stateToProps = ({ user, player }) => ({
  isLogin: user.isLogedIn,
  collectionTitles: player.collectionsTitles,
  entitlementsList: user.entitlementsList,
  continueWatching: user.continueWatching,
  toggleCategoryTvod: user.toggleCategoryTvod
});

const actionToProps = dispatch => ({
  getEntitlementsList: payload => dispatch({ type: FETCH_ENTITLEMENTS_LIST, payload }),
  setToggleCategoryTvod: payload => dispatch({ type: SET_TOGGLE_CATEGORY_TVOD, payload })
});

export default connect(stateToProps, actionToProps)(CategoryTvod);
