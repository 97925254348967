import {
  SET_USER_PROFILE,
  SET_DELETE_ACCOUNT,
  SET_USER_NAME,
  SET_USER_EMAIL,
  SET_ISLOGEDIN,
  SET_PROFILE_PIC,
  SET_LOADING_FAVOURITE,
  SET_FAVOURITE_LIST,
  SET_FAVORITES_MEDIA,
  TITLE_REMOVED,
  SET_RATING_LIST,
  SET_LOADING_RATING,
  SET_CONTINUE_WATCHING,
  LOADING_CONTINUE_WATCHING,
  SET_RESUME_DATA,
  SET_ENTITLEMENTS,
  LOADING_ENTITLEMENTS,
  LOADING_FAVORITES,
  SET_ENTITLEMENTS_LIST,
  SET_ENTITLEMENT_MEDIA,
  SET_ENTITLEMENT_MEDIA_ERROR,
  SET_ENTITLEMENT_KEY,
  LOADING_ENTITLEMENT_MEDIA,
  SET_TOGGLE_CATEGORY_TVOD,
  SET_MEDIA_SIGN_TOKEN,
  LOADING_SIGN_TOKEN
} from './types';

const initialState = {
  userName: '',
  userEmail: '',
  createdAt: '',
  isLogedIn: false,
  profilePic: '',
  idp: '',
  accountDeleted: false,
  loading: false,
  favouriteList: [],
  favoritesMedia: [],
  isRemoved: false,
  removedState: false,
  ratingList: [],
  loadingRating: false,
  continueWatching: [],
  loadingContinueWatching: false,
  resumeData: {},
  entitlements: [],
  loadingEntitlements: false,
  loadingFavorites: false,
  entitlementsList: [],
  entitlementMedia: {},
  entitlementMediaError: {},
  entitlementKey: false,
  loadingEntitlementMedia: false,
  toggleCategoryTvod: false,
  mediaSignToken: '',
  loadingSignToken: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
  case SET_USER_PROFILE:
    return { ...state, userName: payload.firstName, userEmail: payload.email, idp: payload.idp, createdAt: payload.createdAt };
  case SET_DELETE_ACCOUNT:
    return { ...state, accountDeleted: payload };
  case SET_USER_NAME:
    return { ...state, userName: payload };
  case SET_USER_EMAIL:
    return { ...state, userEmail: payload };
  case SET_ISLOGEDIN:
    return { ...state, isLogedIn: payload };
  case SET_PROFILE_PIC:
    return { ...state, profilePic: payload };
  case SET_LOADING_FAVOURITE:
    return { ...state, loading: payload };
  case SET_FAVOURITE_LIST:
    return { ...state, favouriteList: payload };
  case SET_FAVORITES_MEDIA:
    return { ...state, favoritesMedia: payload };
  case TITLE_REMOVED:
    return { ...state, isRemoved: payload };
  case SET_RATING_LIST:
    return { ...state, ratingList: payload };
  case SET_LOADING_RATING:
    return { ...state, loadingRating: payload };
  case SET_CONTINUE_WATCHING:
    return { ...state, continueWatching: payload };
  case LOADING_CONTINUE_WATCHING:
    return { ...state, loadingContinueWatching: payload };
  case SET_RESUME_DATA:
    return { ...state, resumeData: payload };
  case SET_ENTITLEMENTS:
    return { ...state, entitlements: payload };
  case LOADING_ENTITLEMENTS:
    return { ...state, loadingEntitlements: payload };
  case LOADING_FAVORITES:
    return { ...state, loadingFavorites: payload };
  case SET_ENTITLEMENTS_LIST:
    return { ...state, entitlementsList: payload };
  case SET_ENTITLEMENT_MEDIA:
    return { ...state, entitlementMedia: payload };
  case SET_ENTITLEMENT_MEDIA_ERROR:
    return { ...state, entitlementMediaError: payload };
  case SET_ENTITLEMENT_KEY:
    return { ...state, entitlementKey: payload };
  case LOADING_ENTITLEMENT_MEDIA:
    return { ...state, loadingEntitlementMedia: payload };
  case SET_TOGGLE_CATEGORY_TVOD:
    return { ...state, toggleCategoryTvod: payload };
  case SET_MEDIA_SIGN_TOKEN:
    return { ...state, mediaSignToken: payload };
  case LOADING_SIGN_TOKEN:
    return { ...state, loadingSignToken: payload };
  default:
    return state;
  }
};
