import { takeLatest, put, call, takeEvery, all, retry } from 'redux-saga/effects';
import providerTypes from '../../utils/constants/providerTypes';
import * as Sentry from '@sentry/react';
import {
  fetchCollections,
  fetchCollectionTitles,
  movieDetails,
  featuredMedia,
  exploreMovies,
  fetchVideoData,
  storeResumeTitle,
  storeResumeSeries,
  getResumeTime,
  browseCollections,
  exploreGenre,
  fetchEpisodes,
  suggestTitle,
  healthCheck,
  fetchTrailerSource,
  fetchPlaylist
} from '../../network';
import {
  FETCH_COLLECTIONS,
  SET_ERROR_CATEGORY_LIST,
  SET_ERROR_CATEGORY_MOVIES,
  SET_COLLECTIONS,
  SET_COLLECTION_TITLES,
  FETCH_FEATURED_TITLE,
  SET_FEATURED_MEDIA,
  SET_MEDIA_INFO,
  FETCH_MOVIE_METADATA,
  SET_FEATURED_MEDIA_DETAILED_INFO,
  FETCH_EXPLORE_MOVIES,
  SET_EXPLORE_MEDIA,
  SET_EXPLORE_HAS_MORE,
  FETCH_PLAYLIST,
  FETCH_VIDEO_DATA,
  SET_COLLECTION_NAME,
  SET_TITLE_NAME,
  SET_TITLE_ID,
  POST_RESUME_TIME,
  FETCH_RESUME_TIME,
  SET_RESUME_TIME,
  FETCH_BROWSE,
  SET_BROWSE,
  FETCH_EXPLORE_GENRE,
  FETCH_EPISODES,
  SET_EPISODES,
  FETCH_SUGGEST,
  SET_SUGGEST,
  SET_CREDITS_START,
  SET_ERROR_SUGGEST,
  FETCH_SERVICE_AVAILABLE,
  SET_SERVICE_AVAILABLE,
  FETCH_PREVIOUS_NEXT_EPISODE,
  SET_PREVIOUS_NEXT_EPISODE,
  SET_TENANCY,
  SET_PLAYLIST,
  SET_VIDEO_DATA,
  SET_MUX_DATA,
  GET_MODAL_DATA,
  SET_MODAL_DATA,
  SET_MODAL_ERROR,
  RESUME_TIME_SENDED,
  FETCH_TRAILER_SOURCE,
  SET_TRAILER_SOURCE,
  LOADING_PLAYLIST,
  LOADING_VIDEO_DATA,
  SET_ERROR_PLAYLIST,
  SET_EPISODES_HAS_MORE
} from './types';
import { playerErrors } from '../../utils/saga_errors';
import appConfig from '../../config';
const config = appConfig();
const { otteraUrlBase } = config;
const { PROVIDER_TYPES } = providerTypes();

function* fetchAndStoreCollectionTitles(collection) {
  try {
    const titlesResponse = yield call(fetchCollectionTitles, collection.id);
    yield put({
      type: SET_COLLECTION_TITLES,
      payload: { id: collection.id, items: titlesResponse.data.items }
    });
  } catch (e) {
    yield put({ type: SET_ERROR_CATEGORY_LIST, payload: playerErrors.CATEGORY_NOT_FOUND });
    Sentry.configureScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal);
      Sentry.captureException(e);
    });
  }
}

function* getCollections() {
  try {
    const response = yield call(fetchCollections);
    const { collections } = response.data;
    yield put({ type: SET_COLLECTIONS, payload: collections });
    if (collections.length > 0) {
      yield all(collections.map(collection => call(fetchAndStoreCollectionTitles, collection)));
    }
  } catch (e) {
    yield put({ type: SET_ERROR_CATEGORY_LIST, payload: playerErrors.CATEGORY_NOT_FOUND });
    Sentry.configureScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal);
      Sentry.captureException(e);
    });
  }
}

function* getExploreMovies(action) {
  try {
    const response = yield call(exploreMovies, action.payload);
    const { items, name } = response.data;

    yield put({
      type: SET_COLLECTION_NAME,
      payload: name
    });
    yield put({
      type: SET_EXPLORE_MEDIA,
      payload: items
    });
    if (items.length > 0) {
      yield put({
        type: SET_EXPLORE_HAS_MORE,
        payload: true
      });
    } else {
      yield put({
        type: SET_EXPLORE_HAS_MORE,
        payload: false
      });
    }
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'CATEGORY_NOT_FOUND') {
      yield put({ type: SET_ERROR_CATEGORY_MOVIES, payload: playerErrors.CATEGORY_NOT_FOUND });
    } else {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(e);
      });
    }
  }
}

function* getExploreGenre(action) {
  try {
    const response = yield call(exploreGenre, action.payload);
    const { items, name } = response.data;

    yield put({
      type: SET_COLLECTION_NAME,
      payload: name
    });
    yield put({
      type: SET_EXPLORE_MEDIA,
      payload: items
    });
    if (items.length > 0) {
      yield put({
        type: SET_EXPLORE_HAS_MORE,
        payload: true
      });
    } else {
      yield put({
        type: SET_EXPLORE_HAS_MORE,
        payload: false
      });
    }
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'CATEGORY_NOT_FOUND') {
      yield put({ type: SET_ERROR_CATEGORY_MOVIES, payload: playerErrors.CATEGORY_NOT_FOUND });
    } else {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(e);
      });
    }
  }
}

function* getPlaylist(action) {
  try {
    const { url, provider, hasDrm } = action.payload;
    yield put({ type: LOADING_PLAYLIST, payload: false });
    const response = yield retry(3, 5000, fetchPlaylist, url);

    let sources = [];
    let tracks = [];
    let additionalProperties = {};

    if (provider === PROVIDER_TYPES.JWPLAYER) {
      sources = response.data.playlist[0].sources;
      tracks = response.data.playlist[0].tracks;
    } else if (provider === PROVIDER_TYPES.BRIGHTCOVE) {
      sources = response.data.sources;
      tracks = response.data.text_tracks;
    }

    const mapTrack = ({ file, kind, label, src }) => {
      return {
        label: kind === 'thumbnails' ? kind : label,
        src: provider === PROVIDER_TYPES.JWPLAYER ? file : src,
        kind: kind === 'thumbnails' ? 'metadata' : kind,
        default: true,
        srclang: kind === 'captions' ? 'es' : undefined
      };
    };
    const mapSubtitle = ({ file, label, kind }) => {
      return {
        id: `sub${label.substring(0, 2)}`,
        lang: label.substring(0, 2).toLowerCase(),
        label,
        url: file,
        kind
      };
    };

    additionalProperties = {
      thumbnailTrack: {
        url:
          tracks.length > 0
            ? provider === PROVIDER_TYPES.JWPLAYER
              ? tracks[0].file
              : tracks[0].src
            : ''
      },
      textTracks: tracks.length > 0 ? tracks.map(mapTrack) : [],
      thumbnailsUrl:
        tracks.length > 0
          ? provider === PROVIDER_TYPES.JWPLAYER
            ? `${tracks[0].file.split('.vtt')[0]}.jpg`
            : `${tracks[0].src.split('.webvtt')[0]}.jpg`
          : '',
      subtitles: tracks.length > 0 ? tracks.filter(t => t.kind === 'captions').map(mapSubtitle) : []
    };

    if (provider === PROVIDER_TYPES.BRIGHTCOVE) {
      additionalProperties = {
        ...additionalProperties,
        duration: response.data.duration
      };
    }

    const getPayloadData = (sources, providerType) => {
      const getBitrate = source => {
        if (providerType === PROVIDER_TYPES.JWPLAYER) {
          return source.bitrate || source.encoding_rate;
        }
        return source.avg_bitrate;
      };

      const getLabel = (source, type) => {
        if (providerType === PROVIDER_TYPES.JWPLAYER) {
          return type ? source.label : `${source.height}p`;
        }
        return `${source.height}p`;
      };

      return {
        hls: sources
          .filter(
            ({ type, file, src }) =>
              (type === 'application/vnd.apple.mpegurl' || type === 'application/x-mpegURL') &&
              (file || src)
          )
          .map(s => s.file || s.src)
          .shift(),
        progressive: sources
          .filter(({ type, container }) => type === 'video/mp4' || container === 'MP4')
          .map(source => ({
            url: source.file || source.src,
            type: source.type || `video/${source.container.toLowerCase()}`,
            bitrate: getBitrate(source),
            label: getLabel(source, source.type)
          }))
      };
    };

    let payload = {};

    if (provider === PROVIDER_TYPES.JWPLAYER) {
      if (hasDrm) {
        payload = {
          dash: sources.find(s => s.type === 'application/dash+xml')?.file || '',
          drm: {
            widevine: {
              LA_URL: sources.find(s => s.drm?.widevine)?.drm.widevine.url || ''
            },
            playready: {
              LA_URL: sources.find(s => s.drm?.playready)?.drm.playready.url || ''
            }
          }
        };
      } else {
        payload = getPayloadData(sources, PROVIDER_TYPES.JWPLAYER);
      }
    } else if (provider === PROVIDER_TYPES.BRIGHTCOVE) {
      payload = getPayloadData(sources, PROVIDER_TYPES.JWPLAYER);
    }

    payload = {
      ...payload,
      ...additionalProperties
    };

    yield put({ type: SET_PLAYLIST, payload });
    yield put({ type: LOADING_PLAYLIST, payload: true });
  } catch (error) {
    const { response } = error;
    yield put({ type: SET_ERROR_PLAYLIST, payload: response.data.error });
    if (response && response.status !== 401) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(error);
      });
    }
  }
}

function* getVideoData(action) {
  try {
    yield put({ type: LOADING_VIDEO_DATA, payload: false });
    const response = yield retry(3, 5000, fetchVideoData, action.payload);
    const { ads, player_title, type, sources, sub_property_id, title, theme, mux } = response.data;
    const dataMux = {
      custom_1: mux.custom_1,
      video_title: mux.video_title,
      video_content_type: mux.content_type,
      video_id: mux.video_id,
      video_series: mux.video_series,
      sub_property_id
    };
    yield put({ type: SET_MUX_DATA, payload: dataMux });
    yield put({ type: SET_TITLE_ID, payload: title.id });
    yield put({ type: SET_TITLE_NAME, payload: player_title });
    yield put({ type: SET_CREDITS_START, payload: title.credits_start });

    const payload = {
      sources,
      title: player_title,
      endCredits: title.credits_start,
      titleName: title.name,
      titleId: title.id,
      rated: title.rated || 'NR',
      macros: {
        baseURL: ads?.base_url || otteraUrlBase,
        ...ads
      },
      serieId: type === 'SERIES' ? title.series_id : null,
      monetizationType: title.monetization_type,
      titleType: title.type,
      playerTheme: theme.name
    };

    yield put({ type: SET_VIDEO_DATA, payload });
    yield put({ type: LOADING_VIDEO_DATA, payload: true });
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'MEDIA_INVALID_ID') {
      yield put({
        type: SET_ERROR_CATEGORY_MOVIES,
        payload: playerErrors.MEDIA_INVALID_ID
      });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(window.location.pathname);
      });
    } else if (response && response.data.error === 'MEDIA_NOT_FOUND') {
      yield put({
        type: SET_ERROR_CATEGORY_MOVIES,
        payload: playerErrors.NOT_FOUND
      });
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(window.location.pathname);
      });
    } else {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(e);
      });
    }
    window.location.replace('/error');
    throw e;
  }
}

function* getMediaDetailedInfo(action) {
  try {
    const response = yield call(movieDetails, action.payload);
    yield put({
      type: SET_MEDIA_INFO,
      payload: response.data
    });
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'MEDIA_INVALID_ID') {
      yield put({
        type: SET_ERROR_CATEGORY_MOVIES,
        payload: playerErrors.MEDIA_INVALID_ID
      });
    } else {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(e);
      });
    }
  }
}

function* getFeaturedMedia(action) {
  try {
    const response = yield call(featuredMedia, action.payload);
    var featuredMediaData =
      response.data.items[Math.floor(Math.random() * response.data.items.length)];
    const responseMedia = yield call(movieDetails, featuredMediaData.id);
    yield put({
      type: SET_FEATURED_MEDIA_DETAILED_INFO,
      payload: responseMedia.data
    });
    yield put({
      type: SET_FEATURED_MEDIA,
      payload: featuredMediaData
    });
  } catch (e) {
    yield put({ type: SET_ERROR_CATEGORY_MOVIES, payload: playerErrors.CATEGORY_NOT_FOUND });
    Sentry.configureScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal);
      Sentry.captureException(e);
    });
  }
}

function* postResumeTime(action) {
  const { type } = action.payload;
  try {
    if (type === 'movie') {
      yield call(storeResumeTitle, action.payload);
    }
    if (type === 'series') {
      yield call(storeResumeSeries, action.payload);
    }
    yield put({ type: RESUME_TIME_SENDED, payload: true });
    yield put({ type: RESUME_TIME_SENDED, payload: false });
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'MEDIA_INVALID_ID') {
      yield put({
        type: SET_ERROR_CATEGORY_MOVIES,
        payload: playerErrors.MEDIA_INVALID_ID
      });
    } else if (response) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* fetchResumeTime(action) {
  try {
    const response = yield call(getResumeTime, action.payload);
    yield put({
      type: SET_RESUME_TIME,
      payload: response.data.resume_time
    });
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'MEDIA_INVALID_ID') {
      yield put({
        type: SET_ERROR_CATEGORY_MOVIES,
        payload: playerErrors.MEDIA_INVALID_ID
      });
    } else if (response) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* getBrowse(action) {
  try {
    const response = yield call(browseCollections, action.payload);
    yield put({
      type: SET_BROWSE,
      payload: response.data
    });
  } catch (e) {
    yield put({ type: SET_ERROR_CATEGORY_MOVIES, payload: playerErrors.MEDIA_INVALID_ID });
    Sentry.configureScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal);
      Sentry.captureException(e);
    });
  }
}

function* getEpisodesSeries(action) {
  try {
    const response = yield call(fetchEpisodes, action.payload);
    const { episodes, has_more } = response.data;
    yield put({
      type: SET_EPISODES,
      payload: episodes
    });
    if (has_more) {
      yield put({
        type: SET_EPISODES_HAS_MORE,
        payload: true
      });
    } else {
      yield put({
        type: SET_EPISODES_HAS_MORE,
        payload: false
      });
    }
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'NOT_FOUND') {
      yield put({
        type: SET_ERROR_CATEGORY_MOVIES,
        payload: playerErrors.NOT_FOUND
      });
    } else {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal);
        Sentry.captureException(e);
      });
    }
  }
}

function* getSuggest(action) {
  try {
    const response = yield call(suggestTitle, action.payload);
    if (response.data && response.data.suggestions) {
      const { suggestions } = response.data;
      const randomSuggestions = suggestions[Math.floor(Math.random() * suggestions.length)];
      const { id, name, description, assets, type, movie, series } = randomSuggestions;
      const finalSuggestions = {
        id,
        name,
        description,
        type,
        movie,
        series,
        poster: assets
          .filter(({ type }) => type === 'poster_h')
          .map(p => p.url)
          .shift()
      };
      yield put({ type: SET_SUGGEST, payload: { suggestions: finalSuggestions } });
    } else {
      yield put({ type: SET_SUGGEST, payload: response.data });
    }
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'NOT_FOUND') {
      yield put({
        type: SET_ERROR_SUGGEST,
        payload: playerErrors.NOT_FOUND_NEXT_EPISODE
      });
    } else if (response) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* getPreviousOrNextEpidose(action) {
  try {
    const response = yield call(suggestTitle, action.payload);
    yield put({ type: SET_PREVIOUS_NEXT_EPISODE, payload: response.data });
  } catch (e) {
    const { response } = e;
    if (response && response.data.error === 'NOT_FOUND') {
      yield put({
        type: SET_ERROR_SUGGEST,
        payload: playerErrors.NOT_FOUND_NEXT_EPISODE
      });
    } else if (response) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}

function* fetchServiceAvailable() {
  try {
    const response = yield call(healthCheck);
    const { tenant_id } = response.data;
    yield put({ type: SET_SERVICE_AVAILABLE, payload: true });
    yield put({ type: SET_TENANCY, payload: tenant_id });
  } catch (e) {
    const { response } = e;
    yield put({ type: SET_SERVICE_AVAILABLE, payload: false });
    if (response && response.status !== 403) {
      Sentry.configureScope(scope => {
        scope.setLevel(Sentry.Severity.Warning);
        Sentry.captureException(e);
      });
    }
    if (response && response.data && response.data.tenant_id) {
      yield put({ type: SET_TENANCY, payload: response.data.tenant_id });
    }
  }
}

const handleTrailerData = responseModalData => {
  const { trailer, assets } = responseModalData.data;

  const hlsSources = [];
  const progressiveSources = [];

  for (const source of trailer.playlist[0].sources) {
    if (source.type === 'application/vnd.apple.mpegurl') {
      hlsSources.push(source.file);
    } else if (source.type !== 'audio/mp4') {
      progressiveSources.push({
        url: source.file,
        type: source.type,
        bitrate: source.bitrate,
        label: source.label
      });
    }
  }

  const poster = assets
    .filter(asset => asset.type === 'backdrop')
    .map(asset => asset.url)
    .shift();

  responseModalData.data.trailer = {
    id: trailer.id,
    sources: {
      hls: hlsSources.shift(),
      progressive: progressiveSources,
      poster
    }
  };

  return responseModalData;
};

function* getModalData(action) {
  try {
    let responseModalData = yield call(movieDetails, action.payload);
    if (responseModalData.data.trailer) {
      responseModalData = yield call(handleTrailerData, responseModalData);
    }
    yield put({
      type: SET_MODAL_DATA,
      payload: responseModalData.data
    });
  } catch (e) {
    if ((e.response && e.response.status === 404) || e.response.status === 422) {
      yield put({
        type: SET_MODAL_ERROR,
        payload: e.response.data.error
      });
    }
  }
}

function* getTrailerSource(action) {
  try {
    const response = yield call(fetchTrailerSource, action.payload);
    const { player_title, playlist, theme } = response.data;
    const { sources, tracks } = playlist[0];

    const transformFile = ({ file, kind, label }) => ({
      label: kind === 'thumbnails' ? kind : label,
      src: file,
      kind: kind === 'thumbnails' ? 'metadata' : kind,
      default: true,
      srclang: kind === 'captions' ? 'es' : undefined
    });

    const payload = {
      hls: sources.find(({ type }) => type === 'application/vnd.apple.mpegurl')?.file,
      progressive: sources
        .filter(({ type }) => type !== 'application/vnd.apple.mpegurl' && type !== 'audio/mp4')
        .map(transformFile),
      thumbnailTrack: {
        url: tracks[0].file
      },
      title: player_title,
      textTracks: tracks.map(transformFile),
      thumbnailsUrl: `${tracks[0].file.split('.vtt')[0]}.jpg`,
      playerTheme: theme.name
    };
    yield put({ type: SET_TRAILER_SOURCE, payload });
    action.payload.setIsLoading(false);
  } catch (e) {
    Sentry.configureScope(scope => {
      scope.setLevel(Sentry.Severity.Fatal);
      Sentry.captureException(e);
    });
  }
}

function* watchGetModalData() {
  yield takeLatest(GET_MODAL_DATA, getModalData);
}

function* watchGetCollections() {
  yield takeLatest(FETCH_COLLECTIONS, getCollections);
}

function* watchGetPlaylist() {
  yield takeLatest(FETCH_PLAYLIST, getPlaylist);
}

function* watchVideoData() {
  yield takeLatest(FETCH_VIDEO_DATA, getVideoData);
}

function* watchGetMediaDetailedInfo() {
  yield takeLatest(FETCH_MOVIE_METADATA, getMediaDetailedInfo);
}

function* watchGetFeaturedMedia() {
  yield takeLatest(FETCH_FEATURED_TITLE, getFeaturedMedia);
}

function* watchGetExploreMovies() {
  yield takeEvery(FETCH_EXPLORE_MOVIES, getExploreMovies);
}

function* watchPostResumeTime() {
  yield takeEvery(POST_RESUME_TIME, postResumeTime);
}

function* watchGetResumeTime() {
  yield takeEvery(FETCH_RESUME_TIME, fetchResumeTime);
}

function* watchGetBrowse() {
  yield takeEvery(FETCH_BROWSE, getBrowse);
}

function* watchGetExploreGenre() {
  yield takeEvery(FETCH_EXPLORE_GENRE, getExploreGenre);
}

function* watchGetEpisodesSeries() {
  yield takeLatest(FETCH_EPISODES, getEpisodesSeries);
}

function* watchGetSuggest() {
  yield takeLatest(FETCH_SUGGEST, getSuggest);
}

function* watchFetchServiceAvailable() {
  yield takeLatest(FETCH_SERVICE_AVAILABLE, fetchServiceAvailable);
}

function* watchGetPreviousOrNextEpidose() {
  yield takeLatest(FETCH_PREVIOUS_NEXT_EPISODE, getPreviousOrNextEpidose);
}

function* watchGetTrailerSource() {
  yield takeLatest(FETCH_TRAILER_SOURCE, getTrailerSource);
}

export default [
  watchGetCollections(),
  watchGetFeaturedMedia(),
  watchGetMediaDetailedInfo(),
  watchGetExploreMovies(),
  watchGetPlaylist(),
  watchVideoData(),
  watchPostResumeTime(),
  watchGetResumeTime(),
  watchGetBrowse(),
  watchGetExploreGenre(),
  watchGetEpisodesSeries(),
  watchGetSuggest(),
  watchFetchServiceAvailable(),
  watchGetPreviousOrNextEpidose(),
  watchGetModalData(),
  watchGetTrailerSource()
];
