import { Cookies } from 'react-cookie';
import moment from 'moment';

const cookies = new Cookies();

export default () => ({
  setModalMuted: payload => {
    const expires = moment().add(1, 'y').toDate();
    cookies.set('modalMuted', payload, { path: '/', sameSite: true, expires });
  },
  getModalMuted: () => {
    const cookie = cookies.get('modalMuted');
    return cookie === 'true';
  },
  removeModalMuted: () => {
    cookies.remove('modalMuted', { path: '/' });
  }
});
