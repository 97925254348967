const auth = () => ({
  registerUrl: '/auth/register',
  loginUrl: '/auth/token',
  emailConfirmUrl: '/auth/account/verify',
  reSendEmailConfirm: '/auth/account/confirm',
  forgotUrl: '/auth/password/forgot',
  resetUrl: '/auth/password/reset',
  host: `${window.location.protocol}//${window.location.host}/account/confirm`,
  resetHost: `${window.location.protocol}//${window.location.host}/reiniciar/clave`,
  tvCode: '/auth/device/login'
});

export default auth;
