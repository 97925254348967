import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { LoginForm, FacebookLogin, GoogleLogin, ToolboxButton } from '../../components';

const LoginView = () => {
  const location = useLocation();
  const [userToken, setUserToken] = useState(null);
  const [idp, setIdp] = useState(null);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('toolbox_user_token');
    const idpParam = new URLSearchParams(location.search).get('idp');
    if (token && idpParam) {
      setUserToken(token);
      setIdp(idpParam);
    }
  }, [location]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-sm-10 col-md-8 col-lg-5 mt-5">
          <h4 className="text-white mb-3">Ingresa con tus redes sociales</h4>
          <div className="mb-4">
            <FacebookLogin title="Inicia sesion con Facebook" source="login" />
            <GoogleLogin title="Inicia sesión con Google" source="login" />
          </div>
          <div className="mb-4">
            <ToolboxButton userToken={userToken} idp={idp} />
          </div>
          <h4 className="text-white mb-3">Ingresa con tu correo</h4>
          <LoginForm />
          <div className="text-right mb-4">
            <Button as={Link} variant="link" to="/recuperar/clave" className="text-white">
              Olvidé mi contraseña
            </Button>
          </div>
          <div className="text-center">
            <p className="text-white">¿Aún no tienes cuenta?</p>
            <Button as={Link} variant="link" to="/registro" className="text-white">
              Crear cuenta
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
