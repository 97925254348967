
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import IconPendingAccount from '../../images/icon-email-notification.svg';

const PendingView = () => {
  const [email, setEmail] = useState('');
  const location = useLocation();

  useEffect(() => {
    setEmail(new URLSearchParams(location.search).get('email'));
  }, [location]);


  return (
    <div className="container container-pt-custom">
      <div className="row">
        <div className="col-sm-6 mx-auto text-center">
          <img src={IconPendingAccount} alt="" className="mb-5" />
          <h2 className="font-weight-bold text-white mb-4">
            Te enviamos un correo de confirmación
          </h2>
          <p></p>
          <div className="row">
            <div className="col-sm-10 mx-auto">
              <p className="text-white">
                Para completar tu registro necesitamos que valides tu correo {email}
              </p>
              <p className="text-white mb-4">Si no lo encuentras, revisa tu carpeta de Spam. :)</p>
            </div>
          </div>
          <Button as={Link} variant="outline-light" to="/inicio">
            Entendido
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PendingView;
