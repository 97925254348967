import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import setupPlayer from '../../utils/helpers/setupPlayer';
import config from '../../config';
import customUI from './ui';
import watermark from '../../images/riivi-brand.svg';
import usePlayerType from '../../utils/hooks/usePlayerType';

const TrailerPlayer = ({ trailerSource, uiVisibility }) => {
  const history = useHistory();
  const playerRef = useRef(null);
  const { bitmovin } = window;
  const playerInit = useRef(false);
  const styleId = usePlayerType(trailerSource);

  useEffect(() => {
    const player = document.getElementById(styleId);
    document.getElementById('player-trailer-container').appendChild(player);
    return () => {
      if (playerRef.current !== null) {
        playerRef.current.destroy();
      }
    };
  }, [styleId]);

  useEffect(() => {
    if (bitmovin) {
      if (trailerSource && Object.keys(trailerSource).length > 0 && !playerInit.current) {
        playerInit.current = true;
        setupPlayer();
        const conf = {
          key: config().player.bitmovinKey,
          playback: {
            autoplay: true
          },
          ui: false
        };
        const player = new bitmovin.player.core.Player(document.getElementById(styleId), conf);
        playerRef.current = player;
        customUI(player);
        playerRef.current.unload();
        playerRef.current.load(trailerSource).then(() => {
          const bitmovinPlayer = bitmovin.player;
          bitmovin.player = bitmovin.player.core;
          bitmovin.player = bitmovinPlayer;
          if (trailerSource.subtitles) {
            trailerSource.subtitles.map((_, index) => {
              const element = trailerSource.subtitles[index];
              playerRef.current.subtitles.add(element);
              return false;
            });
          }
        });
      }
    }
  }, [bitmovin, trailerSource, styleId]);

  const comeBackHome = () => {
    history.goBack();
  };

  const trailerUI = () => {
    return (
      uiVisibility && (
        <>
          <Button onClick={comeBackHome} variant="light" className="btn-blur btn-back" size="lg">
            <i className="far fa-arrow-left"></i>
          </Button>
          <div className="watermark">
            <img src={watermark} alt="watermark-riivi" />
          </div>
          <h1 className="title-name">Trailer</h1>
        </>
      )
    );
  };

  return ReactDOM.createPortal(trailerUI(), document.getElementById(styleId));
};

TrailerPlayer.propTypes = {
  trailerSource: PropTypes.object.isRequired,
  uiVisibility: PropTypes.bool.isRequired
};

export default TrailerPlayer;
