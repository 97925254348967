import axios from 'axios';
import CookieHandler from './cookie_handler';
import services from '../../config';
import eventBus from './eventBus';

export default http => ({
  authRequest: request => {
    const token = CookieHandler().getAccessToken();
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
  },

  authResponse: async error => {
    const { config, response } = error;
    const { loginUrl } = services().auth;

    if (response && response.status === 401 && config.url !== loginUrl) {
      if (!window.refreshing) {
        const { getRefreshToken, setToken, removeToken } = CookieHandler();
        window.refreshing = true;

        try {
          const refresh = await http.post(loginUrl, {
            grant_type: 'refresh_token',
            refresh: getRefreshToken()
          });

          if (refresh && refresh.status === 200) {
            setToken(refresh.data);
            axios.defaults.headers.common['Authorization'] = `Bearer ${refresh.data.access_token}`;
            window.refreshing = false;
            window.logout = false;
            eventBus.dispatch('refreshed');
            return http({
              ...config,
              data: typeof config.data === 'string' ? JSON.parse(config.data) : config.data
            });
          }

          removeToken();
          throw new Error('unauthorized');
        } catch (e) {
          removeToken();
          window.refreshing = false;
          window.logout = true;
          return Promise.reject(error);
        }
      }

      return new Promise(resolve => {
        eventBus.on('refreshed', () => {
          eventBus.remove('refreshed');
          if (window.logout) {
            return Promise.reject(error);
          }
          resolve(
            http({
              ...config,
              data: typeof config.data === 'string' ? JSON.parse(config.data) : config.data
            })
          );
        });
      });
    }
    return Promise.reject(error);
  }
});
