import React from 'react';
import { Container } from 'react-bootstrap';
import { ForgotForm } from '../../components';

const ForgotView = () => {
  return (
    <Container className="container-pt-custom">
      <div className="row">
        <div className="col-sm-10 col-md-8 col-lg-6">
          <h4 className="text-white mb-3">Reestablecer mi contraseña</h4>
          <p className="text-white mb-4">
            Para poder reestablecer tu contraseña debes ingresar el correo electrónico asociado a tu
            cuenta
          </p>
          <ForgotForm />
        </div>
      </div>
    </Container>
  );
};

export default ForgotView;
