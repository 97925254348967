const schema = {
  password: {
    type: 'string',
    min: 6,
    max: 100,
    optional: false,
    pattern: '(?=.*?[A-Z])(?=.*?[0-9])',
    messages: {
      required: 'La contraseña es obligatoria.',
      string: 'Por favor ingresar contraseña valida (letras y numeros).',
      stringMin: 'Su contraseña es muy corta (minimo 6 caracteres).',
      stringMax: 'Su contraseña es muy larga (maximo 100 caracteres).',
      stringPattern: 'La contraseña debe contener mayúsculas y números'
    }
  }
};

export default schema;
