import { useState, useEffect } from 'react';
import playerTypes from '../constants/playerTypes';

const usePlayerType = playerSource => {
  const { PLAYER_TYPES } = playerTypes();

  const avodStyle = 'player-avod';
  const tvodStyle = 'player-tvod';
  const [styleId, setStyleId] = useState(avodStyle);

  useEffect(() => {
    const currentMonetizationType = playerSource?.playerTheme;

    if (currentMonetizationType === PLAYER_TYPES.TVOD) {
      setStyleId(tvodStyle);
    } else if (currentMonetizationType === PLAYER_TYPES.AVOD) {
      setStyleId(avodStyle);
    }
  }, [playerSource, avodStyle, tvodStyle, PLAYER_TYPES]);

  return styleId;
};

export default usePlayerType;
