import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FETCH_EXPLORE_MOVIES,
  RESET_EXPLORE_MEDIA,
  SET_EXPLORE_HAS_MORE,
  FETCH_EXPLORE_GENRE,
  SET_COLLECTION_NAME
} from '../../store/player/types';
import { Slider } from '../';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import TagManagerHelper from '../../utils/helpers/gtmDatalayer';
import playerScript from '../../utils/helpers/playerScript';
import { Spinner } from 'react-bootstrap';
import { useIntersectionObserver } from '../../utils/hooks/useInterceptorObserver';
import { FETCH_ENTITLEMENTS_LIST } from '../../store/user/types';
import { useMatchEntitlements } from '../../utils/hooks/useMatchEntitlements';

const Explore = ({
  getExploreMovies,
  categoryExploreListArray,
  hasMore,
  resetHasMore,
  resetExploreMedia,
  collectionName,
  getExploreGenre,
  resetCollectionName,
  entitlementsList,
  getEntitlementsList,
  isLogin
}) => {
  const history = useHistory();
  const [nextPage, setNextPage] = useState(1);
  const { collectionID } = useParams();
  const [currentCollectionId, setCurrentCollectionId] = useState(collectionID);
  const currentUrl = window.location.href;
  const scriptsLoader = playerScript();
  const { nodeRef, showLoader } = useIntersectionObserver({
    onIntersect: useCallback(() => setNextPage(prevPage => prevPage + 1), []),
    hasMore
  });
  const { titles } = useMatchEntitlements({
    entitlementsList,
    collectionTitles: categoryExploreListArray,
    isLogin,
    titleId: categoryExploreListArray.map(title => title.id),
    source: 'explore'
  });

  useEffect(() => {
    isLogin && entitlementsList.length < 1 && getEntitlementsList();
  }, [getEntitlementsList, isLogin, entitlementsList]);

  useEffect(() => {
    return () => {
      resetExploreMedia();
      resetHasMore(true);
      resetCollectionName();
    };
  }, [resetExploreMedia, resetHasMore, resetCollectionName]);

  useEffect(() => {
    if (collectionID !== currentCollectionId) {
      setCurrentCollectionId(collectionID);
      setNextPage(1);
    } else {
      if (currentUrl.includes('/genero/')) {
        getExploreGenre({ id: collectionID, page: nextPage });
      } else {
        getExploreMovies({ id: collectionID, page: nextPage });
      }
    }
  }, [getExploreMovies, collectionID, nextPage, getExploreGenre, currentUrl, currentCollectionId]);

  useEffect(() => {
    TagManagerHelper().exploreFunnel();
  }, []);

  useEffect(() => {
    scriptsLoader.titleDocuments('Explorar');
    scriptsLoader.metaTag(
      {
        description: `Explora nuestro contenido de ${collectionName}.
      Tenemos las mejores películas, series y documentales chilenos y latinos. ¡Completamente gratis!`
      },
      true
    );
  }, [scriptsLoader, collectionName]);

  const renderLoader = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="light" className="spinner-custom" />
      </div>
    );
  };

  const goBack = () => {
    history.push('/inicio');
  };

  return collectionName !== '' ? (
    <div>
      <Button className="text-purple-pink btn-explore mb-3" onClick={goBack}>
        <i className="far fa-arrow-left mr-1"></i>
        Volver
      </Button>
      <h3 className="mb-3">{collectionName}</h3>
      {titles.length > 0 && (
        <Slider explore={true} collectionName={collectionName}>
          {titles.map((title, index) => (
            <Slider.Item
              key={title.id}
              title={title}
              origin="explore"
              collectionIndex={index}
              titleIndex={index}
              collectionName={collectionName}
              ticket={title.monetization?.type === 'TVOD' || false}
            />
          ))}
        </Slider>
      )}
      {showLoader && renderLoader()}
      <div ref={nodeRef} style={{ height: '1rem' }} />
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant="light" className="spinner-custom" />
    </div>
  );
};

Explore.propTypes = {
  getExploreMovies: PropTypes.func.isRequired,
  categoryExploreListArray: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  resetExploreMedia: PropTypes.func.isRequired,
  resetHasMore: PropTypes.func.isRequired,
  collectionName: PropTypes.string.isRequired,
  getExploreGenre: PropTypes.func.isRequired,
  resetCollectionName: PropTypes.func.isRequired,
  entitlementsList: PropTypes.array.isRequired,
  getEntitlementsList: PropTypes.func.isRequired,
  isLogin: PropTypes.bool.isRequired
};

const stateToProps = ({ player, user }) => ({
  categoryExploreListArray: player.exploreMedia,
  hasMore: player.exploreHasMore,
  collectionName: player.collectionName,
  entitlementsList: user.entitlementsList,
  isLogin: user.isLogedIn
});

const actionToProps = dispatch => ({
  getExploreMovies: payload => dispatch({ type: FETCH_EXPLORE_MOVIES, payload }),
  resetExploreMedia: () => dispatch({ type: RESET_EXPLORE_MEDIA, payload: [] }),
  resetHasMore: payload => dispatch({ type: SET_EXPLORE_HAS_MORE, payload }),
  getExploreGenre: payload => dispatch({ type: FETCH_EXPLORE_GENRE, payload }),
  resetCollectionName: () => dispatch({ type: SET_COLLECTION_NAME, payload: '' }),
  getEntitlementsList: payload => dispatch({ type: FETCH_ENTITLEMENTS_LIST, payload })
});

export default connect(stateToProps, actionToProps)(Explore);
