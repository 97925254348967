export const SET_USER_NAME = 'saga/user/SET_USER_NAME';
export const SET_USER_PROFILE = 'saga/user/SET_USER_PROFILE';
export const SET_USER_EMAIL = 'saga/user/SET_USER_EMAIL';
export const SET_ISLOGEDIN = 'saga/user/SET_ISLOGEDIN';
export const SET_PROFILE_PIC = 'saga/user/SET_PROFILE_PIC';
export const SET_LOADING_FAVOURITE = 'saga/user/SET_LOADING_FAVOURITE';
export const SET_FAVOURITE_LIST = 'saga/user/SET_FAVOURITE_LIST';
export const FETCH_FAVORITES_LIST = 'saga/user/FETCH_FAVORITES_LIST';
export const GET_USER_INFO = 'saga/user/GET_USER_INFO';
export const SET_DELETE_ACCOUNT = 'user/SET_DELETE_ACCOUNT';
export const DELETE_ACCOUNT = 'saga/user/DELETE_ACCOUNT';
export const POST_FAVOURITES = 'saga/user/POST_FAVOURITES';
export const REMOVE_WATCHING = 'saga/user/REMOVE_WATCHING';
export const TITLE_REMOVED = 'saga/user/TITLE_REMOVED';
export const FETCH_RATING_LIST = 'saga/user/FETCH_RATING_LIST';
export const SET_RATING_LIST = 'saga/user/SET_RATING_LIST';
export const POST_RATING = 'saga/user/POST_RATING';
export const SET_LOADING_RATING = 'saga/user/SET_LOADING_RATING';
export const FETCH_RESUME_DATA = 'saga/user/FETCH_RESUME_DATA';
export const SET_RESUME_DATA = 'saga/user/SET_RESUME_DATA';
export const FETCH_CONTINUE_WATCHING = 'saga/user/FETCH_CONTINUE_WATCHING';
export const LOADING_CONTINUE_WATCHING = 'saga/user/LOADING_CONTINUE_WATCHING';
export const SET_CONTINUE_WATCHING = 'saga/user/SET_CONTINUE_WATCHING';
export const FETCH_ENTITLEMENTS = 'saga/user/FETCH_ENTITLEMENTS';
export const LOADING_ENTITLEMENTS = 'saga/user/LOADING_ENTITLEMENTS';
export const SET_ENTITLEMENTS = 'saga/user/SET_ENTITLEMENTS';
export const FETCH_FAVORITES = 'saga/user/FETCH_FAVORITES';
export const LOADING_FAVORITES = 'saga/user/LOADING_FAVORITES';
export const SET_FAVORITES_MEDIA = 'saga/user/SET_FAVORITES_MEDIA';
export const FETCH_ENTITLEMENTS_LIST = 'saga/user/FETCH_ENTITLEMENTS_LIST';
export const SET_ENTITLEMENTS_LIST = 'saga/user/SET_ENTITLEMENTS_LIST';
export const POST_ENTITLEMENT_KEY = 'saga/user/POST_ENTITLEMENT_KEY';
export const SET_ENTITLEMENT_KEY = 'saga/user/SET_ENTITLEMENT_KEY';
export const FETCH_ENTITLEMENT_MEDIA = 'saga/user/FETCH_ENTITLEMENT_MEDIA';
export const SET_ENTITLEMENT_MEDIA = 'saga/user/SET_ENTITLEMENT_MEDIA';
export const SET_ENTITLEMENT_MEDIA_ERROR = 'saga/user/SET_ENTITLEMENT_MEDIA_ERROR';
export const LOADING_ENTITLEMENT_MEDIA = 'saga/user/LOADING_ENTITLEMENT_MEDIA';
export const SET_TOGGLE_CATEGORY_TVOD = 'saga/user/SET_TOGGLE_CATEGORY_TVOD';
export const POST_MEDIA_SIGN = 'saga/user/POST_MEDIA_SIGN';
export const SET_MEDIA_SIGN_TOKEN = 'saga/user/SET_MEDIA_SIGN_TOKEN';
export const LOADING_SIGN_TOKEN = 'saga/user/LOADING_SIGN_TOKEN';
