import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FormControl, Form } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import validator from '../../utils/validator';
import { resetSchema } from '../../utils/validator/schemas';
import { POST_RESET_PASSWORD } from '../../store/login/types';

const ResetForm = props => {
  const { resetPassword } = props;
  const { token } = useParams();
  const history = useHistory();
  const [resetForm, setResetForm] = useState({ password: '' });
  const [validationErrors, setValidationErrors] = useState({ password: '' });
  const [message, setMessage] = useState('');

  const handleResponse = response => {
    if (response.success) {
      history.push('/inicio');
    } else {
      setMessage(response.message);
    }
  };

  const handleInputChange = event => {
    setResetForm({ ...resetForm, [event.target.name]: event.target.value });
    setValidationErrors({ ...validationErrors, [event.target.name]: '' });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const errors = validator(resetSchema, resetForm);

    if (errors.length > 0) {
      const messages = errors
        .map(e => ({ [e.field]: e.message }))
        .reduce((prev, current) => ({ ...prev, ...current }), {});
      setValidationErrors(messages);
      return false;
    }

    resetPassword({ password: resetForm.password, token, response: handleResponse });
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Form.Group className="form-label-group mb-4">
        <FormControl
          name="password"
          type="password"
          placeholder="Ingresa tu nueva contraseña"
          onChange={handleInputChange}
          isInvalid={validationErrors.password}
        />
        <Form.Label>Nueva Contraseña</Form.Label>
        <Form.Control.Feedback type="invalid">{validationErrors.password}</Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-end mb-4">
        <Button variant="primary" type="submit" size="lg">
          Crear contraseña
        </Button>
      </div>
      <h3>{message}</h3>
    </form>
  );
};

ResetForm.propTypes = {
  resetPassword: PropTypes.func.isRequired
};

const actionsToProps = dispatch => ({
  resetPassword: payload => dispatch({ type: POST_RESET_PASSWORD, payload })
});

export default connect(null, actionsToProps)(ResetForm);
