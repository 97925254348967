export default () => ({
  response: async error => {
    const { response } = error;
    const allowedPaths = ['/no-disponible', '/pronto-disponible', '/account/confirm/', '/exito'];

    if (
      response &&
      response.data &&
      response.data.error === 'GEO_BLOCK' &&
      !allowedPaths.some(p => new RegExp(p).test(window.location.pathname))
    ) {
      document.getElementById('root').remove();
      window.location.href = '/no-disponible';
    }
    return Promise.reject(error);
  }
});
