import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import config from '../../config';
import { NextEpisode } from '..';
import { Recomended } from '..';
import TitleTypeFormat from '../../utils/helpers/titleTypeHelper';
import playerScript from '../../utils/helpers/playerScript';
import formatUrl from '../../utils/helpers/formatUrl';
import { PlayerError } from '../../views';
import {
  POST_RESUME_TIME,
  FETCH_RESUME_TIME,
  FETCH_SUGGEST,
  SET_SHOW_SUGGEST
} from '../../store/player/types';
import AdsMacros from '../../utils/helpers/adsMacros';
import setupPlayer from '../../utils/helpers/setupPlayer';
import handlePlayerEvents from './playerEvents';
import customUI from './ui';
import watermark from '../../images/riivi-brand.svg';
import adServer from '../../utils/constants/adServer';
import usePlayerType from '../../utils/hooks/usePlayerType';
import providerTypes from '../../utils/constants/providerTypes';
import brightcoveEvents from '../../utils/constants/brightcoveEvents';
import eventsBrightcoveCollector from '../../utils/helpers/eventsBrightcoveCollector';
import adsPosition from '../../utils/constants/adsPosition';

const Player = ({
  id,
  type,
  titleName,
  isLogin,
  postResumeTime,
  getResumetime,
  resumeTime,
  fetchSuggest,
  showSuggest,
  creditsStart,
  setShowSuggest,
  suggest,
  showModalAdult,
  buttonVisibility,
  previousOrNextEpisode,
  muxData,
  identityProvider,
  serieId,
  playlist,
  videoData,
  autoplay,
  entitlementKey,
  comesFromSuccessfullPayment,
  clearPlaylist,
  clearVideoData
}) => {
  const bitmovin = window.bitmovin;
  const history = useHistory();
  const location = useLocation();
  const playTimeCounter = parseInt(config().player.playTimeCounter, 10);
  const valueAd = config().player.valueAd;
  const playerInit = useRef(false);
  const [errorLoadPlayer, setErrorLoadPlayer] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const countReloadRef = useRef(localStorage.getItem('countReload'));
  const bitmovinRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [postResumeOnce, setPostResumeOnce] = useState(false);
  const currentTypeRef = useRef(TitleTypeFormat(window.location.pathname));
  const currentTimeRef = useRef(0);
  const [positionSuggest, setPositionSuggest] = useState(false);
  const positionSuggestRef = useRef(0);
  const [fetchSuggestOnce, setFetchSuggestOnce] = useState(true);
  const [adsFinished, setAdsFinished] = useState(true);
  const [showCredits, setShowCredits] = useState(false);
  const [playbackFinished, setPlaybackFinished] = useState(false);
  const [dispatchMux, setDispatchMux] = useState(false);
  const [comeBack, setComeBack] = useState(false);
  const { LOCATIONS } = adServer();
  const [player, _setPlayer] = useState(null);
  const playerRef = useRef(player);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const styleId = usePlayerType(videoData);
  const { BRIGHTCOVE_EVENTS } = brightcoveEvents();
  const { PROVIDER_TYPES } = providerTypes();
  const [playerEventsOnce, setPlayerEventsOnce] = useState(false);
  const [canPostEngagement, setCanPostEngagement] = useState(false);
  const prevRangeTimeRef = useRef(0);
  const { ADS_POSITION } = adsPosition();

  const checkMetadata = media => {
    return Object.keys(media).length > 0;
  };

  const playerDataLoaded = useMemo(() => {
    return playlist && checkMetadata(playlist) && videoData && checkMetadata(videoData);
  }, [playlist, videoData]);

  const setPlayer = data => {
    playerRef.current = data;
    _setPlayer(data);
  };

  useEffect(() => {
    const player = document.getElementById(styleId);
    document.getElementById('player-container').appendChild(player);
    isMobile && player.setAttribute('data-fullscreen', 'true');
  }, [styleId]);

  useEffect(() => {
    return () => {
      if (playerRef.current !== null) {
        playerRef.current.destroy();
        playerScript().scriptTag({}, false);
        setComeBack(true);
      }
    };
  }, []);

  const comeBackHome = useCallback(() => {
    comesFromSuccessfullPayment ? history.push('/perfil?rqpak=my-tickets') : history.goBack();
  }, [history, comesFromSuccessfullPayment]);

  const goNextEpisode = useCallback(() => {
    const { next_episode, title } = suggest;
    const formatName = formatUrl().getCleanedString(title.name);
    const type = formatUrl().translateType(formatUrl().getCleanedString(title.type));
    history.replace(`/${type || 'series'}/${formatName}/${next_episode.id}`, {
      from: LOCATIONS.INTERNALNAVIGATION
    });
    clearPlaylist();
    clearVideoData();
    playerInit.current = false;
  }, [suggest, history, LOCATIONS, clearPlaylist, clearVideoData]);

  const handleErrorPlayer = useCallback(() => {
    history.push('/player-error');
  }, [history]);

  const handlePostResume = useCallback(() => {
    if (currentTimeRef.current > 0 && playerRef.current !== null) {
      if (currentTypeRef.current === 'movie') {
        postResumeTime({
          time: currentTimeRef.current,
          type: currentTypeRef.current,
          titleId: id
        });
      } else if (currentTypeRef.current === 'series') {
        postResumeTime({
          time: currentTimeRef.current,
          type: currentTypeRef.current,
          serieId: serieId,
          episodeId: id
        });
      }
    }
    setPostResumeOnce(false);
  }, [setPostResumeOnce, postResumeTime, id, serieId]);

  const handleSuggest = useCallback(() => {
    if (
      positionSuggestRef.current > 0 &&
      positionSuggestRef.current >= creditsStart &&
      !showCredits
    ) {
      setShowSuggest(true);
    }
    setPositionSuggest(false);
  }, [creditsStart, setShowSuggest, showCredits]);

  useEffect(() => {
    if (dispatchMux) {
      const playerInitTime = Date.now();
      muxData.env_key = config().player.muxKey;
      muxData.player_name = 'Web';
      muxData.player_init_time = playerInitTime;
      if (window.initBitmovinMux) {
        window.initBitmovinMux(player, {
          debug: false,
          data: muxData
        });
        setDispatchMux(false);
      }
    }
  }, [dispatchMux, muxData, player]);

  useEffect(() => {
    if (bitmovin) {
      if (playerDataLoaded && !playerInit.current) {
        playerInit.current = true;
        setShowSuggest(false);
        setFetchSuggestOnce(true);
        setCurrentTime(0);
        setupPlayer();
        currentTimeRef.current = 0;
        const conf = {
          key: config().player.bitmovinKey,
          advertising: {
            adBreaks: [
              currentTimeRef.current === 0 &&
                AdsMacros({
                  data: videoData.macros,
                  location: location?.state,
                  position: ADS_POSITION.PREROLL,
                  identityProvider
                })
            ]
          },
          playback: {
            autoplay
          },
          ui: false
        };
        if (playerRef.current === null) {
          const bitPlayer = new bitmovin.player.core.Player(document.getElementById(styleId), conf);
          setPlayer(bitPlayer);
          customUI(bitPlayer);
        } else {
          playerRef.current.unload();
          playerRef.current.ads.schedule(
            AdsMacros({
              data: videoData.macros,
              location: location?.state,
              position: ADS_POSITION.PREROLL,
              identityProvider
            })
          );
        }
        playerRef.current.load(playlist).then(() => {
          bitmovinRef.current = bitmovin.player;
          bitmovin.player = bitmovin.player.core;
          setDispatchMux(true);
          bitmovin.player = bitmovinRef.current;
          if (playlist.subtitles) {
            playlist.subtitles.map((_, index) => {
              const element = playlist.subtitles[index];
              playerRef.current.subtitles.add(element);
              return false;
            });
          }
          setPlayerLoaded(true);
        });
      }
      localStorage.removeItem('countReload');
    } else {
      const currentCount = parseInt(countReloadRef.current, 10);
      if (currentCount < 2 && history.length <= 2) {
        localStorage.setItem('countReload', currentCount + 1);
        window.location.reload();
        return;
      } else {
        setErrorLoadPlayer(true);
        localStorage.removeItem('countReload');
      }
    }
  }, [
    bitmovin,
    setShowSuggest,
    history,
    location,
    identityProvider,
    playlist,
    videoData,
    styleId,
    autoplay,
    playerDataLoaded,
    ADS_POSITION
  ]);

  useEffect(() => {
    if (playerDataLoaded && playerRef.current !== null && !playerEventsOnce) {
      setPlayerEventsOnce(true);
      eventsBrightcoveCollector({
        eventType: BRIGHTCOVE_EVENTS.PLAY_REQUEST,
        videoData,
        playlist,
        PROVIDER_TYPES
      });
      handlePlayerEvents({
        videoData,
        playerRef,
        setCurrentTime,
        setPostResumeOnce,
        playTimeCounter,
        handleErrorPlayer,
        valueAd,
        setIsFullscreen,
        setPositionSuggest,
        setAdsFinished,
        setPlaybackFinished,
        location,
        identityProvider,
        playlist,
        eventsBrightcoveCollector,
        BRIGHTCOVE_EVENTS,
        PROVIDER_TYPES,
        setCanPostEngagement
      });
    } else {
      return;
    }
  }, [
    videoData,
    playerRef,
    playTimeCounter,
    handleErrorPlayer,
    valueAd,
    location,
    identityProvider,
    playlist,
    BRIGHTCOVE_EVENTS,
    PROVIDER_TYPES,
    playerEventsOnce,
    playerDataLoaded
  ]);

  useEffect(() => {
    let range = '';
    if (
      canPostEngagement &&
      !playerRef.current.isPaused() &&
      !playerRef.current.hasEnded() &&
      adsFinished
    ) {
      if (currentTime > 10) {
        range = `${prevRangeTimeRef.current}..${currentTime}`;
        prevRangeTimeRef.current = currentTime;
        eventsBrightcoveCollector({
          eventType: BRIGHTCOVE_EVENTS.VIDEO_ENGAGEMENT,
          videoData,
          playlist,
          PROVIDER_TYPES,
          rangeTime: range
        });
      }
      setCanPostEngagement(false);
    }
  }, [
    canPostEngagement,
    adsFinished,
    videoData,
    playlist,
    BRIGHTCOVE_EVENTS,
    PROVIDER_TYPES,
    currentTime
  ]);

  useEffect(() => {
    if (resumeTime > 0 && playerRef.current !== null && playerLoaded) {
      playerRef.current.seek(resumeTime);
    }
  }, [resumeTime, playerLoaded]);

  useEffect(() => {
    if (playlist && checkMetadata(playlist)) {
      if (isLogin) {
        getResumetime({ type, titleID: id });
      }
    }
  }, [id, getResumetime, isLogin, playlist, type]);

  useEffect(() => {
    if (fetchSuggestOnce) {
      fetchSuggest({ type: currentTypeRef.current, mediaId: id });
      setFetchSuggestOnce(false);
    }
  }, [
    currentTime,
    creditsStart,
    fetchSuggestOnce,
    fetchSuggest,
    currentTypeRef,
    id,
    setFetchSuggestOnce
  ]);

  useEffect(() => {
    if (
      postResumeOnce &&
      !playerRef.current.isPaused() &&
      !playerRef.current.hasEnded() &&
      adsFinished
    ) {
      currentTimeRef.current = currentTime;
      isLogin && handlePostResume();
    }
  }, [postResumeOnce, currentTime, handlePostResume, isLogin, adsFinished]);

  useEffect(() => {
    if (
      positionSuggest &&
      !playerRef.current.isPaused() &&
      !playerRef.current.hasEnded() &&
      adsFinished
    ) {
      positionSuggestRef.current = currentTime;
      handleSuggest();
    }
  }, [currentTime, handleSuggest, positionSuggest, adsFinished]);

  useEffect(() => {
    if (playbackFinished && comeBack) {
      if (currentTypeRef.current === 'series') {
        if (checkMetadata(suggest) && suggest.next_episode) {
          goNextEpisode();
        } else {
          comeBackHome();
        }
      } else {
        comeBackHome();
      }
      setPlaybackFinished(false);
    }
  }, [playbackFinished, suggest, comeBackHome, goNextEpisode, comeBack]);

  useEffect(() => {
    const playContent = () => {
      if (playerRef.current) {
        playerRef.current.play();
      }
    };

    if (playerLoaded && (!showModalAdult || entitlementKey)) {
      playContent();
    }
  }, [showModalAdult, entitlementKey, playerLoaded]);

  useEffect(() => {
    const formatTitle = name => {
      if (!name) return 'Riivi';
      return name.length > 60 ? `${name.substring(0, 60)}... | Riivi` : `${name} | Riivi`;
    };

    document.title = formatTitle(titleName);
  }, [titleName]);

  const setTitleName = () => {
    const className = `title-name ${!isFullscreen ? 'title-name-minimized' : ''}`;
    const title = adsFinished ? titleName : 'Anuncio';

    return <h1 className={className}>{title}</h1>;
  };

  const showNextEpisodeButton = () => {
    if (
      playerRef.current !== null &&
      playerRef.current.getViewMode() === 'fullscreen' &&
      currentTypeRef.current === 'series' &&
      previousOrNextEpisode &&
      previousOrNextEpisode.next_episode
    ) {
      return (
        <Button
          onClick={goNextEpisode}
          variant="primary"
          className="btn-next text-white mr-2"
          size="lg"
        >
          <span>Siguiente capítulo</span>
          <i className="fas fa-play mx-2"></i>
        </Button>
      );
    }
  };

  const renderButtonBeginning = () => {
    return (
      <Button
        onClick={() => playerRef.current.seek(0)}
        variant="light"
        className="btn-blur btn-beginning"
        size="lg"
      >
        <i className="far fa-history mx-2"></i>
        <span>Desde el comienzo</span>
      </Button>
    );
  };

  const buttonReturn = () => {
    if (!errorLoadPlayer) {
      return (
        (buttonVisibility || showSuggest) && (
          <>
            <Button onClick={comeBackHome} variant="light" className="btn-blur btn-back" size="lg">
              <i className="far fa-arrow-left"></i>
            </Button>
            {adsFinished && currentTime >= 15 && renderButtonBeginning()}
            <div className="watermark">
              <img src={watermark} alt="watermark-riivi" />
            </div>
            {!showSuggest &&
              adsFinished &&
              !isMobile &&
              videoData?.monetizationType !== 'TVOD' &&
              showNextEpisodeButton()}
            {showSuggest && suggest.next_episode && videoData?.monetizationType !== 'TVOD' && (
              <NextEpisode
                handleShowCredits={setShowCredits}
                handleShowSuggest={setShowSuggest}
                playerInit={playerInit}
                clearPlaylist={clearPlaylist}
                clearVideoData={clearVideoData}
              />
            )}
            {showSuggest && suggest.suggestions && (
              <Recomended handleShowCredits={setShowCredits} />
            )}
            {!isMobile && setTitleName()}
          </>
        )
      );
    } else {
      return (
        <div style={{ background: 'black', widt: '100vw', height: '100vh' }} className="py-5">
          <PlayerError source={'playerError'} />
        </div>
      );
    }
  };

  return ReactDOM.createPortal(buttonReturn(), document.getElementById(styleId));
};

Player.propTypes = {
  id: PropTypes.string.isRequired,
  titleName: PropTypes.string.isRequired,
  isLogin: PropTypes.bool.isRequired,
  postResumeTime: PropTypes.func.isRequired,
  resumeTime: PropTypes.number.isRequired,
  getResumetime: PropTypes.func.isRequired,
  fetchSuggest: PropTypes.func.isRequired,
  showSuggest: PropTypes.bool.isRequired,
  creditsStart: PropTypes.number,
  setShowSuggest: PropTypes.func.isRequired,
  suggest: PropTypes.object.isRequired,
  previousOrNextEpisode: PropTypes.object.isRequired,
  muxData: PropTypes.object.isRequired,
  buttonVisibility: PropTypes.bool.isRequired,
  playlist: PropTypes.object.isRequired,
  videoData: PropTypes.object.isRequired,
  entitlementKey: PropTypes.bool.isRequired
};

const stateToProps = ({ player, user }) => ({
  titleName: player.titleName,
  isLogin: user.isLogedIn,
  resumeTime: player.resumeTime,
  showSuggest: player.showSuggest,
  creditsStart: player.creditsStart,
  suggest: player.suggest,
  previousOrNextEpisode: player.previousOrNextEpisode,
  muxData: player.muxData,
  identityProvider: user.idp
});

const actionToProps = dispatch => ({
  postResumeTime: payload => dispatch({ type: POST_RESUME_TIME, payload }),
  getResumetime: payload => dispatch({ type: FETCH_RESUME_TIME, payload }),
  fetchSuggest: payload => dispatch({ type: FETCH_SUGGEST, payload }),
  setShowSuggest: payload => dispatch({ type: SET_SHOW_SUGGEST, payload })
});

export default connect(stateToProps, actionToProps)(Player);
